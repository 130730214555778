<template>
  <Layout tituloPagina="Compras | Cotizaciones">
    <div class="row">
      <div class="col-lg-12">
        <cmp-secciones seccion="cotizaciones"></cmp-secciones>
      </div>
    </div>

    <br>
 
    <div class="row">
      <div class="col-lg-2 col-md-6">
        <div class="input-group">
          <span class="input-group-text">
            <i class="fa fa-filter"></i>
            Estado
          </span>
          <select class="form-select">
            <option >Todos</option>
            <option >Sin seguimiento</option>
            <option >Aprobado</option>
            <option >Rechazado</option>
            <option >Pospuesto</option>
            <option >Cancelado</option>
          </select>
        </div>
      </div>
      <div class="col-lg-2 col-md-6">
        <div class="input-group">
          <span class="input-group-text">
            <i class="fa fa-filter"></i>
            Fecha
          </span>
          <select class="form-select">
            <option >Seleccionar</option>
            <option >Dia</option>
            <option >Mes</option>
            <option >Año</option>
            <option >Personalizado</option>
          </select>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="input-group">
          <input class="form-control"/>
          <span class="input-group-text">
            <i class="fa fa-calendar"></i>
          </span>
          <span class="input-group-text">
            al
          </span>
          <input class="form-control"/>
          <span class="input-group-text">
            <i class="fa fa-calendar"></i>
          </span>
          <button class="btn btn-secondary">
            <i class="mdi mdi-refresh"></i>
          </button>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-right">
        <button class="btn btn-default" @click="mostrarMdlSeleccionRequerimiento()">
          <i class="fa fa-plus"></i> Nueva cotizacion
        </button>
      </div>
    </div>

    <br />
    
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover align-middle">
            <thead>
              <tr>
                <th>Numero de cotización</th>
                <th>Fecha de elaboración</th>
                <th>Nombre de la cotización</th>
                <th>Estado</th>
                <th style="width:250px">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  327
                </td>
                <td>
                  31/05/2020
                </td>
                <td>
                  Cotización para requerimiento 1035
                </td>
                <td>
                  x
                </td>
                <td>
                  <router-link
                    :to="{ name: 'visorCotizacion', params: { id:10 } }"
                    class="btn btn-info btn-sm"
                  >
                    <i class="fa fa-eye"></i>
                    ver
                  </router-link>
                  <router-link
                    :to="{ name: 'edicionCotizacion', params: { id:10 } }"
                    class="btn btn-warning btn-sm"
                  >
                    <i class="fa fa-edit"></i>
                    Editar
                  </router-link>
                  <button class="btn btn-danger btn-sm" @click="cancelarCotizacion(10)">
                    <i class="fa fa-ban"></i>
                    Cancelar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarRequerimientos()
        "
      ></paginador>
    </div>

    <mdl-seleccionar-requerimiento ref="mostrarSeleccionRequerimiento"/>
    <br>
  </Layout>
</template>

<script>
import CmpSecciones from '../CmpSecciones.vue'
import Paginador from '@/components/Paginador.vue'
import MdlSeleccionarRequerimiento from './MdlSeleccionarRequerimiento.vue'
import Layout from "@/views/layouts/main"
export default {
  components: {
    CmpSecciones, 
    Paginador,
    MdlSeleccionarRequerimiento,
    Layout
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: 10,
      tc: 0, // Total de clientes
      totpag: 0, // Total de páginas
    }
  },
  created() {
    
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      //self.refrescarRequerimientos({ page: n })
    },

    mostrarMdlSeleccionRequerimiento:function () {
      var self = this

      self.$refs.mostrarSeleccionRequerimiento.mostrar()
    },

    cancelarCotizacion: function(idCotizacion) {
      swal({
        title: "¿Cancelar cotización?",
        text: "¿Está seguro de cancelar la cotización 259?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        closeOnConfirm: false,
        closeOnCancel: false
      },
      function(isConfirm) {
        if (isConfirm) {
          swal("Cancelado!!", "Cotización 259 cancelada", "success");
        } else {
          swal("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  }
}
</script>

<style></style>
