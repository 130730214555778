<template>
  <Layout tituloPagina="Sistema | Usuarios">
    <div class="row">
      <div class="col-lg-5">
        <CmpSecciones vista="usuarios"/>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 col-5">
        <div class="form-check form-switch form-switch-lg mb-3">
          <input
            :checked="todos"
            @change="
              todos = !todos,
              refrescarUsuarios()
            "
            class="form-check-input"
            type="checkbox"
            id="banderaTodos"
          />
          <label
            class="form-check-label"
            for="banderaTodos"
          >
            {{!todos ? 'Activos' : 'Todos'}}
          </label>
        </div>
      </div>
      <div class="col-sm-8 col-7 text-right mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarUsuarios()
          "
          :datoini="dato"
          placeholder="Dato del usuario"
        ></filtrador>
      </div>
      <div class="col-sm-2 text-right">
        <router-link :to="{ name: 'nuevoUsuario' }" class="btn btn-success">
          <i class="mdi mdi-plus-thick"></i>
          Nuevo
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 offset-md-8 text-right" style="padding-top: 10px;">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nUsuariosEnPag}} usuarios en página
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nUsuariosTotales}} usuarios en total
        </span>
      </div>
    </div>

    <br />

    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive" style="min-height: 475px;">
          <table class="table table-hover table-nowrap align-middle" id="tabla-usuarios">
            <thead>
              <tr>
                <th style="width: 30px">Foto</th>
                <th style="width: 100px">Identidad</th>
                <th>Nombre</th>
                <th>Teléfono</th>
                <th>Roles</th>
                <th style=" width: 50px">Estado</th>
                <th style=" width: 150px">Última operación</th>
                <th style=" width: 190px" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="usuario in usuarios" :key="usuario.id">
                <td>
                  <!-- modificacion del icono del usuario -->
                  <img
                    :src="`${API}/usuarios/${usuario.id}/foto?_tk=${token}&ancho=50&alto=50`"
                    class="rounded-circle"
                    style="width: 30px; height: 30px;"
                    v-if="usuario.id_foto != null"
                  />

                  <img
                    :src="usuarioicon"
                    class="usuarioicon img-circle"
                    v-if="usuario.id_foto == null"
                  />
                  <!-- fin de la modificacion -->
                </td>
                <td  class="fw-semibold">
                  {{ usuario.identidad }}
                </td>
                <td>
                  {{ usuario.nombre || '' }}
                  {{ usuario.apellido_paterno || '' }}
                  {{ usuario.apellido_materno || ''}}
                </td>
                <td>
                  {{ usuario.telefono }}
                </td>
                <td>
                  <template v-for="rol in usuario.roles">
                    <span v-if="true" :key="'rol-'+rol.id" class="badge bg-primary bg-gradient">
                      {{rol.nombre}}
                    </span>
                    <span v-if="true" :key="'esp'+rol.id" style="display: inline-block; width: 2px;"></span>
                  </template>
                </td>
                <td>
                  <span
                    v-if="usuario.activo == true"
                    class="badge bg-success bg-gradient font-size-13"
                  >
                    Activo
                  </span>
                  <span
                    v-if="usuario.activo == false"
                    class="badge bg-danger bg-gradient font-size-13"
                  >
                    Inactivo
                  </span>
                </td>
                <td>
                  {{formatoMT(usuario.mt_ultima_operacion)}}
                </td>
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'edicionUsuario',
                      params: { idUsuario: usuario.id }
                    }"
                    class="btn btn-warning btn-sm"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                    Editar
                  </router-link>
                  <button
                    @click="preguntaEliminarUsuario(usuario)"
                    class="btn btn-danger btn-sm"
                  >
                    <i class="mdi mdi-trash-can-outline"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultimaPagina"
        :epp="porPagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          porPagina = $event,
          refrescarUsuarios()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import API from '@/API.js'
import usuarioicon from '@/assets/img/usuario.png'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import CmpSecciones from '../CmpSecciones.vue'
import UsuarioSrv from '@/services/UsuarioSrv.js'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  name: 'ListadoUsuarios',
  components: { Layout, CmpSecciones, Paginador, Filtrador },
  data() {
    return {
      API: API,
      token: localStorage.getItem('argusblack.token'),
      dato: '',
      usuarios: [],
      pagina: 1,
      porPagina: parseInt(localStorage.getItem('argusblack.listadoUsuarios.por_pagina') || 10),
      todos: false,
      ultimaPagina: 1,
      usuarioicon: usuarioicon,
      nUsuariosEnPag:0,
      nUsuariosTotales:0
    }
  },

  created: function() {
    var self = this

    // Carga de los querys
    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.pagina) this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina) this.porPagina = parseInt(this.$route.query.por_pagina)
    if (this.$route.query.todos) this.todos = this.$route.query.todos == 'true' ? true : false

    self.refrescarUsuarios()
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.refrescarUsuarios()
    },
    formatoMT(mt) {
      if(mt == null) return null
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
    },
    preguntaEliminarUsuario: function(usuario) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminar usuario",
        html: '¿Está seguro que quiere eliminar el usuario <strong>' +
          usuario.nombre + ' ' +
          (usuario.apellido_paterno ? usuario.apellido_paterno : '') + ' ' +
          (usuario.apellido_materno ? usuario.apellido_materno : '') + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          UsuarioSrv.eliminar(usuario.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarUsuarios()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el usuario'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    refrescarUsuarios: function() {
      var self = this

      iu.spinner.mostrar('#tabla-usuarios')
      // Registro de los querys
      this.$router.replace({
        query: {
          dato: this.dato,
          pagina: this.pagina,
          por_pagina: this.porPagina,
          todos: this.todos
        }
      }).catch(error => {})

      let params = {
        dato: self.dato,
        pagina: self.pagina,
        por_pagina: self.porPagina,
        todos: self.todos,
        con_roles: true
      }

      UsuarioSrv.usuariosJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoUsuarios.por_pagina', self.porPagina)
        let res = response.data
        self.usuarios = res.usuarios
        self.ultimaPagina = res.ultima_pagina
        self.btnPagFin = res.ultimaPagina
        self.nUsuariosEnPag = res.nUsuariosEnPag
        self.nUsuariosTotales = res.total
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-usuarios')
      })
    }
  },
  mounted() {
    iu.spinner.mostrar('#tabla-usuarios')
  }
}
</script>

<style scoped>
.usuarioicon {
  width: 30px;
  height: 30px;
}
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>