<template>
  <Layout tituloPagina="Movimientos | Detalles">
    <div class="row">
      <div class="col-md-10">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h5 class="card-title">
              Detalles de movimiento ({{articulo.nombre}})
            </h5>
            <div>
              <a href="#" onclick="return false;" @click="cerrar()">
                <i class="mdi mdi-close text-dark"></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label for="nombre">Artículo</label>
                <input type="text" class="form-control" v-model="articulo.nombre" disabled>
              </div>
              <div class="col-md-2">
                <label for="nombre">Familia artículo</label>
                <input type="text" class="form-control" v-model="articulo.familia.familia" disabled>
              </div>
              <div class="col-md-4">
                <label for="nombre">División</label>
                <input type="text" class="form-control" v-model="articulo.familia.division" disabled>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-3">
                <label for="unidadMedida">Unidad de medida</label>
                <span class="form-control">
                  {{unidad_medida.nombre}}
                </span>
              </div>
              <div class="col-md-3" v-show="articulo.stock_minimo > 0">
                <label>Stock mínimo</label>
                <br>
                <span
                  class="form-control"
                  :class="{
                    'text-primary': articulo.stock_total >  articulo.stock_minimo,
                    'text-warning': articulo.stock_total == articulo.stock_minimo,
                    'text-danger' : articulo.stock_total <  articulo.stock_minimo
                  }"
                  
                >
                  {{articulo.stock_minimo}}
                  {{unidad_medida.nombre}}{{articulo.stock_minimo > 1 ? 's': ''}}
                </span>
              </div>
              <div class="col-md-3">
                <label>Cantidad existente</label>
                <br>
                <span class="form-control" :class="{
                  'text-primary': articulo.stock_total >  articulo.stock_minimo,
                  'text-warning': articulo.stock_total == articulo.stock_minimo,
                  'text-danger' : articulo.stock_total <  articulo.stock_minimo
                }">
                  {{articulo.stock_total}}
                  {{unidad_medida.nombre}}{{articulo.stock_total > 1 ? 's': ''}}
                </span>
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-hover table-nowrap align-middle">
                    <thead>
                      <tr>
                        <th style="width:200px">Fecha de entrada</th>
                        <th>Hora de entrada</th>
                        <th style="width: 80px;">Cantidad</th>
                        <th style="width: 90px;">Unidad de medida</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="movimiento in movimientosEntrada" :key="movimiento.id">
                        <td>{{formatoFecha(movimiento.created_at)}}</td>
                        <td>{{formatoHora(movimiento.created_at)}}</td>
                        <td class="text-center">{{movimiento.entrada}}</td>
                        <td>{{movimiento.abreviatura_unidad_medida}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-center">
                  <paginador
                    :pag="movEntrada.page"
                    :totpag="movEntrada.totpag"
                    :epp="movEntrada.epp"
                    @cargar-pagina="cargarPaginaEntrada"
                    @epp-actualizado="
                      movEntrada.epp = $event,
                      refrescarMovimientosEntrada()
                    "
                  ></paginador>
                </div>
              </div>
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-hover table-nowrap align-middle">
                    <thead>
                      <tr>
                        <th style="width:200px">Fecha de salida</th>
                        <th>Hora de salida</th>
                        <th style="width:80px;">Cantidad</th>
                        <th style="width: 90px;">Unidad de medida</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="movimiento in movimientosSalida" :key="movimiento.id">
                        <td>{{formatoFecha(movimiento.created_at)}}</td>
                        <td>{{formatoHora(movimiento.created_at)}}</td>
                        <td class="text-center">{{movimiento.salida}}</td>
                        <td>{{movimiento.abreviatura_unidad_medida}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-center">
                  <paginador
                    :pag="movSalida.page"
                    :totpag="movSalida.totpag"
                    :epp="movSalida.epp"
                    @cargar-pagina="cargarPaginaSalida"
                    @epp-actualizado="
                      movSalida.epp = $event,
                      refrescarMovimientosSalida()
                    "
                  ></paginador>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button @click="cerrar()" class="btn btn-secondary">
            <i class="mdi mdi-chevron-left"></i>
            Atras
          </button>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import MovimientoArticulosSrv from '@/services/inventario/MovimientoArticulosSrv.js'
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import Paginador from '@/components/Paginador.vue'
import Layout from "@/views/layouts/main"
import moment from 'moment'

export default {
  components:{ 
    Paginador, 
    Layout
  },
  data() {
    return {
      articulo: {
        id: null,
        nombre: '',
        descripcion: '',
        id_familia: null,
        familia: {},
        campos: [],
        stock_minimo: 0,
        stock_total: 0,
        foto_principal: null,
        id_unidad_medida: null,
      },

      id_articulo: null,
      movimientosEntrada: [],
      movimientosSalida: [],
      movEntrada:{
        page: 1,
        epp: parseInt(localStorage.getItem('argusblack.detallesMovimiento.por_pagina_entrada') || 10),
        tc: 0, // Total de clientes
        totpag: 0, // Total de páginas
      },
      movSalida:{
        page: 1,
        epp: parseInt(localStorage.getItem('argusblack.detallesMovimiento.por_pagina_salida') || 10),
        tc: 0, // Total de clientes
        totpag: 0, // Total de páginas
      },

      unidad_medida: {
        nombre: '',
        abreviatura: ''
      }
    }
  },
  created() {
    let self = this

    self.cargarArticulo(self.$route.params.id).then(response => {
      self.cargarUnidadMedida(response.data.id_unidad_medida).then(response => {
        self.refrescar()
      })
    })
  },
  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    cerrar: function() {
      this.atras()
    },

    cargarPaginaEntrada: function(n) {
      var self = this
      self.movEntrada.page = n
      self.refrescarMovimientosEntrada({ page: n })
    },

    cargarPaginaSalida: function(n) {
      var self = this
      self.movSalida.page = n
      self.refrescarMovimientosSalida({ page: n })
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },

    formatoHora(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
    },

    refrescar() {
      this.refrescarMovimientosEntrada()
      this.refrescarMovimientosSalida()
    },

    refrescarMovimientosEntrada() {
      var self = this

      let params = {
        dato: self.movEntrada.dato,
        page: self.movEntrada.page,
        epp: self.movEntrada.epp,
        orden: 'desc',
        id_articulo: self.id_articulo,
        solo_entradas: true,
      }

      if(self.idUnidadMedidaSeleccionada != null)
        params ["id_unidad_medida"] = self.idUnidadMedidaSeleccionada


      MovimientoArticulosSrv.movimientosJSON(params).then(response => {
        localStorage.setItem('argusblack.detallesMovimiento.por_pagina_entrada', self.movEntrada.epp)
        
        let page = response.data
        self.movimientosEntrada = page.data
        self.movEntrada.tc = page.total
        self.movEntrada.totpag = page.last_page

        if (self.movEntrada.totpag < self.page) {
          self.page = self.movEntrada.totpag
          self.refrescarMovimientosEntrada()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los movimientos de artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    refrescarMovimientosSalida: function() {
      var self = this

      let params = {
        dato: self.movSalida.dato,
        page: self.movSalida.page,
        epp: self.movSalida.epp,
        orden: 'desc',
        id_articulo: self.id_articulo,
        solo_salidas: true,
      }

      if(self.idUnidadMedidaSeleccionada != null)
        params ["id_unidad_medida"] = self.idUnidadMedidaSeleccionada


      MovimientoArticulosSrv.movimientosJSON(params).then(response => {
        localStorage.setItem('argusblack.detallesMovimiento.por_pagina_salida', self.movSalida.epp)
        let page = response.data
        self.movimientosSalida = page.data
        self.movSalida.tc = page.total
        self.movSalida.totpag = page.last_page

        if (self.movSalida.totpag < self.page) {
          self.page = self.movSalida.totpag
          self.refrescarMovimientosSalida()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los movimientos de artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarArticulo(id) {
      let self = this

      let idArticulo = id

      self.id_articulo = id
      
      let params = {
        con_cantidad: true,
        con_familia: true,
        con_unidades_medida: true
      }

      if(self.idUnidadMedidaSeleccionada != null) 
        params["id_unidad_medida"] = self.idUnidadMedidaSeleccionada

      let prom = ArticuloSrv.articuloJSON(idArticulo,params)

      prom.then(response => {
        self.articulo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el artículo '+idArticulo
        }
        iu.msg.error(mensaje)
        console.log(error)
      })

      return prom
    },

    cargarUnidadMedida(idArticulo) {
      let self = this
      
      let prom = ArticuloSrv.unidadMedidaArticuloJSON(idArticulo)
      
      prom.then(response => {
        self.unidad_medida = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el artículo '+idArticulo
        }
        iu.msg.error(mensaje)
        console.log(error)
      })

      return prom
    }
  }
}
</script>

<style>

</style>