<template>
  <Layout :tituloPagina="'Network | Routers'">
    <div class="row mb-3">
      <div class="col-md-2 col-2">
        <router-link class="btn btn-success" :to="{ name: 'estadoRed' }">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-server-network"></i>
            Estado de red
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-server-network"></i>
          </span>
        </router-link>
      </div>
      <div class="col-md-8 col-8 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarRouters()
          "
          :datoini="dato"
          placeholder="Dato del router"
        ></filtrador>
      </div>
      <div class="col-md-2 col-2 text-right">
        <router-link class="btn btn-success" :to="{ name: 'nuevoRouter' }">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>

    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive" style="min-height: 475px;">
          <table class="tblRouters table table-hover table-nowrap align-middle" id="tabla-router">
            <thead>
              <tr>
                <th>#ID</th>
                <th style="width: 250px">Nombre</th>
                <th>Descripción / Sitio / Último contacto</th>
                <th>Enlace a VPN</th>
                <th>Dirección IP en VPN</th>
                <th>Conexión a API</th>
                <th style="width: 100px">CPEs</th>
                <th>autoconfig</th>
                <th class="text-center" style="width: 50px">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="router in routers" :key="router.id">
                <td class="fw-semibold">{{ router.id }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'edicionRouter',
                      params: { id: router.id }
                    }"
                  >
                    {{ router.nombre }}
                  </router-link>
                </td>
                <td>
                  <p class="text-truncate font-size-14" v-if="router.descripcion" :title="router.descripcion">
                    {{ router.descripcion }}
                  </p>
                  <span v-if="router.sitio != null" class="d-inline-block">
                    <strong>Sitio:</strong> {{ router.sitio.nombre }}
                  </span> 
                  <br v-if="router.ultimo_contacto != null">
                  <span v-if="router.ultimo_contacto != null" class="d-inline-block">
                    <strong>Último contacto:</strong> {{fechaYHora(router.ultimo_contacto)}}
                  </span>
                  <div class="d-inline-block ps-2">
                    <router-link
                      class="text-right"
                      :to="{
                        name: 'registroCaidasRouter',
                        params: { id: router.id }
                      }"
                    >
                      Registros de caídas
                    </router-link>
                  </div>
                </td>

                <td>
                  <span class="badge bg-gradient font-size-13"
                    :class="{
                      'bg-success': router.en_linea,
                      'bg-danger': !router.en_linea
                    }">
                    <i class="mdi"
                      :class="{
                        'mdi-check-bold': router.en_linea ,
                        'mdi-close-thick': !router.en_linea 
                      }"
                      ></i>
                    {{router.en_linea == true? 'Activo': 'Inactivo'}}
                  </span>
                </td>

                <td>
                  {{ router.ip }}
                </td>
                
                <td>
                  <span
                    class="badge bg-gradient font-size-13"
                    :class="{
                      'bg-primary': router.en_linea && router.intentos_conexion_fallidos_api == 0,
                      'bg-danger': !router.en_linea || router.intentos_conexion_fallidos_api > 0
                    }"
                  >
                    <i class="mdi"
                      :class="{
                        'mdi-check-bold': router.en_linea && router.intentos_conexion_fallidos_api == 0,
                        'mdi-close-thick': !router.en_linea || router.intentos_conexion_fallidos_api > 0
                      }"
                      ></i>
                    {{router.en_linea && router.intentos_conexion_fallidos_api == 0? 'Correcta':'Error'}}
                  </span>
                </td>
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'listadoCpes',
                      params: { id_router: router.id }
                    }"
                  >
                    {{ router.cantidad_cpes.total }}
                    cpe{{
                      router.cantidad_cpes.total == 0 ||
                      router.cantidad_cpes.total > 1
                        ? 's'
                        : ''
                    }}
                  </router-link>
                </td>
                <td>
                  <div class="d-grid gap-2">
                    <button
                      class="btn btn-light btn-sm"
                      @click="autoconfig(router)"
                    >
                      <i class="mdi mdi-cloud-download font-size-15"></i>
                    </button>
                  </div>
                </td>
                <td class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li>
                        <router-link
                          :to="{ name:  $store.state.todo.nombre_empresa != 'Livaur Internet 2' ? 'tableroRouter' : 'tableroRouter2', params: { id: router.id } }"
                          class="dropdown-item primary"
                        >
                          <i class="mdi mdi-chart-bell-curve-cumulative font-size-15 text-primary"></i>
                          Tablero
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          :to="{ name: 'edicionRouter', params: { id: router.id } }"
                          class="dropdown-item warning"
                        >
                          <i class="mdi mdi-content-save-edit-outline font-size-15 text-warning"></i>
                          Editar
                        </router-link>
                      </li>
                      <li>
                        <button
                          class="dropdown-item danger"
                          @click="preguntaEliminar(router)"
                        >
                          <i class="mdi mdi-trash-can-outline font-size-15 text-danger"></i>
                          Eliminar
                        </button>
                      </li>
                      <li></li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="6"></td>
                <td class="text-right">
                  <strong> {{ total_cpes }} cpes </strong>
                </td>
                <td colspan="2"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import Filtrador from '@/components/Filtrador.vue'
import RouterSrv from '@/services/RouterSrv.js'
import Swal from 'sweetalert2'
import moment from 'moment'
import API from '@/API.js'
export default {
  name: 'ListadoRouters',
  components: { Layout, Filtrador },
  data() {
    return {
      baseUrl: window.location.origin,
      dato: '',
      routers: [],
      total_cpes: 0,
      mostrar_spinner: true,
      intervals: {
        actualizacion: null
      }
    }
  },
  created: function() {
    var self = this

    if (this.$route.query.dato) this.dato = this.$route.query.dato

    self.refrescarRouters()

    self.intervals.actualizacion = setInterval(function() {
      self.mostrar_spinner = false
      self.refrescarRouters()
    }, 30000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    actualizarTotalCpes: function() {
      var self = this
      self.total_cpes = 0
      self.routers.forEach(function(router, index) {
        self.total_cpes += router.cantidad_cpes.total
      })
    },
    autoconfig: function(router) {
      let tk = localStorage.getItem('argusblack.token')
      window.open(`${API}/routers/${router.id}/autocfg?_tk=${tk}`, '_blank')
    },
    fechaYHora(mt) {
      return moment(mt, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY hh:mm:ss')
    },
    preguntaEliminar: function(router) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminación de router",
        html: '¿Está seguro que desea eliminar el router <strong>'+router.nombre+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          RouterSrv.eliminar(router.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarRouters()
            self.actualizarTotalCpes()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    refrescarRouters: function() {
      var self = this
      
      if(self.mostrar_spinner) iu.spinner.mostrar('#tabla-router')

      self.$router.replace({
        query: {
          dato: self.dato
        }
      }).catch(error => {})

      let params = { dato: self.dato, con_sitio: true }

      RouterSrv.routersJSON(params).then(response => {
        self.routers = response.data
        self.actualizarTotalCpes()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        if(self.mostrar_spinner) iu.spinner.ocultar('#tabla-router')
        self.mostrar_spinner = true
      })
    }
  },
  mounted() {
    iu.spinner.mostrar('#tabla-router')
  }
}
</script>

<style scoped>
.text-truncate {
  width: 420px;
}
</style>