<template>
  <div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10">
        <h2>Network</h2>
        <ol class="breadcrumb">
          <li>
            <strong>Administracion IPv4</strong>
          </li>
        </ol>
      </div>
    </div>
    <cmp-secciones seccion="IPv4"></cmp-secciones>
    <div id="listadoIPv4" class="wrapper wrapper-content">
      <div class="row">
        <div class="col-md-8 col-md-offset-2">
          <filtrador
            v-on:dato-actualizado="
              dato = $event,
              refrescarlistadoCPEs()
            "
            :datoini="dato"
            placeholder="Buscar Router"
          ></filtrador>
          &nbsp;
        </div>
        <div class=" col-md-2 text-right">
          <button class="btn btn-default" @click="cerrar()">
            <i class="fa fa-angle-left"></i>
            Atrás
          </button>
        </div>
      </div>
      <br />
      <div class="ibox">
        <div class="ibox-title">
          <h5>Listado IP {{ routerNombre }}</h5>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <table class="table table-hover align-middle">
              <thead>
                <tr>
                  <th style="width: 50px"></th>
                  <th style="width: 250px">IP WAN</th>
                  <th class="text-center">
                    Estado
                  </th>
                  <th class="text-center">
                    Cliente asignado
                  </th>
                  <th class="text-center" style="width:250px">CPE asignado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ipscpes in listaCPEs" :key="ipscpes.id">
                  <td></td>
                  <td>
                    {{ ipscpes.ip_wan }}
                    <small class="text-navy">
                      <h5>IP Estatica</h5>
                    </small>
                    <!-- <small class="text-warning" v-show="ipscpes.sipppoe != 0">
                      <h5>PPPOE</h5>
                    </small> -->
                  </td>
                  <td class="text-center" style="padding:22px 0 0 0">
                    <label
                      class="label label-default"
                      style="background-color:#fff; font-size:13px; border:1px solid lightgrey;"
                    >
                      Ocupada
                    </label>
                    <!-- <label class="label label-info" style="font-size:13px;">
                      Disponible
                    </label>-->
                  </td>
                  <td class="text-center">
                    <router-link
                      :to="{
                        name: 'tableroCliente',
                        params: { id_cliente: ipscpes.id_cliente }
                      }"
                      v-if="
                        ipscpes.nombre_cliente != undefined &&
                          ipscpes.id_cliente != null
                      "
                    >
                      {{ ipscpes.nombre_cliente }}
                    </router-link>
                  </td>
                  <td class="text-center" style="width:250px">
                    <div style="min-height: 30px; display: inline-block">
                      <img
                        :src="
                          API +
                            '/cpes/' +
                            ipscpes.id +
                            '/foto?ancho=50&alto=50&cacheable=1&_tk=' +
                            tk
                        "
                      />
                    </div>
                    <span
                      class="text-success"
                      v-if="ipscpes.en_linea == true"
                      title="Conectado"
                    >
                      <i class="fa fa-signal"></i>
                    </span>
                    <span
                      class="text-danger"
                      v-if="ipscpes.en_linea == false"
                      title="Desconectado"
                    >
                      <i class="fa fa-exclamation-circle"></i>
                    </span>
                    <router-link
                      :to="{
                        name: 'edicionCpe',
                        params: { id: ipscpes.id }
                      }"
                    >
                      {{ ipscpes.nombre }}
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="text-center">
        <paginador
          :pag="pagina"
          :totpag="ultima_pagina"
          :epp="por_pagina"
          v-on:cargar-pagina="cargarPagina"
          v-on:epp-actualizado="
            por_pagina = $event,
            refrescarlistadoCPEs()
          "
        ></paginador>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/API.js'
import Filtrador from '@/components/Filtrador.vue'
import CmpSecciones from '../CmpSecciones.vue'
import CpeSrv from '@/services/CpeSrv.js'
import Paginador from '@/components/Paginador.vue'

//var ipaddr = require('ipaddr.js')

export default {
  name: 'ListadoIPWan',
  components: { CmpSecciones, Filtrador, Paginador },
  data: function() {
    return {
      tk: localStorage.getItem('argusblack.token'),
      API: API,
      baseUrl: window.location.origin,
      dato: '',
      listaCPEs: [],
      routerNombre: '',
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.listadoIPWan.por_pagina') || 10),
      ultima_pagina: 1,
      id_router: null,
      red_lan: null
    }
  },
  created: function() {
    var self = this
    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.pagina)
      this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina)
      this.por_pagina = parseInt(this.$route.query.por_pagina)
    if (this.$route.params.id_router)
      this.id_router = this.$route.params.id_router
    else if (this.$route.query.id_router)
      this.id_router = this.$route.query.id_router
    if (this.$route.params.red_lan) this.red_lan = this.$route.params.red_lan
    else if (this.$route.query.red_lan) this.red_lan = this.$route.query.red_lan
    self.refrescarlistadoCPEs()
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.refrescarlistadoCPEs()
    },
    cerrar: function() {
      this.$router.go(-1)
    },
    refrescarlistadoCPEs: function() {
      var self = this
      this.$router.replace({
        query: {
          dato: self.dato,
          pagina: self.pagina,
          por_pagina: self.por_pagina,
          id_router: self.id_router,
          red_lan: self.red_lan
        }
      })
      let params = {
        dato: self.dato,
        id_router: self.id_router,
        red_lan: self.red_lan,
        pagina: self.pagina,
        por_pagina: self.por_pagina
      }

      CpeSrv.listadoCPEsJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoIPWan.por_pagina', self.por_pagina)
        let res = response.data
        self.pagina = res.pagina
        self.total = res.total
        self.ultima_pagina = res.ultima_pagina
        self.listaCPEs = res.listadoipcpes
        self.routerNombre = res.routerNombre
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
  mounted() {
    $('#listadoIPv4').fadeIn()
  }
}
</script>

<style>
#listadoIPv4 {
  display: none;
}
</style>
