<template>
  <Layout tituloPagina="Pagos | Selección de cliente | Facturas pendientes de pago">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Facturas pendientes de pago
        </h4>
        <div>
          <a href="#" @click="cerrar()" onclick="return false;">
            <i class="mdi mdi-close text-dark"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3">
            <h5>Cliente</h5>
            <strong>ID:</strong>&nbsp;{{ cliente.id }}
            <br />
            <strong>Nombre:</strong>&nbsp;
            <a
              class="text-right cursor"
              @click="verTableroCliente(cliente.id)"
            >
              {{ cliente.nombre }}
              {{ cliente.apellido_paterno }}
              {{ cliente.apellido_materno }}
            </a>

            <br />
            <br />
            <strong>Dirección:</strong> <br />
            {{ cliente.calle }}, 
            <span v-show="cliente.numero_exterior != null && cliente.numero_exterior != ''">
              {{cargarAliasCampo('N° ext')}} {{ cliente.numero_exterior }}
            </span>,
            <span v-show="cliente.numero_interior != null && cliente.numero_interior != ''">
              {{cargarAliasCampo('N° int')}} {{ cliente.numero_interior }}
            </span>
            <br />
            {{ cliente.colonia }} <br />
            {{ cliente.municipio }} <br />
            {{ cliente.estado_residencia }} <br />
            {{ cliente.pais }}
          </div>
          <div class="col-lg-9">
            <div class="row">
              <div class="col-md-8">
                <h5>Facturas pendientes de pago</h5>
              </div>
              <div class="col-md-4 text-right">
                <button class="btn btn-light btn-sm me-3" @click="mostrarFacturasAnticipadas()">
                  <i class="mdi mdi-cogs"></i>
                  Facturas anticipadas
                </button>
                <router-link
                  :to="{
                    name: 'nuevaFactura',
                    params: { id_cliente: cliente.id }
                  }"
                  class="btn btn-success btn-sm"
                >
                  <i class="mdi mdi-plus-thick"></i>
                  Nueva factura
                </router-link>
              </div>
            </div>

            <div class="contenedorTblFacturas table-responsive">
              <table class="tblFacturas table table-hover table-nowrap align-middle" id="tablasPagos">
                <thead>
                  <tr>
                    <th style="width: 50px">
                      <input
                        type="checkbox"
                        class="cursor"
                        v-model="todasFacturasSeleccionadas"
                        @change="palancaTodasFacturas()"
                      />
                    </th>
                    <th>Folio</th>
                    <th>Contrato</th>
                    <th>Fecha</th>
                    <th>Fecha para pago</th>
                    <th>Periodo</th>
                    <th>Total de factura</th>
                    <th>Saldo pendiente</th>
                    <th class="text-center" style="width: 150px">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(factura, index) in facturas" :key="factura.id">
                    <td>
                      <input
                        type="checkbox"
                        v-model="factura.a_pagar"
                        class="cursor"
                        @change="
                          actualizarTotalACobrar(),
                          activarCheckTodasFacturas()
                        "
                        :disabled="validarFactura(index, factura.id, factura.id_contrato, factura.inicio_periodo)"
                      />
                    </td>
                    <td>
                      <router-link :to="{
                        name: 'factura',
                        params: {
                          id: factura.id
                        }
                      }">
                        {{ factura.ns }}
                        <sup
                          title="Generada por sistema"
                          class="badge badge-pill badge-soft-info"
                          v-show="factura.generada_por_sistema == true"
                        >GS</sup>
                        <sup
                          title="Factura anticipada"
                          class="badge badge-pill badge-soft-dark"
                          v-show="factura.anticipada == true"
                        >FA</sup>
                        <sup
                          v-show="factura.prorroga > 0"
                          class="badge badge-pill badge-soft-success font-size-11"
                          title="Tiene promesa de pago"
                        >P</sup>
                      </router-link>
                    </td>
                    <td>
                      <template v-if="factura.id_contrato != null">
                        <router-link
                          :to="{
                            name: 'edicionContrato',
                            params: {
                              id_cliente: factura.id_cliente,
                              id_contrato: factura.id_contrato
                            }
                          }"
                        > 
                          {{factura.id_contrato}}
                        </router-link>
                      </template>
                    </td>
                    <td>{{ formatoFecha(factura.fecha) }}</td>
                    <td>{{ factura.fecha_para_pago ? formatoFecha(factura.fecha_para_pago) : null }}</td>
                    <td>
                      <template
                        v-if="
                          factura.inicio_periodo != null &&
                            factura.fin_periodo != null
                        "
                      >
                        De {{ formatoFecha(factura.inicio_periodo) }} <br />
                        al {{ formatoFecha(factura.fin_periodo) }}
                      </template>
                    </td>
                    <td class="text-right">
                      {{ hrr.fmon(factura.total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                    </td>
                    <td class="text-right">
                      <strong>
                        {{ hrr.fmon(factura.saldo, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                      </strong>
                    </td>
                    <td class="text-right">
                      <div class="btn-group">
                        <button
                          class="btn btn-soft-dark btn-sm" title="Imprimir factura"
                          @click="imprimirFactura(factura)"
                        >
                          <i class="mdi mdi-printer"></i>
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          v-show="!factura.cancelada && !factura.pagada"
                          @click="preguntaCancelarFactura(factura)"
                          title="Cancelar factura">
                          <i class="mdi mdi-cancel"></i>
                        </button>
                        <router-link
                          :to="{ name: 'factura', params: { id: factura.id } }"
                          class="btn btn-info btn-sm"
                          title="Mostrar factura"
                        >
                          <i class="mdi mdi-eye"></i>
                          Mostrar
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <h5>Últimos 5 pagos</h5>
            <div class="row">
              <div class="col-md-12">
                <div class="contenedorTblAbonos table-responsive">
                  <table class="tblAbonos table table-hover table-nowrap align-middle">
                    <thead>
                      <tr>
                        <th style="width: 70px">#ID</th>
                        <th style="width: 100px">Fecha</th>
                        <th style="width: 100px">Hora</th>
                        <th style="width: 100px">Importe</th>
                        <th style="width: 150px">Método de pago</th>
                        <th>Facturas pagadas</th>
                        <th class="text-center" style="width: 200px">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="abono in abonos" :key="abono.id">
                        <td>{{ abono.id }}</td>
                        <td>{{ formatoFecha(abono.fecha) }}</td>
                        <td>{{ formatoHora(abono.hora) }}</td>
                        <td class="text-right">
                          {{ hrr.fmon(abono.importe, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                        </td>
                        <td>
                          <span class="badge bg-gradient font-size-12" 
                            :class="{
                              'bg-success': abono.id_metodo_pago == 1,
                              'bg-primary': abono.id_metodo_pago == 2,
                              'bg-warning': abono.id_metodo_pago == 3,
                              'bg-info': abono.id_metodo_pago == 4,
                              'bg-dark': abono.id_metodo_pago == 102
                            }"
                          >
                            {{abono.id_metodo_pago == 1 ? 'Efectivo' : ''}}
                            {{abono.id_metodo_pago == 2 ? 'Depósito' : ''}}
                            {{abono.id_metodo_pago == 3 ? 'Transferencia' : ''}}
                            {{abono.id_metodo_pago == 4 ? 'PayPal' : ''}}
                            {{abono.id_metodo_pago == 102 ? 'Mercado Pago' : ''}}
                          </span>
                        </td>
                        <td>
                          <template v-for="factura in abono.facturas_pagadas">
                            <router-link
                              :to="{
                                name: 'factura',
                                params: { id: factura.id }
                              }"
                              v-if="true"
                              :key="factura.id"
                            >
                              {{ factura.ns }}
                            </router-link>
                            &nbsp;
                          </template>
                        </td>
                        <td class="text-right">
                          <div class="btn-group">
                            <button class="btn btn-info btn-sm" @click="detallesPago(abono.id)">
                              <i class="mdi mdi-eye"></i>
                              Ver
                            </button>
                            <button class="btn btn-soft-dark btn-sm" @click="imprimirTicketPago(abono)">
                              <i class="mdi mdi-printer"></i>
                              Imprimir
                            </button>
                            <button
                              type="button" title="Cancelar abono"
                              class="btn btn-danger btn-sm"
                              @click="preguntaCancelarAbono(abono)"
                              v-if="abono.cancelable"
                            >
                              <i class="mdi mdi-cancel"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col-md-5">
                <div>
                  <div class="input-group">
                    <span class="input-group-text">
                      Saldo a favor del cliente
                      <i class="mdi mdi-concourse-ci mdi-spin" v-show="cargandoSaldo"></i>
                    </span>
                    <input
                      type="text"
                      class="saldoAFavor text-right form-control"
                      :value="hrr.fmon(cliente.saldo_a_favor, { symbol: monedaSistema.simbolo, miles: ',', decimal: '.' })"
                      readonly
                    />
                  </div>
                  <div class="pt-3" v-show="cliente.saldo_a_favor > 0">
                    <label for="saldoAFavor">Usar saldo a favor para pagar cuenta</label>
                    &nbsp;
                    <input
                      id="saldoAFavor"
                      type="checkbox"
                      v-model="usarSaldoAFavor"
                      @change="actualizarTotalACobrar()"
                    />
                  </div>
                </div>
              </div>
              <div class="offset-md-2 col-md-5" style="height: 60px">
                <div class="input-group">
                  <span class="input-group-text">
                    Saldo por cobrar
                  </span>
                  <input
                    type="text"
                    class="saldoTotalFacturas text-right form-control"
                    :value="hrr.fmon(totalACobrar, { symbol: monedaSistema.simbolo, miles: ',', decimal: '.' })"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <a class="btn btn-secondary" @click="cerrar()">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </a>
        <a class="btn btn-success" @click="irAConfirmarPago()" v-if="confirmar">
          <i class="bx bx-dollar"></i> Continuar
        </a>
      </div>
    </div>

    <br>

    <mdl-detalle-pago ref="mdl_detalles_pago" />
    <mdl-facturas-anticipadas 
      ref="modal_facturas_anticipadas"
      @facturas:anticipadas:actualizar="recargarTodo()"
    ></mdl-facturas-anticipadas>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import API from "@/API.js"
import AbonoSrv from "@/services/AbonoSrv.js"
import FacturaSrv from "@/services/FacturaSrv.js"
import ClienteSrv from "@/services/ClienteSrv.js"

import MdlDetallePago from "./MdlDetallePago.vue"
import MdlFacturasAnticipadas from "../facturas/MdlFacturasAnticipadas.vue"
import moment from "moment"
import md5 from 'md5'

import { todoGetters } from "@/state/helpers"
import Swal from 'sweetalert2'

export default {
  name: "FacturasPendientesPago",
  components: { Layout, MdlDetallePago, MdlFacturasAnticipadas },
  data() {
    return {
      API: API,
      hrr: iu.hrr,
      token: localStorage.getItem("argusblack.token"),
      baseUrl: window.location.origin,
      clientes: [],
      cliente: {
        id: null,
        saldo_a_favor: 0,
      },
      facturas: [],
      factura: {},
      abonos: [],
      contratos: [],
      rels: [],
      rel: {},
      cargandoSaldo: true,
      totalACobrar: 0,
      todasFacturasSeleccionadas: true,
      usarSaldoAFavor: false,
      saldoAFavorAUsar: 0,

      confirmar: false
    }
  },
  computed: {
    ...todoGetters,
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function () {
    var self = this;
    var idCliente = this.$route.params.id_cliente;

    self.cliente.id = idCliente;

    // Cargas iniciales
    self.cargarCliente();
    self.refrescarFacturasPendientes();
    self.refrescarAbonos();
  },
  mounted: function() {
    iu.spinner.mostrar('#tablasPagos')
  },
  methods: {
    activarCheckTodasFacturas: function () {
      var self = this,
        n = 0;
      self.facturas.forEach(function (factura) {
        if (factura.a_pagar) n++;
      });

      if (n == self.facturas.length) self.todasFacturasSeleccionadas = true;
      else self.todasFacturasSeleccionadas = false;
    },

    actualizarTotalACobrar: function () {
      var total = 0;

      this.facturas.forEach(function (factura) {
        if (!factura.a_pagar) return;

        // Suma el total de la factura al total general
        total += factura.saldo;
      });

      if (this.usarSaldoAFavor) {
        if (this.cliente.saldo_a_favor <= total) {
          this.saldoAFavorAUsar = this.cliente.saldo_a_favor;
          total -= this.cliente.saldo_a_favor;
        } else {
          this.saldoAFavorAUsar = total;
          total = 0;
        }
      }

      this.totalACobrar = total;
    },

    cancelarAbono: function (abono) {
      var self = this;

      self.confirmar = false

      AbonoSrv.cancelar(abono.id).then((response) => {
        iu.msg.success("Se canceló correctamente");

        self.cargarCliente();
        self.refrescarFacturasPendientes();
        self.refrescarAbonos();
        self.refrescarTotalesCliente();
      }).catch((error) => {
        let mensaje;
        try {
          mensaje = error.response.data.message;
        } catch (e) {
          mensaje = "No se pudo cancelar el abono";
        }
        iu.msg.error(mensaje);
        console.log(error);
      }).finally(() => {
        self.confirmar = true
      })
    },
    
    cargarCliente() {
      var self = this;
      var idCliente = this.$route.params.id_cliente;

      ClienteSrv.clienteJSON(idCliente).then((response) => {
        let cliente = response.data;
        Object.assign(self.cliente, cliente);

        self.cargarSaldos();
      }).catch((error) => {
        let mensaje;
        try {
          mensaje = error.response.data.message;
        } catch (e) {
          mensaje = "Cliente no encontrado";
        }
        iu.msg.error(mensaje);
        console.log(error);
      })
    },

    cargarSaldos() {
      let self = this;
      let idCliente = this.$route.params.id_cliente;
      ClienteSrv.saldosJSON(idCliente).then((response) => {
        let saldos = response.data;

        // Cálculo de saldo a favor del cliente
        self.cliente.saldo_a_favor = parseFloat(
          saldos.total_abonos - saldos.total_abonos_asignados
        ).toFixed(2);
        self.cargandoSaldo = false;
      }).catch((error) => {
        self.cargandoSaldo = false;
      })
    },

    refrescarAbonos: function () {
      var self = this;
      var idCliente = this.cliente.id;

      let params = { id_cliente: idCliente, orden: "desc", cantidad: 5 };
      AbonoSrv.vabonos1JSON(params).then((response) => {
        self.abonos = response.data;
      }).catch((error) => {
        let mensaje;
        try {
          mensaje = error.response.data.message;
        } catch (e) {
          mensaje = "Cliente no encontrado";
        }
        iu.msg.error(mensaje);
        console.log(error);
      })
    },

    irAConfirmarPago: function () {
      var self = this,
        facturasAPagar = [];

      this.facturas.forEach(function (factura) {
        if (!factura.a_pagar) return;

        facturasAPagar.push(JSON.stringify(factura));
      });

      // Verificación de selección de al menos una factura
      if (self.facturas.length > 0 && facturasAPagar.length == 0) {
        iu.msg.warning("Es necesario seleccionar al menos una factura");
        return;
      }

      self.$router.push({
        name: "confirmarPago",
        params: {
          id_cliente: self.cliente.id,
          cliente: JSON.stringify(self.cliente),
          facturasAPagar: facturasAPagar,
          totalACobrar: self.totalACobrar,
          usarSaldoAFavor: self.usarSaldoAFavor,
          saldoAFavorAUsar: self.saldoAFavorAUsar,
        },
      });
    },

    cancelarFactura(idFactura, params) {
      let self = this
      params = params || {}

      self.confirmar = false

      FacturaSrv.cancelar(idFactura, params).then(response => {
        iu.msg.success('Factura cancelada correctamente.')
        
        self.cargarCliente();
        self.refrescarFacturasPendientes();
        self.refrescarAbonos();
        self.refrescarTotalesCliente();
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cancelar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.confirmar = true
      })
    },

    cerrar: function () {
      this.$router.go(-1);
    },

    detallesPago: function (idPago) {
      var self = this;

      self.$refs.mdl_detalles_pago.mostrar(idPago);
    },

    mostrarFacturasAnticipadas: function () {
      var self = this,
        idCliente = self.cliente.id

      self.$refs.modal_facturas_anticipadas.mostrar(idCliente)
    },

    imprimirTicketPago(abono) {
      let url = API+"/abonos/"+abono.id+"/ticket/"+md5(abono.id.toString())
      window.open(url,'_blank')
    },

    imprimirFactura(factura) {
      let url = API+"/facturas/"+factura.id+"/impresion/"+md5(factura.id.toString())
      window.open(url,'_blank')
    },

    formatoFecha: function (mt) {
      return moment(mt, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    formatoHora: function (mt) {
      return moment(mt, "h:mm:ss").format("LT");
    },

    refrescarFacturasPendientes: function () {
      var self = this;
      var idCliente = this.cliente.id;

      iu.spinner.mostrar('#tablasPagos')

      self.confirmar = false

      FacturaSrv.pendientesPagoJSON({ id_cliente: idCliente }).then((response) => {
        let facturas = response.data;

        // Seleccion de las facturas a pagar
        facturas.forEach(function (factura) {
          factura.a_pagar = true;
        });
        // Actualización de las facturas
        self.facturas = facturas;

        self.actualizarTotalACobrar();
      }).catch((error) => {
        let mensaje;
        try {
          mensaje = error.response.data.message;
        } catch (e) {
          mensaje = "";
        }
        mensaje != "" ? iu.msg.error(mensaje) : null;
        console.log(error);
      }).finally(() => {
        iu.spinner.ocultar('#tablasPagos')
        self.confirmar = true
      })
    },

    refrescarTotalesCliente: function () {
      var self = this;
      let idCliente = this.cliente.id;

      ClienteSrv.totalesJSON(idCliente).then((response) => {
        let totales = response.data;
        self.cliente.total_facturas = totales.total_facturas;
        self.cliente.total_abonos = totales.total_abonos;
        self.cliente.total_abonos_asignados = totales.total_abonos_asignados;
        self.cliente.saldo = totales.saldo;

        self.cliente.saldo_a_favor = parseFloat(
          totales.total_abonos - totales.total_abonos_asignados
        ).toFixed(2);
      }).catch((error) => {
        let mensaje;
        try {
          mensaje = error.response.data.message;
        } catch (e) {
          mensaje = "";
        }
        mensaje != "" ? iu.msg.error(mensaje) : null;
        console.log(error);
      })
    },

    recargarTodo: function() {
      var self = this 

      self.cargarCliente();
      self.refrescarFacturasPendientes();
      self.refrescarAbonos();
      self.refrescarTotalesCliente();
    },

    preguntaCancelarAbono: function (abono) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });

      swal.fire({
        title: "Cancelación de abono",
        html: '¿Desea cancelar el abono con ID <strong>'+abono.id+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          AbonoSrv.cancelar(abono.id).then((response) => {
            swal.fire("Cancelado!", "Se canceló correctamente", "success")
            self.cargarCliente();
            self.refrescarFacturasPendientes();
            self.refrescarAbonos();
            self.refrescarTotalesCliente();
          }).catch((error) => {
            let mensaje;
            try {
              mensaje = error.response.data.message;
            } catch (e) {
              mensaje = "No se pudo cancelar el abono";
            }
            iu.msg.error(mensaje);
            console.log(error);
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    preguntaCancelarFactura(factura) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "Cancelación de factura",
        html: '¿Desea cancelar la factura <strong>'+factura.ns+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          if(factura.id_contrato != null && factura.fecha_para_pago != null) {
            swal.fire("¡Cancelación de factura!", "Cancelación de factura "+factura.ns, "success")
            setTimeout(function(){
              swal.fire({
                title:  'Regeneración de factura',
                html:  '¿Deseas que el sistema vuelva a regenerar la factura?',
                icon: "info",
                confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
                cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
                showCancelButton: true
              }).then(result => {
                if (result.value) {
                  self.cancelarFactura(factura.id, {regenerar: true})
                } else if ( result.dismiss === Swal.DismissReason.cancel ) {
                  self.cancelarFactura(factura.id)
                }
              })
            }, 300)
          }
          else {
            self.cancelarFactura(factura.id)
          }
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
        }
      })
    },

    palancaTodasFacturas: function () {
      var self = this;

      // Selección o deselección de la bandera "a_pagar"
      // de cada factura
      this.facturas.forEach(function (factura) {
        factura.a_pagar = self.todasFacturasSeleccionadas;
      });

      this.actualizarTotalACobrar();
    },

    validarFactura: function (
      index,
      id = null,
      id_contrato = null,
      inicio_periodo = null
    ) {
      var self = this,
        facturasSup = [];

      if (id_contrato == null || inicio_periodo == null) return false;

      facturasSup = self.facturas.filter((factura) => {
        return (
          factura.id_contrato == id_contrato &&
          factura.id != id &&
          factura.a_pagar != true &&
          factura.inicio_periodo < inicio_periodo
        );
      });

      if (facturasSup.length > 0) {
        self.facturas[index].a_pagar = false;
        return true;
      } else return false;
    },

    verTableroCliente: function (id) {
      var self = this;

      self.$router.push({ name: "tableroCliente", params: { id_cliente: id } });
    }
  }
}
</script>

<style scoped>
.saldoAFavor {
  font-size: 15px;
  font-weight: bold;
}
.saldoTotalFacturas {
  font-size: 25px;
  font-weight: bold;
}
</style>
