<template>
  <div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10">
        <h2>Inventario</h2>
        <ol class="breadcrumb">
          <li>
            <router-link :to="{ name: 'listadoConversionesArticulos' }">
              Conversiones
            </router-link>
          </li>
          <li>
            <strong>Listado</strong>
          </li>
        </ol>
      </div>
    </div>
    <div class="wrapper wrapper-content">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <filtrador
            v-on:dato-actualizado="
              dato = $event,
              refrescarSalidas()
            "
            title="Solo filtra por nombre de artículo, descripción, cantidad de salida, cantidad de salida, total"
            :datoini="dato"
            placeholder="Dato del artículo"
          ></filtrador>
        </div>
        

        <div class="col-lg-1 col-md-4">
          <div class="checkbox checkbox-info checkbox-circle">
            <input id="personalizado" type="checkbox" @change="checkedPersonalizado()">
            <label for="personalizado"><strong>Personalizado</strong></label>
          </div>
        </div>

        <div class="col-lg-4 col-md-8">
          <div class="input-group" v-show="personalizado == true">
            <input
              class="form-control"
              name="fecha_inicial"
              v-model="fecha_inicial"
            />
            <span class="input-group-addon">
              <i class="fa fa-calendar"></i>
            </span>
            <span class="input-group-addon" v-show="personalizado == true">
              al
            </span>
            <input
              class="form-control"
              name="fecha_final"
              v-model="fecha_final"
              v-show="personalizado == true"
            />
            <span class="input-group-addon" v-show="personalizado == true">
              <i class="fa fa-calendar"></i>
            </span>
            <div class="input-group-btn">
              <button class="btn btn-default"
                @click="refrescarSalidas()"
              >
                <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-3 text-right">
          <router-link class="btn btn-default"
            :to="{name: 'nuevaConversionArticulos'}">
            <i class="fa fa-plus"></i>
            Nueva conversión
          </router-link>
        </div>
      </div>

      <br>

      <div class="ibox">
        <div class="ibox-content" style="min-height: 475px;">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-hover table-nowrap align-middle">
                  <thead>
                    <tr>
                      <th style="width: 70px">No.</th>
                      <th style="width: 100px">Fecha</th>
                      <th style="width: 100px">Hora</th>
                      <th>Tipo</th>
                      <th style="width:100px;">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="salida in salidas" :key="`ent-${salida.id}`">
                      <td class="text-right">{{salida.id_salalm}}</td>
                      <td>{{formatoFecha(salida.created_at)}}</td>
                      <td>{{formatoHora(salida.created_at)}}</td>
                      <td>
                        <span class="label label-warning" v-show="salida.tipo == 1">
                          Salida sin solicitud de artículos
                        </span>
                        <span class="label label-info" v-show="salida.tipo == 3">
                          Salida por conversión de artículos
                        </span>
                        <span class="label label-primary" v-show="salida.tipo == 4">
                          Salida por solicitud de artículos
                        </span>
                      </td>
                      <td>
                        <router-link
                          :to="{ name: 'visorSalidaAlmacen', params: { id: salida.id } }"
                          class="btn btn-default btn-xs"
                        >
                          <i class="fa fa-eye"></i>
                          Mostrar
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <paginador
          :pag="page"
          :totpag="totpag"
          :epp="epp"
          @cargar-pagina="cargarPagina"
          @epp-actualizado="
            epp = $event,
            refrescarSalidas()
          "
        ></paginador>
      </div>
    </div>
  </div>
</template>

<script>
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import SistemaSrv from '@/services/SistemaSrv.js'
import SalidaSrv from '@/services/inventario/SalidaSrv.js'
import moment from 'moment'

export default {
  components: {
    Paginador,
    Filtrador,
  },
  data: function() {
    return {
      salidas: [],

      dato: '',
      //datos de paguinacion
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoConvercionesArticulos.por_pagina') || 10),
      tc: 0, // Total de Movimientos
      totpag: 0, // Total de páginas
      
      personalizado: false,

      fecha_inicial: '',
      fecha_final: ''
    }
  },
  created(){
    var self = this

    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp) self.epp = parseInt(self.$route.query.epp)

    //Carga de la fecha inicial del sistema
    if (self.fecha_inicial == '') {
      SistemaSrv.fecha({formato: 'd/m/Y'}).then(response => {
        self.fecha_inicial = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    }

    //Carga de la fecha inicial del sistema
    if (self.fecha_final == '') {
      SistemaSrv.fecha({formato: 'd/m/Y'}).then(response => {
        self.fecha_final = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    }

    // Datepicker
    $(document).ready(function() {
      $('input[name="fecha_inicial"]').datepicker({
        dateFormat: 'dd/mm/yy',
        onSelect: function(fecha) {
          self.fecha_inicial = fecha
        }
      })

      $('input[name="fecha_final"]').datepicker({
        dateFormat: 'dd/mm/yy',
        onSelect: function(fecha) {
          self.fecha_final = fecha
        }
      })
    })
    
    self.refrescarSalidas()
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarSalidas({ page: n })
    },

    checkedPersonalizado: function() {
      var self = this

      if(self.personalizado != true) self.personalizado = true
      else self.personalizado = false
      if (self.personalizado == true) {
        document.getElementById("personalizado").checked = true
      }else{
        document.getElementById("personalizado").checked = false
      }

      if(self.personalizado == true){
        document.getElementById("dia").checked = false
        self.dia = false
      }
      self.refrescarSalidas()
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },

    formatoFechaBD(fecha) {
      return moment(fecha, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    formatoHora(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
    },
    
    refrescarSalidas: function() {
      var self = this

      this.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp
        }
      }).catch(error => {})

      let params = {
        dato: self.dato,
        page: self.page,
        epp: self.epp,
        orden: 'desc'
      }

      if(self.personalizado){
        Object.assign(params,{
          fecha_inicial: self.formatoFechaBD(self.fecha_inicial),
          fecha_final: self.formatoFechaBD(self.fecha_final)
        })
      }

      SalidaSrv.salidasJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoConversionesArticulos.por_pagina')
        let page = response.data
        self.salidas = page.data
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarSalidas()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las salidas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>
