<template>
  <Layout tituloPagina="Tickets de soporte">
    <div class="row">
      <div class="col-md-2 col-2 mb-3">
        <button
          class="btn btn-secondary"
          @click="modalFiltros()"
        >
          <span class="d-none d-sm-block">
            <i class="fas fa-list-ul"></i>
            Filtros
          </span>
          <span class="d-block d-sm-none">
            <i class="fas fa-list-ul"></i>
          </span>
        </button>
      </div>
      <div class="col-md-8 col-8 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarTickets()
          "
          :datoini="dato"
          placeholder="Dato del ticket"
        >
          <!-- <filtros 
            v-on:filtros-actualizados="
              filtros.campos = $event,
              refrescarTickets()
            "
            :filtrosini="filtros.campos"
          /> -->
        </filtrador>
      </div>
      <div class="col-md-2 col-2 text-right mb-3">
        <router-link
          :to="{ name: 'nuevoTicketSoporte' }"
          class="btn btn-success"
        >
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-6">
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="numeroCliente"
            v-model="columnas.numero_cliente"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="numeroCliente">Número cliente</label>
        </div>
        &nbsp; 
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="contrato"
            v-model="columnas.contacto"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="contrato">Contacto</label>
        </div>
        &nbsp; 
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="fecha"
            v-model="columnas.fecha"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="fecha">Fecha</label>
        </div>
        &nbsp; 
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="tiempo_estimado"
            v-model="columnas.tiempo_estimado"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="tiempo_estimado">Tiempo estimado</label>
        </div>
      </div>
      <div class="col-md-6 text-right">
        <span class="badge bg-secondary bg-gradient font-size-12">
          <span class="d-block d-sm-none">
            {{ total_tickets }}
            pendientes
          </span>
          <span class="d-none d-sm-block">
            {{ total_tickets }}
            <span v-if="total_tickets > 1 || total_tickets == 0">
              Tickets
            </span>
            <span v-if="total_tickets == 1">
              Ticket
            </span>
            pendientes
          </span>
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          <span class="d-block d-sm-none">
            {{nTicketsEnPag}} en página
          </span>
          <span class="d-none d-sm-block">
            {{nTicketsEnPag}} Tickets en página
          </span>
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          <span class="d-block d-sm-none">
            {{nTicketsTotales}} en total
          </span>
          <span class="d-none d-sm-block">
            {{nTicketsTotales}} Tickets en total
          </span>
        </span>
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive" style="min-height: 475px;">
          <table class="table table-hover table-nowrap align-middle" style="min-width: 950px" id="tabla-soportes">
            <thead>
              <tr>
                <th class="text-right" style="width: 60px">#ID</th>
                <th style="width: 130px" v-show="columnas.numero_cliente">Numero cliente</th>
                <th style="width: 80px">Prioridad</th>
                <th style="width: 110px;" v-show="columnas.fecha">Fecha</th>
                <th style="width: 100px" v-show="columnas.tiempo_estimado">Tiempo estimado</th>
                <th>Nombre</th>
                <th style="width: 150px" v-show="columnas.contacto">Contacto</th>
                <th style="width: 100px">Estado</th>
                <th style="width: 100px">Sesiones</th>
                <th style="width: 100px" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ticket in tickets" :key="ticket.id">
                <td class="text-right fw-semibold">
                  <router-link
                    :to="{
                      name: 'edicionTicketSoporte',
                      params: { id: ticket.id }
                    }"
                  >
                    {{ ticket.id }}
                  </router-link>
                </td>
                <td v-show="columnas.numero_cliente">
                  {{ ticket.numero_cliente }}
                </td>
                <td>
                  <span
                    class="badge font-size-13 badge-pill badge-soft-danger"
                    v-show="ticket.prioridad == 1"
                  >
                    URGENTE
                  </span>
                  <span
                    class="badge font-size-13 badge-pill badge-soft-warning"
                    v-show="ticket.prioridad == 2"
                  >
                    Media
                  </span>
                  <span
                    class="badge font-size-13 badge-pill badge-soft-success"
                    v-show="ticket.prioridad == 3"
                  >
                    Normal
                  </span>
                </td>
                <td v-show="columnas.fecha">
                  {{ formatoDate(ticket.fecha_programada, 'DD/MM/YYYY - hh:mm A') }}
                </td>
                <td v-show="columnas.tiempo_estimado">
                  {{ convertidorSegundos_a_DHM(ticket.tiempo_estimado) }}
                </td>
                <td class="fw-semibold">
                  <router-link
                    :to="{
                      name: 'tableroCliente',
                      params: { id_cliente: ticket.id_cliente }
                    }"
                    v-if="ticket.id_cliente != null"
                  >
                    {{ ticket.nombre }}
                  </router-link>

                  <span v-if="ticket.id_cliente == null">
                    {{ ticket.nombre }}
                  </span>
                </td>
                <td v-show="columnas.contacto">
                  <button class="btn btn-light btn-outline-success bg-gradient btn-sm"
                    @click="mostrarMdlWhatsapp(ticket.telefonos, ticket.nombre)">
                    <i class="mdi mdi-whatsapp"></i>
                    Whatsapp
                  </button>
                  <button
                    class="btn btn-light btn-sm btn-outline-secondary bg-gradient"
                    name="vcard"
                    title="vCard del cliente"
                    @click="descargarVCard(ticket.id_cliente)"
                    v-show="ticket.id_cliente != null"
                  >
                    <i class="mdi mdi-newspaper-variant-outline"></i>
                  </button>
                </td>
                <td>
                  <span
                    class="badge font-size-12 bg-warning"
                    v-if="!ticket.completado && !ticket.cancelado"
                  >
                    Activo
                  </span>
                  <span
                    class="badge font-size-12 bg-primary"
                    v-if="ticket.completado && !ticket.cancelado"
                  >
                    Completado
                  </span>
                  <span
                    class="badge font-size-12 bg-danger"
                    v-if="ticket.cancelado"
                  >
                    Cancelado
                  </span>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'sesionesTicketSoporte',
                      params: { id: ticket.id }
                    }"
                    class="btn btn-sm btn-light"
                  >
                    <i class="mdi mdi-account-clock-outline"></i>
                    Sesión de soporte
                  </router-link>
                </td>
                <td class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li v-show="ticket.mt_inicio == null && !ticket.completado">
                        <button class="dropdown-item success" @click="iniciarSoporte(ticket.id)">
                          <i class="mdi mdi-play-outline font-size-15 text-success"></i>
                          Iniciar soporte
                        </button>
                      </li>
                      <li v-show="ticket.mt_inicio != null && ticket.mt_cierre == null && !ticket.completado">
                        <button
                          class="dropdown-item primary"
                          @click="!ticket.bpausa ? pausarSoporte(ticket) : reanudadSoporte(ticket.id)"
                        >
                          <i 
                            class="mdi font-size-15 text-primary"
                            :class="!ticket.bpausa ? 'mdi-pause' : 'mdi-play-outline'"
                          ></i>
                          {{ !ticket.bpausa ? 'Pausar' : 'Reanudad' }} soporte
                        </button>
                      </li>
                      <li v-show="ticket.mt_inicio != null && ticket.mt_cierre == null && !ticket.completado">
                        <button class="dropdown-item dark" @click="finalizarSoporte(ticket.id)">
                          <i class="mdi mdi-stop font-size-15 text-dark"></i>
                          finalizar soporte
                        </button>
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'edicionTicketSoporte',
                            params: { id: ticket.id }
                          }"
                          class="dropdown-item"
                          :class="{
                            'warning': !ticket.completado,
                            'info': ticket.completado,
                          }"
                        >
                          <i 
                            class="mdi font-size-15"
                            :class="{
                              'mdi-square-edit-outline text-warning': !ticket.completado,
                              'mdi-eye text-info': ticket.completado
                            }"
                          ></i>
                          {{ !ticket.completado ? 'Editar' : 'Visor' }}
                        </router-link>
                      </li>
                      <li v-show="!ticket.completado">
                        <button
                          class="dropdown-item danger"
                          @click="preguntaCancelarTicket(ticket)"
                        >
                          <i class="mdi mdi-cancel font-size-15 text-danger"></i>
                          Cancelar
                        </button>
                      </li>
                      <li v-show="!ticket.completado || ticket.cancelado">
                        <button
                          @click="preguntaEliminarTicket(ticket.id, ticket.nombre)"
                          class="dropdown-item danger"
                        >
                          <i class="mdi mdi-trash-can-outline font-size-15 text-danger"></i>
                          Eliminar
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarTickets()
        "
      ></paginador>
    </div>
    <mdl-whatsapp ref="mdlWhatsapp"/>
    <filtros 
      ref="filtros"
      v-on:filtros-actualizados="
        filtros = $event,
        refrescarTickets()
      "
    /> 
    <br>
  </Layout>
</template>

<script>
/**
 * Listado de todos los tickets de soporte
 */
import Paginador from '@/components/Paginador.vue'
import Filtros from "./CmpMdlFiltros.vue"
import Filtrador from '@/components/Filtrador.vue'
import TicketSoporteSrv from '@/services/TicketSoporteSrv.js'
import SesionTicketSoporteSrv from '@/services/SesionTicketSoporteSrv.js'
import MdlWhatsapp from '@/views/clientes/MdlWhatsapp.vue'
import API from '@/API.js'

import Layout from '@/views/layouts/main'
import Swal from 'sweetalert2'
import moment from 'moment'
export default {
  name: 'ListadoTicketsSoporte',
  components: {
    Layout,
    Paginador,
    Filtrador,
    MdlWhatsapp,
    Filtros
  },
  data() {
    return {
      tk: localStorage.getItem('argusblack.token'),
      API: API,
      baseUrl: window.location.origin,
      dato: '',
      por_pagina: parseInt(localStorage.getItem('argusblack.listadoTicketsSoporte.por_pagina') || 10),
      pagina: 1,
      tickets: [],
      total_tickets: 0,
      ultima_pagina: 1,
      ticket: {
        id: null,
        id_cliente: null,
        nombre: ''
      },
      
      nTicketsEnPag:0,
      nTicketsTotales:0,

      columnas: {
        numero_cliente: true,
        fecha: true,
        tiempo_estimado: true,
        contacto: false
      },
      
      filtros: {
        prioridad: null,
        tod_act_comp: 'activos',
        solo_nuevos: false,
        campos: [
          {
            activo: true,
            nombre: 'ID',
            columna: 'id'
          },
          {
            activo: true,
            nombre: 'ID Cliente',
            columna: 'id_cliente'
          },
          {
            activo: true,
            nombre: 'Nombre',
            columna: 'nombre'
          },
          {
            activo: false,
            nombre: 'Nota soporte',
            columna: 'nota_soporte'
          },
          {
            activo: false,
            nombre: 'Nota cliente',
            columna: 'nota_cliente'
          },
          {
            activo: true,
            nombre: 'Numero de cliente',
            columna: 'numero_cliente'
          },
          {
            activo: true,
            nombre: 'Correo',
            columna: 'correo'
          },
          {
            activo: false,
            nombre: 'Fecha',
            columna: 'created_at'
          }
        ]
      }
    }
  },

  created: function() {
    var self = this
    self.ticket.id = this.$route.params.id

    if (self.$route.query.pagina) self.pagina = parseInt(self.$route.query.pagina)
    if (self.$route.query.por_pagina) self.por_pagina = parseInt(self.$route.query.por_pagina)
    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.tod_act_comp) self.filtros.tod_act_comp = self.$route.query.tod_act_comp
    if (self.$route.query.solo_nuevos) self.filtros.solo_nuevos = self.$route.query.solo_nuevos == 'true' ? true:false
    if (self.$route.query.prioridad) self.filtros.prioridad= parseInt(self.$route.query.prioridad)
    
    if (self.$route.query.columnas) {
      let columnas = self.$route.query.columnas
      if(typeof columnas == 'string') {
        let columnasNuevas = []
        columnasNuevas.push(columnas)
        columnas = columnasNuevas
      }
      self.filtros.campos.forEach(campo => {
        let col = columnas.find(c=>c==campo.columna)
        if(col) campo.activo = true
        else campo.activo = false
      })
    }

    // Ejecución de instrucciones
    self.inicio()
    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()
  },

  methods: {
    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.listadoTicketsSoporte.columnas', JSON.stringify(self.columnas))
    },
    cargarPagina: function(nPag) {
      var self = this

      self.pagina = nPag
      self.refrescarTickets()
    },
    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.listadoTicketsSoporte.columnas')
      
      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return dias+' dia '+horas+' hrs '+minutos+' min'
    },

    descargarVCard: function(idCliente) {
      window.open(`${API}/clientes/${idCliente}.vcf?_tk=${this.tk}`,'_blank')
    },

    formatoDate(mt, formato = 'DD/MM/YYYY') {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format(formato)
    },
    iniciarSoporte: function(idTicket = null) {
      var self = this

      SesionTicketSoporteSrv.iniciar(idTicket).then(response => {
        iu.msg.success('Se inició correctamente la sesión de soporte')
        self.refrescarTickets()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo iniciar la sesión de soporte '+idTicket
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    pausarSoporte: function(ticket) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Deseas pausar el ticket de soporte?",
        html: `¿Está seguro de pausar el ticket de soporte <strong>${ticket.id}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.motivoPausa(ticket)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    motivoPausa: function(ticket){
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Motivo de pausa del ticket de soporte",
        html: "Escriba el motivo de pausa del ticket de soporte:",
        icon: "question",
        input: "textarea",
        inputPlaceholder: "Escribe un motivo para pausar el soporte.",
        showCancelButton: true,
        confirmButtonText: "Pausar",
        cancelButtonText: "Cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a",
        allowOutsideClick: false
      }).then(text => {
        if (text.value) {
          let sesion = { motivo_pausa: text.value }

          SesionTicketSoporteSrv.pausar(ticket.id, sesion).then(response => {
            swal.fire("Pausado!", `La sesión de soporte se ha pausado correctamente, con motivo: <br><br> ${text.value}`, "success");
            self.refrescarTickets()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo pausar el ticket de soporte '+ticket.id
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
          })
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    reanudadSoporte: function(idTicket = null) {
      var self = this

      SesionTicketSoporteSrv.reanudar(idTicket).then(response => {
        iu.msg.success('Se a reanudado correctamente la sesión de soporte')
        self.refrescarTickets()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo reanudar la sesión de soporte '+idTicket
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    finalizarSoporte: function(idTicket = null) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Deseas finalizar el ticket de soporte?",
        html: `¿Está seguro de finalizar el ticket de soporte <strong>${idTicket}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          SesionTicketSoporteSrv.finalizar(idTicket).then(response => {
            swal.fire("Finalizado!", "La sesión de soporte se ha finalizado correctamente", "success");
            self.refrescarTickets()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo finalizar el ticket de soporte '+idTicket
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    inicio: function() {
      var self = this

      this.refrescarTickets()
    },

    preguntaCancelarTicket: function(ticket) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: 'Cancelar ticket de soporte',
        html: '¿Desea cancelar el ticket de soporte del cliente <br> <strong>' + ticket.nombre + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          TicketSoporteSrv.cancelar(ticket.id).then(response=>{
            swal.fire("Cancelado!", "Se a cancelado correctamente", "success")
            self.refrescarTickets()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cancelar'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    preguntaEliminarTicket: function(idTicket, nombre) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: 'Eliminación de ticket de soporte',
        html: '¿Desea eliminar el ticket del cliente <br> <strong>' + nombre + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          TicketSoporteSrv.eliminar(idTicket).then(response=>{
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarTickets()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    mostrarMdlWhatsapp(telefonos, nombre) {
      let self = this
      self.$refs.mdlWhatsapp.mostrar(telefonos, nombre)
    },
    modalFiltros() {
      let self = this
      self.$refs.filtros.mostrar(self.filtros)
    },
    refrescarTickets: function() {
      var self = this

      iu.spinner.mostrar('#tabla-soportes')

      var columnas = []
      self.filtros.campos.forEach(filtro => {
        if(filtro.activo) columnas.push(filtro.columna)
      })
      // Actualización de los querys
      this.$router.replace({
        query: {
          pagina: this.pagina,
          por_pagina: this.por_pagina,
          dato: this.dato,
          tod_act_comp: this.filtros.tod_act_comp,
          solo_nuevos: this.filtros.solo_nuevos,
          prioridad: self.filtros.prioridad,
          columnas: columnas
        }
      }).catch(error => {})

      var params = {
        dato: self.dato,
        tod_act_comp: self.filtros.tod_act_comp,
        pagina: self.pagina,
        por_pagina: self.por_pagina,
        solo_nuevos: self.filtros.solo_nuevos,
        prioridad:self.filtros.prioridad,
        columnas: columnas,
        cfg_ticket: {
          id: true,
          id_cliente: true,
          nombre: true,
          telefono: true,
          completado: true,
          prioridad: true,
          created_at: true
        }
      }

      TicketSoporteSrv.ticketsJSON(params).then(response=>{
        localStorage.setItem('argusblack.listadoTicketsSoporte.por_pagina', self.por_pagina)
        var res = response.data
        // Formateo de la fecha y hora de creación del ticket
        res.tickets.forEach(t => {
          var tmp = t.created_at.split(' ')
          t.fecha = tmp[0]
          t.hora = tmp[1]
        })
        // Carga de los tickets
        self.tickets = res.tickets
        self.ultima_pagina = res.ultima_pagina
        self.nTicketsEnPag = res.nTicketsEnPag
        self.nTicketsTotales = res.total

        if (self.pagina > self.ultima_pagina) {
          self.pagina = self.ultima_pagina
          self.refrescarTickets()
        }
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pidieron cargar los tickets'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-soportes')
      })

      self.refrescarTotalTickets()
    },
    refrescarTotalTickets: function() {
      var self = this
      TicketSoporteSrv.totalTicketsActivos().then(response=>{
        self.total_tickets = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }
  },
  mounted() {
    iu.spinner.mostrar('#tabla-soportes')
  }
}
</script>

<style scoped>
</style>