<template>
  <Layout :tituloPagina="`Sistema | Roles de usuarios | Rol ${ rol.nombre } | Bloque ${bloque.nombre} | Edición de permisos`">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          <span class="d-none d-sm-block">
            Edición de permisos para el rol {{ rol.nombre }}
          </span>
          <span class="d-block d-sm-none">
            Permisos del rol {{ rol.nombre }}
          </span>
        </h4>
        <div>
          <a 
            href="#"
            onclick="return false;"
            @click="$router.go(-1)"
            v-if="rol.id != null"
          >
            <i class="mdi mdi-close text-dark"></i>
          </a>
        </div>
      </div>
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="table table-hover table-striped table-nowrap align-middle" id="tabla-permisos">
            <thead>
              <tr>
                <th style="width: 40px">Hijas</th>
                <th style="width: 70px">Permiso</th>
                <th style="width: 70px">Tipo</th>
                <th style="width: 300px">Nombre de función</th>
                <th>Descripción de la función</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(permiso, index) in permisos">
                <tr :key="`fm_${permiso.id_funcion}`" v-if="true">
                  <td>
                    <button
                      class="btn btn-sm"
                      :class="permiso.funciones_hijas ? 'btn-info' : 'btn-soft-dark'"
                      @click="permiso.funciones_hijas = !permiso.funciones_hijas"
                    >
                      <i class="mdi" :class="!permiso.funciones_hijas ? 'mdi-chevron-down': 'mdi-chevron-up'"></i>
                    </button>
                  </td>
                  <td>
                    <div class="form-check form-switch form-switch-md">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="'switch-' + index"
                        :disabled="rol.generico"
                        @change="
                          copiarPermisos(),
                          actualizarPermiso(permiso, index, $event)
                        "
                        :checked="permiso.permiso == true"
                      />
                      <label
                        class="form-check-label"
                        :for="'switch-' + index"
                      ></label>
                    </div>
                  </td>
                  <td>
                    <span
                      class="badge bg-gradient font-size-12 text-capitalize"
                      :class="{ 
                        'bg-success': permiso.tipo == 'accion',
                        'bg-info'   : permiso.tipo == 'vista',
                        'bg-warning': permiso.tipo == 'grupo',
                        'bg-primary': permiso.tipo == 'componente'
                      }"
                    >
                      {{ permiso.tipo }}
                    </span>
                  </td>
                  <td class="fw-semibold">
                    {{ permiso.nombre }}
                  </td>
                  <td>
                    {{ permiso.descripcion }}
                  </td>
                </tr>
                <tr :key="`fh_${permiso.id_funcion}`" v-if="permiso.funciones_hijas">
                  <td colspan="5">
                    <permisos-hijas
                      :idRol="rol.id"
                      :idBloque="idBloque"
                      :idFuncionMadre="permiso.id_funcion"
                      :permisoMadre="permiso.permiso"
                    ></permisos-hijas>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-right pb-4">
      <button class="btn btn-secondary" @click="$router.go(-1)">
        <i class="mdi mdi-chevron-left"></i>
        Atrás
      </button>
    </div>
    <br>
  </Layout>
</template>

<script>
import PermisoSrv from '@/services/PermisoSrv.js'
import RolUsuariosSrv from '@/services/RolUsuariosSrv.js'
import BloqueFuncionesSrv from '@/services/BloqueFuncionesSrv.js'
import PermisosHijas from './PermisosHijas.vue'
import Layout from "@/views/layouts/main"
export default {
  name: 'Permisos',
  components: { Layout, PermisosHijas },
  data() {
    return {
      bloque: { id: null, nombre: '' },
      indexVisible: null,
      permisos: [],
      permisosTem: [],
      rol: { id: null, nombre: '' },
      bandera_hijas: false,
    }
  },

  computed: {
    idBloque() {
      let id = this.$route.query.id_bloque 

      if (id == undefined) id = null
      return id
    },

    idFuncionMadre() {
      let id = this.$route.query.id_funcion_madre

      if (id == undefined) id = null
      return id
    },
  },

  watch: {
    idFuncionMadre(newVal, oldVal) {
      if(newVal == oldVal) return 

      this.refrescarPermisos()
    },

    bandera_hijas: function(newBandera, oldBandera){
      var self = this

      if(self.permisos.length == 0) return

      self.permisos.forEach(permiso => {
        var permisoTem = self.permisosTem.filter(permiso_tem => permiso_tem.id_funcion == permiso.id_funcion)
        
        if(permisoTem.length == 0) return

        permiso.funciones_hijas = permisoTem[0].funciones_hijas
      })
      self.bandera_hijas = false
    }
  },

  created() {
    var self = this

    // Carga del rol
    self.rol.id = self.$route.params.id_rol
    self.bloque.id = self.$route.query.id_bloque
    
    if (self.rol.id == null || self.bloque.id == null) self.$router.go(-1)

    // Carga de los permisos del rol
    self.cargarRol()
    self.cargarBloque()
    self.refrescarPermisos()
  },

  methods: {
    actualizarPermiso(permiso, index, event) {
      var self = this

      // Si la palanca cambió a true
      if (event.target.checked == true) {
        let permisoAct = {
          id_rol: self.rol.id,
          id_funcion: permiso.id_funcion
        }

        PermisoSrv.agregar(permisoAct).then(response => {
          self.refrescarPermisos()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo actualizar el permiso'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      // Si la palanca cambió a false
      } else {
        PermisoSrv.quitar(permiso.id_rol, permiso.id_funcion).then(response => {
          self.refrescarPermisos()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo quitar el permiso'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      }
    },

    cargarBloque() {
      var self = this

      BloqueFuncionesSrv.bloqueJSON(self.bloque.id).then(response => {
        self.bloque = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se podo cargar el bloque'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarRol() {
      var self = this

      RolUsuariosSrv.rolJSON(self.rol.id).then(response => {
        self.rol = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el rol'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    copiarPermisos() {
      var self = this
      self.permisosTem = self.permisos
    },

    refrescarPermisos() {
      var self = this
      //self.permisos = [];

      iu.spinner.mostrar('#tabla-permisos')

      PermisoSrv.permisosJSON({
        id_rol: self.rol.id,
        id_bloque: self.idBloque,
        id_funcion_madre: self.idFuncionMadre
      }).then(response => {
        var permisos = response.data

        permisos.forEach(permiso => {
          permiso.funciones_hijas = false
        });
        self.permisos = permisos
        self.bandera_hijas = true
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los permisos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-permisos')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-permisos')
  }
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>