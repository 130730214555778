<template>
  <Layout tituloPagina="Session de ticket de soporte">
    <div class="row">
      <div class="col-lg-10">
        <div class="card">
          <div class="card-body" style="min-height: 475px;">
            <div class="table-responsive" style="min-height: 475px;">
              <table class="table table-hover table-nowrap align-middle" id="tabla-sesion-soporte">
                <thead>
                  <tr>
                    <th class="text-right" style="width: 60px">#ID</th>
                    <th style="width: 150px">Inicio</th>
                    <th style="width: 150px">Fin</th>
                    <th style="width: 150px;">Tiempo</th>
                    <th>Nota</th>
                    <th style="width: 100px" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sesion, index) in sesiones" :key="sesion.if">
                    <td class="text-right fw-semibold">
                      {{ sesion.id }}
                    </td>
                    <td>
                      {{ index_editar != index && sesion.inicio ? formatoDate(sesion.inicio, 'DD/MM/YYYY - hh:mm A') : '' }}
                      <input
                        type="datetime-local"
                        class="form-control"
                        v-show="index_editar == index && sesion.inicio"
                        v-model="inicio_editar"
                        @change="actualizar()"
                      />
                    </td>
                    <td>
                      {{ index_editar != index && sesion.fin ? formatoDate(sesion.fin, 'DD/MM/YYYY - hh:mm A') : '' }}
                      <input
                        type="datetime-local"
                        class="form-control"
                        v-show="index_editar == index && sesion.fin"
                        v-model="fin_editar"
                        @change="actualizar()"
                      />
                    </td>
                    <td>
                      {{ convertidor_DHM(sesion.inicio, sesion.fin) }}
                    </td>
                    <td>
                      {{ index_editar != index && sesion.nota ? sesion.nota : '' }}
                      <textarea 
                        v-model="nota_editar"
                        v-show="index_editar == index"
                        class="form-control"
                        @change="actualizar()"
                      ></textarea>
                    </td>
                    <td>
                      <button class="btn btn-warning btn-sm" @click="editar(index, sesion)" v-show="index_editar != index">
                        <i class="mdi mdi-square-edit-outline"></i>
                        Editar
                      </button>
                      <button
                        class="btn btn-success btn-sm"
                        @click="actualizar()"
                        v-show="index_editar == index"
                      >
                        <i class="mdi mdi-content-save"></i>
                        Actualizar
                      </button>
                      <br v-show="index_editar == index">
                      <button
                        class="btn btn-info btn-sm"
                        @click="limpiarSesionEditar()"
                        v-show="index_editar == index"
                      >
                        <i class="mdi mdi-cancel"></i>
                        No editar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-md-4 offset-md-8 text-right">
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="mdi mdi-clock-outline"></i>
                    &nbsp;
                    Tiempo total
                  </span>
                  <input 
                    type="text"
                    class="form-control text-right"
                    v-model="tiempo_total"
                    readonly
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div class="text-right">
          <div class="btn-group">
            <a
              href="#"
              onclick="return false"
              @click="atras()"
              class="btn btn-secondary"
            >
            <i class="mdi mdi-chevron-left"></i>
              Atras
            </a>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import SesionTicketSoporteSrv from '@/services/SesionTicketSoporteSrv.js'
import moment from 'moment'

export default {
  name: 'SesionesTicketSoporte',
  components: { Layout },
  data() {
    return {
      id_ticket: null,
      sesiones: [],
      index_editar: -1,
      id_sesion_editar: null,
      inicio_editar: null,
      fin_editar: null,
      nota_editar: ''
    }
  },

  created: function() {
    var self = this

    self.id_ticket = self.$route.params.id

    self.refrescarSesionesSoporte()
  },

  computed: {
    tiempo_total: function() {
      let self = this, segundos = 0

      self.sesiones.forEach(sesion => {
        if(sesion.fin != null ) {
          let finicial = moment(sesion.inicio)
          let ffinal = moment(sesion.fin)
          let dsegundos = ffinal.diff(finicial, 'seconds')

          segundos += dsegundos
        }
      })

      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)

      return dias+' dia '+horas+' hrs '+minutos+' min'
    }
  },

  methods: {
    actualizar(){
      var self = this, sesion = {
        id: self.id_sesion_editar,
        id_ticket_soporte: self.id_ticket,
        nota: self.nota_editar,
        inicio: self.inicio_editar ? self.formatoDate(self.inicio_editar, 'YYYY-MM-DD HH:mm:ss') : null,
        fin: self.fin_editar ? self.formatoDate(self.fin_editar, 'YYYY-MM-DD HH:mm:ss') : null
      }

      SesionTicketSoporteSrv.actualizar(self.id_ticket, sesion).then(response => {
        iu.msg.success('Se actualizó correctamente la sesión de soporte')
        self.refrescarSesionesSoporte()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar la sesión de soporte '+self.id_ticket
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    limpiarSesionEditar() {
      var self = this

      self.index_editar = -1,
      self.id_sesion_editar = null,
      self.inicio_editar = null,
      self.fin_editar = null,
      self.nota_editar = ''
    },

    atras: function() {
      this.$router.go(-1)
    },

    convertidor_DHM(inicio, fin) {
      if(inicio == null || fin == null ) return '0 dia 0 hrs 0 min'

      let fecha_inicial = moment(inicio)
      let fecha_final = moment(fin)
      let segundos = fecha_final.diff(fecha_inicial,'seconds')

      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)

      return dias+' dia '+horas+' hrs '+minutos+' min'
    },

    formatoDate(mt, formato = 'DD/MM/YYYY') {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format(formato)
    },

    editar: function(index, sesion) {
      var self = this 

      self.id_sesion_editar = sesion.id
      self.inicio_editar = sesion.inicio
      self.fin_editar = sesion.fin
      self.nota_editar = sesion.nota

      self.index_editar = index
    },

    refrescarSesionesSoporte:function() {
      var self = this, params = {}

      SesionTicketSoporteSrv.sesionesJSON(self.id_ticket, params).then(response => {
        self.sesiones = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar las sesiones del soporte '+self.id_ticket
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>