<template>
  <div class="modal fade" ref="MdlIspRespaldo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Selección de ISP de respaldo
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cerrar()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="tblRouters table table-hover table-nowrap align-middle">
              <thead>
                <tr>
                  <th style="width: 50px">Id</th>
                  <th>Nombre</th>
                  <th>Carga / Descarga</th>
                  <th style="width: 130px" class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="isp in isps" :key="isp.id">
                  <td>{{ isp.id }}</td>
                  <td>{{ isp.nombre }}</td>
                  <td>
                    <span :class="{ 'text-danger': isp.carga_maxima == 0 }">
                      {{ isp.carga_maxima }}
                      {{ isp.unidad_carga == 'k' ? 'kbps' : '' }}
                      {{ isp.unidad_carga == 'M' ? 'Mbps' : '' }}
                    </span>
                    /
                    <span :class="{ 'text-danger': isp.descarga_maxima == 0 }">
                      {{ isp.descarga_maxima }}
                      {{ isp.unidad_descarga == 'k' ? 'kbps' : '' }}
                      {{ isp.unidad_descarga == 'M' ? 'Mbps' : '' }}
                    </span>
                  </td>
                  <td>
                    <button
                      class="btn btn-success btn-sm"
                      @click="agregar(isp)"
                    >
                      <i class="mdi mdi-check-bold"></i>
                      Seleccionar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="text-right">
            <button
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal"
              @click="cerrar()"
            >
              <i class="mdi mdi-close"></i>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
import IspRouterSrv from '@/services/IspRouterSrv.js'
export default {
  name: 'MdlIspRespaldo',

  props: {
    idsIspsRespaldos: {
      type: Array
    }
  },
  data() {
    return {
      id_isp: null,
      id_router: null,
      isps: []
    }
  },

  methods: {
    agregar: function(isp) {
      var self = this

      let respaldo = Object.assign(isp, {
        prioridad: 1,
      })
      
      self.$emit('isp:respaldo', respaldo)
      var modal = Modal.getInstance(self.$refs.MdlIspRespaldo)
      modal.hide()
    },

    cargarIsps () {
      var self = this

      IspRouterSrv.ispsJSON(self.id_router).then(response => {
        var data = response.data
        
        let isps = data.filter(function(isp) {
          let index = self.idsIspsRespaldos.findIndex(function (id) {
            return id == isp.id
          })
          if(index == -1)
            return isp.id != self.id_isp
        })
        self.isps = isps
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    cerrar: function() {
      var self = this
      var modal = Modal.getInstance(self.$refs.MdlIspRespaldo)
      modal.hide()
    },

    mostrar: function(idRouter, idIsp) {
      var self = this

      self.id_router = idRouter
      self.id_isp = idIsp

      self.cargarIsps()

      var modal = new Modal(self.$refs.MdlIspRespaldo)
      modal.show()
    }
  }
}
</script>