<template>
  <Layout :tituloPagina="$route.path.substr(0, 9) == '/facturas' ? `Facturas | Visor` : `Ventas | Factura rápida`">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Factura <small v-show="factura.generada_por_sistema" style="font-size: 8px">Generada por sistema</small>
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <router-link
                  :to="{
                    name: 'edicionFactura',
                    params: { id_factura: factura.id }
                  }"
                  class="dropdown-item"
                  v-if="!factura.cancelada && factura.saldo == factura.total"
                >
                  <i class="mdi mdi-content-save-edit text-warning"></i>
                  Editar
                </router-link>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-2">
            <label>Serie</label> <br />
            <div class="bg-light form-control text-center">
              {{ factura.ns }}
            </div>

            <div
              class="bg-warning text-center"
              v-show="!factura.pagada && factura.total == factura.saldo"
            >
              SIN PAGAR
            </div>

            <div
              class="bg-warning text-center"
              v-show="!factura.pagada && factura.total > factura.saldo"
            >
              PAGO PARCIAL
            </div>

            <div class="bg-success text-center" v-show="factura.pagada">
              <span v-show="factura.cancelada">
                FUE
              </span>
              PAGADA
            </div>

            <div class="bg-danger text-center" v-show="factura.cancelada">
              CANCELADA
            </div>
          </div>
          <div class="col-sm-3">
            <label>Fecha de registro</label>
            <div class="bg-light form-control text-center">
              {{ factura.fecha }}
            </div>
          </div>
          <div class="col-sm-3" v-show="factura.fecha_para_pago != null">
            <label>Fecha para pago</label>
            <div class="bg-light form-control text-center">
              {{ factura.fecha_para_pago }}
            </div>
          </div>
          <div class="col-sm-3" v-show="factura.fecha_vencimiento != null">
            <label>Fecha de vencimiento</label>
            <div class="bg-light form-control text-center">
              {{ factura.fecha_vencimiento }}
            </div>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-5">
            <div style="height: 96px">
              <label>Cliente</label>
              <div class="input-group">
                <div class="input-group-text" style="min-width: 60px">
                  {{ factura.cliente.id }}
                </div>
                <input
                  name="nombre"
                  class="form-control"
                  v-model="factura.cliente.nombre"
                  readonly
                />
              </div>
            </div>
            <div v-show="factura.prorroga">
              <h5>Promesa de pago</h5>
              <div class="input-group">
                <input type="text" :value="factura.fecha_vencimiento" 
                  class="form-control"
                  readonly
                />
                <button class="btn btn-outline-danger" @click="eliminarProrroga()" v-show="!factura.cancelada">
                  <i class="fa fa-trash"></i>
                  Cancelar promesa
                </button>
              </div>
              <h5>Motivo de promesa</h5>
              <textarea
                class="form-control"
                rows="5"
                v-model="factura.motivo_prorroga"
                readonly
              ></textarea>
            </div>
          </div>
          <div class="col-md-7">
            <label>Nota</label>
            <textarea
              name="nota"
              class="form-control"
              rows="5"
              v-model="factura.nota"
              readonly
            ></textarea>
            <br />
            <div class="row">
              <div class="col-md-4" v-show="factura.id_contrato">
                <h5>Contrato asignado</h5>
                <div class="input-group">
                  <input
                    v-model="factura.id_contrato"
                    class="form-control text-right"
                    readonly
                  />
                  <router-link
                    :to="{
                      name: 'edicionContrato',
                      params: {
                        id_cliente: factura.cliente.id,
                        id_contrato: factura.id_contrato
                      }
                    }"
                    class="btn btn-info"
                    v-if="
                      factura.cliente.id != null &&
                        factura.id_contrato != null
                    "
                  >
                    <i class="mdi mdi-eye"></i>
                  </router-link>
                  <button class="btn btn-info" @click="infoContrato(factura.id_contrato)" title="Información del contrato">
                    <i class="mdi mdi-information-outline"></i>
                  </button>
                </div>
              </div>

              <div
                class="col-md-8"
                v-if="
                  factura.inicio_periodo != null &&
                    factura.fin_periodo != null
                "
              >
                <h5>Periodo asignado</h5>

                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text">
                        Inicio
                      </span>
                      <input
                        class="form-control"
                        v-model="factura.inicio_periodo"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text">
                        Fin
                      </span>
                      <input
                        class="form-control"
                        v-model="factura.fin_periodo"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <!-- Partidas de la factura -->
        <div class="conTblPartidas table-responsive">
          <table class="tblPartidas table table-hover table-striped align-middle">
            <thead>
              <tr>
                <th style="width: 50px">#</th>
                <th style="min-width: 200px">Producto</th>
                <th style="width: 100px">Cantidad</th>
                <th style="width: 125px">P/U</th>
                <th style="width: 125px">S. Total</th>
                <th style="width: 125px">+Carg.</th>
                <th style="width: 125px">-Desc.</th>
                <th style="width: 125px">Total</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(partida, index) in factura.partidas">
                <tr v-if="true" :key="`partidas-${index+1}`" class="text-right">
                  <td>{{ index + 1 }}</td>
                  <td class="text-left">{{ partida.producto.nombre }}</td>
                  <td>{{ partida.cantidad }}</td>
                  <td>
                    {{ hrr.fmon(partida.precio, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  </td>
                  <td>
                    {{ hrr.fmon(partida.sub_total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  </td>
                  <td>
                    {{ hrr.fmon(partida.valor_total_cargos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  </td>
                  <td>
                    {{ hrr.fmon(partida.valor_total_descuentos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  </td>
                  <td>
                    {{ hrr.fmon(partida.total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  </td>
                </tr>

                <tr v-if="partida.nota != null && partida.nota != ''" :key="`partida-nota-${index+1}`">
                  <td></td>
                  <td class="text-right">
                    <strong>Nota de la partida</strong>
                  </td>
                </tr>

                <tr v-if="partida.nota != null && partida.nota != ''" :key="`partida-nota-2${index+1}`">
                  <td colspan="2"></td>
                  <td colspan="4">
                    <p>{{ partida.nota }}</p>
                  </td>
                </tr>

                <tr v-if="partida.cargos.length > 0" :key="`partida-cargos-${index+1}`">
                  <td></td>
                  <td class="text-right">
                    <strong>Cargos</strong>
                  </td>
                </tr>
                <tr v-for="cargo in partida.cargos" :key="cargo.id">
                  <td colspan="2"></td>
                  <td colspan="2">+ {{ cargo.concepto }}</td>
                  <td>{{ cargo.porcentaje > 0 ? cargo.porcentaje + '%' : '' }}</td>
                  <td class="text-right">
                    {{ hrr.fmon(cargo.valor, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  </td>
                </tr>

                <tr v-if="partida.descuentos.length > 0" :key="`partida-descuentos-${index+1}`">
                  <td></td>
                  <td class="text-right">
                    <strong>Descuentos</strong>
                  </td>
                </tr>
                <tr
                  v-for="descuento in partida.descuentos"
                  :key="descuento.id"
                >
                  <td colspan="2"></td>
                  <td colspan="2">- {{ descuento.concepto }}</td>
                  <td>{{ descuento.porcentaje > 0 ? descuento.porcentaje + '%' : '' }}</td>
                  <td></td>
                  <td class="text-right">
                    {{ hrr.fmon(descuento.valor, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <br />

        <div class="row">
          <!-- CARGOS -->
          <div class="col-md-6">
            <div v-if="factura.cargos.length > 0">
              <div class="row">
                <div class="col-9">
                  <label> Cargos globales</label> &nbsp;&nbsp;
                </div>
              </div>

              <div class="conTblCargos table-responsive">
                <table class="tblCargos table table-hover align-middle table-striped">
                  <thead>
                    <tr>
                      <th>Concepto</th>
                      <th style="width: 100px">%</th>
                      <th style="width: 125px">{{ monedaSistema.simbolo }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cargo in factura.cargos" :key="cargo.id">
                      <td>{{ cargo.concepto }}</td>
                      <td>{{ cargo.porcentaje > 0 ? cargo.porcentaje + ' %' : '' }}</td>
                      <td class="text-right">
                        {{ hrr.fmon(cargo.valor, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- DESCUENTOS -->
          <div class="col-md-6">
            <div v-if="factura.descuentos.length > 0">
              <div class="row">
                <div class="col-9">
                  <label>Descuentos globales</label> &nbsp;&nbsp;
                </div>
              </div>

              <div class="conTblDescuentos table-responsive">
                <table class="tblDescuentos table table-hover align-middle table-striped">
                  <thead>
                    <tr>
                      <th>Concepto</th>
                      <th style="width: 100px">%</th>
                      <th style="width: 125px">{{ monedaSistema.simbolo }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="descuento in factura.descuentos"
                      :key="descuento.id"
                    >
                      <td>{{ descuento.concepto }}</td>
                      <td>{{ descuento.porcentaje ? descuento.porcentaje + ' %' : '' }}</td>
                      <td class="text-right">
                        {{ hrr.fmon(descuento.valor, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="offset-md-8 col-md-4 text-right">
            <div class="row">
              <label class="col-sm-6">Sub total</label>
              <div class="col-sm-6">
                {{ hrr.fmon(factura.sub_total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
              </div>
            </div>
            <div class="row">
              <label class="col-sm-6">+Cargos</label>
              <div class="col-sm-6">
                {{ hrr.fmon(factura.valor_total_cargos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
              </div>
            </div>
            <div class="row">
              <label class="col-sm-6">-Descuentos</label>
              <div class="col-sm-6">
                {{ hrr.fmon(factura.valor_total_descuentos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
              </div>
            </div>
            <div class="row" v-show="factura.iva > 0">
              <label class="col-sm-6">Impuestos</label>
              <div class="col-sm-6">
                {{ hrr.fmon(factura.iva, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
              </div>
            </div>
            <div class="row">
              <label class="col-sm-6">Total</label>
              <div class="col-sm-6">
                {{ hrr.fmon(factura.total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-7">
        <router-link
          :to="{ name: 'abonosFactura', params: { id_factura: factura.id } }"
          v-if="factura.saldo < factura.total"
          v-show="!ajustes.factura_abonosenvisor"
          class="btn btn-secondary"
        >
          <i class="mdi mdi-format-list-bulleted"></i>
          Abonos aplicados
        </router-link>

        <CmpAbonosFactura 
          v-if="factura.saldo < factura.total"
          v-show="ajustes.factura_abonosenvisor"
          :idFactura="$route.params.id"
        ></CmpAbonosFactura>
      </div>
      <div class="col-md-5 text-right">
        <div class="btn-group">
          <button @click="atras()" class="btn btn-secondary">
            <i class="mdi mdi-chevron-left"></i>
            Atras
          </button>
          <router-link
            :to="{
              name: 'facturasPendientesPago',
              params: { id_cliente: factura.cliente.id }
            }"
            class="btn btn-success"
            v-if="factura.pagada == false && factura.cancelada == false"
          >
            <span class="d-none d-sm-block">
              <i class="mdi mdi-cash"></i>
              Cobrar
            </span>
            <span class="d-block d-sm-none">
              <i class="mdi mdi-cash"></i>
            </span>
          </router-link>
          <router-link
            :to="{
              name: 'edicionFactura',
              params: { id_factura: factura.id }
            }"
            v-if="!factura.cancelada && factura.saldo == factura.total"
            class="btn btn-warning"
            replace
          >
            <span class="d-none d-sm-block">
              <i class="mdi mdi-content-save-edit"></i>
              Editar
            </span>
            <span class="d-block d-sm-none">
              <i class="mdi mdi-content-save-edit"></i>
            </span>
          </router-link>
          <button class="btn btn-info" @click="imprimirFactura()" v-if="factura.id != null">
            <span class="d-none d-sm-block">
              <i class="mdi mdi-printer"></i>
              Imprimir
            </span>
            <span class="d-block d-sm-none">
              <i class="mdi mdi-printer"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
    <br>

    <mdl-info-contrato ref="mdlInfoCrt"></mdl-info-contrato>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'

import API from '@/API.js'
import FacturaSrv from '@/services/FacturaSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import MdlInfoContrato from './EdicionFactura/MdlInfoContrato.vue'
import UsuarioSrv from '@/services/UsuarioSrv.js'
import CmpAbonosFactura from "./CmpAbonosFactura.vue"
import Swal from 'sweetalert2'
import moment from 'moment'
import md5 from 'md5'

export default {
  name: 'VistaFactura',
  components: { Layout, MdlInfoContrato, CmpAbonosFactura },
  data() {
    return {
      hrr: iu.hrr, // Objeto Herramienta
      API: API,
      token: localStorage.getItem('argusblack.token'),
      factura: {
        id: null,
        serie: {
          prefijo: ''
        },
        cliente: {
          id: null,
          nombre: ''
        },
        periodo: null,
        partidas: [],
        cargos: [],
        descuentos: [],
        fecha: '',
        fecha_para_pago: null,
        fecha_vencimiento: null,
        nota: '',
        sub_total: 0,
        valor_total_cargos: 0,
        valor_total_descuentos: 0,
        iva: 0,
        total: 0,
        prorroga: 0,
        motivo_prorroga: null
      },
      piva: 0, // Porcentaje de IVA,
      ajustes: {
        factura_abonosenvisor: false
      }
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this

    // Cargas iniciales
    self.cargarAjustesUsuario()
    self.cargarFactura()
    self.cargarPorcentajeIva()
  },

  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    cargarAjustesUsuario() {
      var self = this
      UsuarioSrv.ajustesJSON().then(response => {
        let ajustes = response.data
        self.ajustes.factura_abonosenvisor = ajustes.factura_abonosenvisor
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarFactura() {
      var idFactura = this.$route.params.id
      var self = this

      FacturaSrv.facturaJSON(idFactura).then(response => {
        let factura = response.data
        
        factura.fin_prorroga = moment(factura.fin_prorroga, 'YYYY-MM-DD').format('DD/MM/YYYY')

        Object.assign(self.factura, factura)
        Object.assign(self.factura, {
          fecha: moment(factura.fecha, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          fecha_para_pago: moment(factura.fecha_para_pago, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          fecha_para_pago_contrato: moment(factura.fecha_para_pago_contrato, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          fecha_vencimiento: factura.fecha_vencimiento ? moment(factura.fecha_vencimiento, 'YYYY-MM-DD').format('DD/MM/YYYY'): null,
          inicio_periodo: factura.inicio_periodo ? moment(factura.inicio_periodo, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
          fin_periodo: factura.fin_periodo ? moment(factura.fin_periodo, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarPorcentajeIva: function() {
      var self = this

      SistemaSrv.valorAjuste('iva').then(response => {
        self.piva = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    eliminarProrroga() {
      let self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Cancelación de promesa de pago",
        html: '¿Deseas eliminar la promesa de pago?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FacturaSrv.eliminarProrroga(self.factura.id).then(response => {
            swal.fire("¡Cancelación de factura!", 'Promesa de pago cancelada', "success")
            self.factura.prorroga = 0
            self.factura.motivo_prorroga = ''

            FacturaSrv.fechaVencimiento(self.factura.id).then(response => {
              let fechaVencimiento = moment(response.data, 'YYYY-MM-DD').format('DD/MM/YYYY')
              self.factura.fecha_vencimiento = fechaVencimiento
            })
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
        }
      })
    },

    imprimirFactura() {
      var self = this,
        url = API+"/facturas/"+self.factura.id+"/impresion/"+md5(self.factura.id.toString())
      window.open(url,'_blank')
    },

    infoContrato:function(idContrato){
      if(idContrato != null && idContrato != 0) this.$refs.mdlInfoCrt.mostrar(idContrato)
      else iu.msg.warning('No se ha seleccionado ningún contrato')
    }
  }
}
</script>