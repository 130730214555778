<template>
  <Layout :tituloPagina="'Network | Sitios'">
    <div class="row mb-4">
      <div class="offset-md-2 col-md-8 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarSitios()
          "
          :datoini="dato"
          placeholder="Dato del sitio"
        ></filtrador>
      </div>
      <div class="col-md-2 col-3 text-right">
        <router-link class="btn btn-success" :to="{ name: 'nuevoSitio' }">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>

    <div class="card">
      <div class="card-body" style="min-height: 575px;">
        <div class="table-responsive">
          <table class="table table-nowrap align-middle" id="tabla-sitio-network">
            <thead>
              <tr>
                <th style="width: 250px;">Nombre</th>
                <th>Dirección</th>
                <th style="width: 180px">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sitio in sitios" :key="sitio.id">
                <td>{{ sitio.nombre }}</td>
                <td>
                  <p class="text-truncate">
                    {{ sitio.direccion }}
                  </p>
                </td>
                <td>
                  <router-link
                    class="btn btn-warning btn-sm"
                    :to="{ name: 'edicionSitio', params: { id: sitio.id } }"
                  >
                    <i class="mdi mdi-square-edit-outline font-size-13"></i>
                    Editar
                  </router-link>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="preguntaEliminar(sitio)"
                  >
                    <i class="mdi mdi-trash-can-outline font-size-13"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import Filtrador from '@/components/Filtrador.vue'
import SitioSrv from '@/services/SitioSrv.js'
import Swal from 'sweetalert2'
export default {
  name: 'ListadoSitios',
  components: {
    Layout,
    Filtrador
  },
  data() {
    return {
      dato: '',
      sitios: []
    }
  },
  created: function() {
    // Cargas iniciales
    this.refrescarSitios()
  },
  methods: {
    preguntaEliminar: function(sitio) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminación de sitio",
        html: '¿Está seguro que desea eliminar el sitio <strong>'+sitio.nombre+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          SitioSrv.eliminar(sitio.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarSitios()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el sitio'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarSitios: function() {
      var self = this

      iu.spinner.mostrar('#tabla-sitio-network')

      let params = { dato: self.dato }

      SitioSrv.sitiosJSON(params).then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron refrescar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-sitio-network')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-sitio-network')
  }
}
</script>
<style scoped>
.text-truncate {
  width: 750px;
}
</style>