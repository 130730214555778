<template>
  <Layout tituloPagina="Sistema | Aplicaciones">
    <div class="row">
      <div class="col-lg-5">
        <cmp-secciones vista="aplicaciones"></cmp-secciones>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-2 col-5">
            <div class="form-check form-switch form-switch-lg mb-3">
              <input
                :checked="todos"
                @change="
                  todos = !todos,
                  refrescarAplicaciones()
                "
                class="form-check-input"
                type="checkbox"
                id="banderaTodo"
              />
              <label
                class="form-check-label"
                for="banderaTodo"
              >
                Todos
              </label>
            </div>
          </div>
          <div class="col-sm-7 col-7 mb-3">
            <filtrador
              v-on:dato-actualizado="
                dato = $event,
                refrescarAplicaciones()
              "
              :datoini="dato"
              placeholder="Dato de la aplicación"
            ></filtrador>
          </div>
          <div class="col-sm-3 col-3 text-right">
            <router-link
              :to="{ name: 'nuevaAplicacion' }"
              class="btn btn-success"
            >
              <span class="d-none d-sm-block">
                <i class="fa fa-plus"></i>
                Nueva aplicación
              </span>
              <span class="d-block d-sm-none">
                <i class="fa fa-plus"></i>
              </span>
            </router-link>
          </div>
        </div>
        <br />
        <div class="card">
          <div class="card-body" style="min-height: 475px;">
            <div class="table-responsive">
              <table class="table table-hover table-nowrap align-middle" id="tabla-aplicaciones">
                <thead>
                  <tr>
                    <th style="width: 50px">Imagen</th>
                    <th>Nombre de la aplicación</th>
                    <th>Roles</th>
                    <th style=" width: 50px">Estado</th>
                    <th style=" width: 150px">Última operación</th>
                    <th class="text-center" style="width: 200px">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="aplicacion in aplicaciones" :key="aplicacion.id">
                    <td>
                      <!-- modificacion del icono del usuario -->
                      <img
                        :src="`${API}/aplicaciones/${aplicacion.id}/imagen?_tk=${token}&ancho=50&alto=50`"
                        class="rounded-circle"
                        style="width: 30px; height: 30px;"
                        v-if="aplicacion.id_foto"
                      />
                      <div v-show="!aplicacion.id_foto" class="auth-logo">
                        <img
                          :src="aplicacionIcon"
                          class="rounded-circle img-thumbnail auth-logo-dark"
                          style="width: 30px; height: 30px;"
                        />
                        <img
                          :src="aplicacionIconBlanco"
                          class="rounded-circle img-thumbnail auth-logo-light"
                          style="width: 30px; height: 30px;"
                        />
                      </div>
                      <!-- fin de la modificacion -->
                    </td>
                    <td class="fw-semibold">
                      {{ aplicacion.nombre }}
                    </td>
                    <td>
                      <template v-for="rol in aplicacion.roles">
                        <span v-if="true" :key="'rol-'+rol.id" class="badge bg-primary bg-gradient">
                          {{rol.nombre}}
                        </span>
                        <span v-if="true" :key="'esp'+rol.id" style="display: inline-block; width: 2px;"></span>
                      </template>
                    </td>
                    <td>
                      <span
                        v-if="aplicacion.activo == true"
                        class="badge bg-success bg-gradient font-size-13"
                      >
                        Activo
                      </span>
                      <span
                        v-if="aplicacion.activo == false"
                        class="badge bg-danger bg-gradient font-size-13"
                      >
                        Inactivo
                      </span>
                    </td>
                    <td>
                      {{formatoMT(aplicacion.mt_ultima_operacion)}}
                    </td>
                    <td class="text-center">
                      <router-link
                        :to="{
                          name: 'edicionAplicacion',
                          params: { id: aplicacion.id }
                        }"
                        class="btn btn-sm btn-warning"
                      >
                        <i class="mdi mdi-content-save-edit-outline"></i>
                        Editar
                      </router-link>
                      <button
                        @click="preguntaEliminarAplicacion(aplicacion)"
                        class="btn btn-danger btn-sm"
                      >
                        <i class="mdi mdi-trash-can-outline"></i>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-center">
          <paginador
            :pag="page"
            :totpag="totpag"
            :epp="epp"
            @cargar-pagina="cargarPagina"
            @epp-actualizado="
              epp = $event,
              refrescarAplicaciones()
            "
          ></paginador>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import CmpSecciones from "../CmpSecciones.vue"
import Filtrador from "@/components/Filtrador.vue"
import Paginador from "@/components/Paginador.vue"
import AplicacionSrv from "@/services/AplicacionSrv.js"
import aplicacionIcon from "@/assets/img/logoArgus.png"
import aplicacionIconBlanco from "@/assets/img/logoArgusBlanco.png"
import Swal from 'sweetalert2'
import moment from "moment"
import API from "@/API.js"

export default {
  name: "ListadoAplicaciones",
  components: { Layout, CmpSecciones, Filtrador, Paginador },

  data: function() {
    return {
      API: API,
      dato: "",
      token: localStorage.getItem("argusblack.token"),
      page: 1,
      epp: parseInt(localStorage.getItem("argusblack.listadoAplicaciones.por_pagina") || 10),
      ta: 0, // Total de aplicaciones
      totpag: 0, // Total de páginas
      todos: false,
      aplicaciones: [],
      aplicacionIcon: aplicacionIcon,
      aplicacionIconBlanco: aplicacionIconBlanco,
      
    }
  },
  created() {
    var self = this

    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.page) this.page = parseInt(this.$route.query.page)
    if (this.$route.query.epp) this.epp = parseInt(this.$route.query.epp)
    if (this.$route.query.todos) this.todos = this.$route.query.todos == "true" ? true : false

    self.refrescarAplicaciones()
  },
  mounted() {
    iu.spinner.mostrar("#tabla-aplicaciones")
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarAplicaciones({ page: n })
    },
    formatoMT(mt) {
      if(mt == null) return null
      return moment(mt, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
    },

    preguntaEliminarAplicacion: function(aplicacion) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminar la aplicacion",
        html: '¿Está seguro que deseas eliminar la aplicacion <strong>' + aplicacion.nombre + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          AplicacionSrv.eliminar(aplicacion.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarAplicaciones()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar la aplicacion'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarAplicaciones: function() {
      var self = this

      iu.spinner.mostrar("#tabla-aplicaciones")

      this.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp,
          todos: self.todos
        }
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        dato: self.dato,
        todos: self.todos,
        con_roles: true
      }

      AplicacionSrv.aplicacionesJSON(params).then(response => {
        localStorage.setItem("argusblack.listadoAplicaciones.por_pagina", self.epp)

        let page = response.data
        self.aplicaciones = page.data
        self.ta = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarAplicaciones()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudo cargar los calendarios"
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar("#tabla-aplicaciones")
      })
    }
  }
}
</script>
  
<style scoped>
</style>