<template>
  <Layout :tituloPagina="'VPN Credenciales'">
    <div class="row">
      <div class="col-lg-8 col-md-11 col-sm-12">
        <div class="row">
          <div class="col-lg-8 col-8">
            <filtrador
              v-on:dato-actualizado="
                dato = $event,
                mostrar_animacion = true,
                refrescarCredenciales()
              "
              :datoini="dato"
              placeholder="Identidad de la credencial"
            ></filtrador>
          </div>
          <div class="col-lg-4 col-4 pb-4">
            <div class="bx-pull-right">
              <router-link :to="{ name: 'nuevaCredencialVpn' }" class="btn btn-primary">
                <span class="d-none d-sm-block">
                  <i class="mdi mdi-plus-thick"></i>
                  Nueva Credencial
                </span>
                <span class="d-block d-sm-none">
                  <i class="mdi mdi-plus-thick"></i>
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card">
          <h5 class="card-header">Credenciales</h5>
          <div class="card-body" style="min-height: 475px; ">
            <div class="table-responsive">
              <table class="table table-hover table-nowrap align-middle" style="min-width: 550px" id="tabla-credenciales">
                <thead>
                  <tr>
                    <th>Identidad</th>
                    <th style="min-width: 100px">Clave</th>
                    <th>En uso</th>
                    <th>Dirección IP en la VPN</th>
                    <th>Archivos de configuración</th>
                    <th style="width: 130px">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="credencial in credenciales" :key="credencial.id">
                    <td>
                      {{credencial.identidad}}
                    </td>
                    <td>
                      <span v-show="credencial.mostrar_clave">
                        {{credencial.clave}}
                      </span>

                      <span v-show="!credencial.mostrar_clave">
                        {{credencial.clave.replace(/./g, '*')}}
                      </span>

                      <button class="btn btn-default btn-sm"
                        @click="credencial.mostrar_clave = !credencial.mostrar_clave">
                        <i class="mdi mdi-eye"></i>
                      </button>
                    </td>
                    <td>
                      <span class="badge rounded-pill bg-gradient px-3"
                        :class="{
                          'bg-success': credencial.en_uso,
                          'bg-secondary': !credencial.en_uso
                        }">
                        <i class="mdi mdi-check"></i>
                        {{credencial.en_uso ? 'Si': 'No'}} 
                      </span>
                    </td>
                    <td>{{credencial.direccion_ip}}</td>
                    <td>
                      <button class="btn btn-light btn-sm" 
                        @click="mostrarMdlDescargarConfiguracion(credencial.id, credencial.identidad)"
                        :disabled="credencial.estado_vpn < 3"
                      >
                        <i class="mdi mdi-download"></i>
                        <span v-show="credencial.estado_vpn == 3">
                          Descargar 
                        </span>
                        <span v-show="credencial.estado_vpn < 3">
                          Preparando 
                          <span class="loading"></span>
                        </span>
                      </button>
                    </td>
                    <td>
                      <router-link :to="{ name: 'edicionCredencialVpn', params: { id: credencial.id }}"
                        class="btn btn-outline-warning btn-sm">
                        <i class="mdi mdi-square-edit-outline"></i>
                        Editar
                      </router-link>
                        
                      <button class="btn btn-outline-danger btn-sm"
                        @click="
                          mostrar_animacion = true,
                          preguntaEliminar(credencial)
                        ">
                        <i class="mdi mdi-trash-can-outline"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-center">
          <paginador
            :pag="page"
            :totpag="totpag"
            :epp="epp"
            @cargar-pagina="cargarPagina"
            @epp-actualizado="
              epp = $event,
              refrescarCredenciales()
            "
          ></paginador>
        </div>
      </div>
    </div>
    <MdlDescargaConfiguracion ref="mdlDescargaConfiguracion"/>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import CredencialVpnSrv from '@/services/vpn/CredencialSrv.js'
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import MdlDescargaConfiguracion from './MdlDescargaConfiguracion.vue'
import Swal from 'sweetalert2'
export default {
  name: 'ListadoCredenciales',
  components: {
    Layout,
    MdlDescargaConfiguracion,
    Filtrador,
    Paginador
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoCredenciales.por_pagina') || 10),
      totpag: 0, // Total de páginas
      credenciales: [],
      mostrar_animacion: true,
      intervals: {
        actualizacion: null
      }
    }
  },
  created () {
    let self = this

    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp)  self.epp  = parseInt(self.$route.query.epp)

    self.refrescarCredenciales()

    self.intervals.actualizacion = setInterval(function(){
      self.mostrar_animacion = false
      self.refrescarCredenciales()
    }, 20000)
  },
  beforeUnmount: function() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarCredenciales({ page: n })
    },
    mostrarMdlDescargarConfiguracion(idCredencial, identidad) {
      this.$refs.mdlDescargaConfiguracion.mostrar(idCredencial, identidad)
    },
    mostrarMensajeError(error) {
      let data = error.response.data
      let mensaje = data.message
      let errors = data.errors || []

      mensaje += '<br/><br/>'
      Object.keys(errors).forEach(key => {
        mensaje += ' * ' + errors[key] + '<br>'
      })
      iu.msg.error(mensaje)
    },
    preguntaEliminar(credencial) {
      let self = this

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        title: "Eliminación de credencial",
        html: `¿Estás seguro que deseas eliminar la credencial <strong>${credencial.identidad}</strong>?`,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          CredencialVpnSrv.eliminar(credencial.id).then(response => {
            swalWithBootstrapButtons.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarCredenciales()
          }).catch(error => {
            self.mostrarMensajeError(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swalWithBootstrapButtons.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    refrescarCredenciales() {
      let self = this
      
      if (self.mostrar_animacion) iu.spinner.mostrar('#tabla-credenciales')

      self.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp
        }
      }).catch(error => {})
      
      let params = {
        dato: self.dato,
        epp: self.epp,
        page: self.page
      }

      CredencialVpnSrv.credencialesJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoCredenciales.por_pagina', self.epp)
        let page = response.data
        let credenciales = page.data

        credenciales.forEach(function(el){
          Object.assign(el, {mostrar_clave: false})
        })
        
        self.credenciales = credenciales 
        self.totpag = page.last_page
        
        if ( self.totpag < self.page ) {
          self.page = self.totpag
          self.refrescarCredenciales()
        }
      }).finally (() => {
        if (self.mostrar_animacion) iu.spinner.ocultar('#tabla-credenciales')

        self.mostrar_animacion = true
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-credenciales')
  }
}
</script>

<style scoped>
</style>