<template>
  <Layout :tituloPagina="`Network | Routers | Edición | ${router.nombre} | Proveedores de internet | ${isp.nombre} | Proveedores de respaldo`">
    <div class="row">
      <div class="col-lg-9">
        <div class="text-right">
          <button
            @click="agregarIspRespaldo()"
            class="btn btn-success btn-sm"
          >
            <i class="mdi mdi-plus-thick"></i>
            Agregar ISP de respaldo
          </button>
        </div>
        <br />
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              Listado de proveedores de respaldo de internet para el ISP
              "{{ isp.nombre }}"
            </h4>
            <div>
              <a href="#" onclick="return false;" @click="cerrar()">
                <i class="mdi mdi-close text-dark"></i>
              </a>
            </div>
          </div>
          <div class="card-body" style="min-height: 475px;">
            <div class="table-responsive">
              <table class="tblRouters table table-hover table-nowrap align-middle">
                <thead>
                  <tr>
                    <th style=" width: 80px">Id</th>
                    <th>Nombre</th>
                    <th style=" width: 250px">Prioridad</th>
                    <th>Carga / Descarga</th>
                    <th style=" width: 100px">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(isp,index) in isps" :key="isp.id">
                    <td>{{ isp.id }}</td>
                    <td>{{ isp.nombre }}</td>
                    <td>
                      <input
                        type="number"
                        ref="priori"
                        class="form-control"
                        v-model="isp.prioridad"
                        @change="actualizarIspRespaldos()"
                      />
                    </td>
                    <td>
                      <span :class="{ 'text-danger': isp.carga_maxima == 0 }">
                        {{ isp.carga_maxima }}
                        {{ isp.unidad_carga == 'k' ? 'kbps' : '' }}
                        {{ isp.unidad_carga == 'M' ? 'Mbps' : '' }}
                      </span>
                      /
                      <span :class="{ 'text-danger': isp.descarga_maxima == 0 }">
                        {{ isp.descarga_maxima }}
                        {{ isp.unidad_descarga == 'k' ? 'kbps' : '' }}
                        {{ isp.unidad_descarga == 'M' ? 'Mbps' : '' }}
                      </span>
                    </td>
                    <td>
                      <button
                        class="btn btn-outline-danger btn-sm"
                        @click="eliminar(index)"
                      >
                        <i class="mdi mdi-trash-can-outline"></i>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="text-right">
          <button class="btn btn-secondary" @click="atras()">
            <i class="mdi mdi-chevron-left"></i>
            Atrás
          </button>
        </div>
      </div>
    </div>
    <mdl-isp-respaldo
      ref="modalIspRespaldo"
      :idsIspsRespaldos="ids_isps_respaldo"
      v-on:isp:respaldo="agregarRespaldo($event)"
    ></mdl-isp-respaldo>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import MdlIspRespaldo from './MdlIspRespaldo.vue'
import IspRouterSrv from '@/services/IspRouterSrv.js'
import RouterSrv from '@/services/RouterSrv.js'
export default {
  name: 'ListadoProveedoresRespaldoInternetRouter',
  components: { Layout, MdlIspRespaldo },
  data() {
    return {
      baseUrl: window.location.origin,
      router: { id: null },
      isp: {
        id: null,
        nombre: '',
      },
      id_isp: null,
      isps: [],
      respaldo: []
    }
  },

  created: function() {
    var self = this
    self.cargarRouter(function() {
      self.cargarIsp(function() {
        self.refrescarIsps()
      })
    })
  },

  methods: {
    actualizarIspRespaldos: function() {
      var self = this, isps = []

      let retornar = false
      self.isps.forEach((isp,index) => {
        if(isp.prioridad == null || isp.prioridad == '' || isp.prioridad == undefined) {
          iu.msg.warning('La prioridad no puede ir con un valor vació')
          self.$refs.priori[index].select()
          retornar = true
        }
      })

      if(retornar == true) return

      self.isps.forEach(isp => {
        isps.push({
          id: isp.id,
          prioridad: isp.prioridad
        })
      })

      let datos = {
        isps: isps
      }

      IspRouterSrv.actualizarIspRespaldo(self.id_isp, datos).then(response => {
        iu.msg.success('Se actualizo correctamente los isps de respaldo')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.refrescarIsps()
      })
    },

    agregarIspRespaldo: function () {
      var self = this 
      self.$refs.modalIspRespaldo.mostrar(self.router.id, self.id_isp)
    },

    agregarRespaldo(respaldo) {
      var self = this

      let index = self.isps.findIndex(function (isp) {
        return isp.id == respaldo.id
      })
      
      if(index != -1) {
        iu.msg.warning('No se puede registrar el mismo proveedor de respaldo.')
        return
      }

      self.isps.push(respaldo)

      self.actualizarIspRespaldos()
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarIsp: function(callback) {
      var self = this
      self.id_isp = this.$route.params.id_isp

      IspRouterSrv.ispJSON(self.id_isp).then(response => {
        self.isp = response.data

        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    cargarRouter: function(callback) {
      var self = this
      var idRouter = this.$route.params.id

      RouterSrv.routerJSON(idRouter).then(response => {
        self.router = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    eliminar: function(index) {
      var self = this

      self.isps.splice(index, 1)

      self.actualizarIspRespaldos()
    },

    refrescarIsps () {
      var self = this

      IspRouterSrv.ispsRespaldosJson(self.id_isp).then(response => {
        self.isps = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }
  },
  computed:{
    ids_isps_respaldo :function(){
      let ids = this.isps.map(isp => {
        return isp.id
      })
      return ids
    }
  }
}
</script>

<style scoped>

</style>