<template>
  <Layout tituloPagina="Compras | Requerimientos">
    <div class="row">
      <div class="col-lg-12">
        <cmp-secciones seccion="requerimientos"></cmp-secciones>
      </div>
    </div>

    <br>
 
    <div class="row">
      <div class="col-lg-2 col-md-6">
        <div class="input-group">
          <span class="input-group-text">
            <i class="fa fa-filter"></i>
            Estado
          </span>
          <select class="form-select">
            <option >Todos</option>
            <option >Sin seguimiento</option>
            <option >Aprobado</option>
            <option >Rechazado</option>
            <option >Pospuesto</option>
            <option >Cancelado</option>
          </select>
        </div>
      </div>
      <div class="col-lg-2 col-md-6">
        <div class="input-group">
          <span class="input-group-text">
            <i class="fa fa-filter"></i>
            Fecha
          </span>
          <select class="form-select">
            <option >Seleccionar</option>
            <option >Dia</option>
            <option >Mes</option>
            <option >Año</option>
            <option >Personalizado</option>
          </select>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="input-group">
          <input class="form-control"/>
          <span class="input-group-text">
            <i class="fa fa-calendar"></i>
          </span>
          <span class="input-group-text">
            al
          </span>
          <input class="form-control"/>
          <span class="input-group-text">
            <i class="fa fa-calendar"></i>
          </span>
          <button class="btn btn-success">
            <i class="mdi mdi-refresh"></i>
          </button>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-right">
        <router-link :to="{ name: 'nuevoRequerimiento' }" class="btn btn-success">
          <i class="fa fa-plus"></i>
          Nuevo requerimiento
        </router-link>
      </div>
    </div>

    <br />
    
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover align-middle">
            <thead>
              <tr>
                <th>No.</th>
                <th>Fecha de solicitud</th>
                <th>Solicitante</th>
                <th>Área</th>
                <th>Estado</th>
                <th style="width:200px">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  313123
                </td>
                <td>
                  31/05/2020
                </td>
                <td>
                  Omar Jesus Aguilar Mendoza
                </td>
                <td>
                  x
                </td>
                <td>
                  pendiente
                </td>
                <td>
                  <router-link
                    :to="{ name: 'visorRequerimiento', params: { id:10 } }"
                    class="btnInfo btn btn-default btn-xs"
                  >
                    <i class="fa fa-eye"></i>
                    ver
                  </router-link>
                  <router-link
                    :to="{ name: 'edicionRequerimiento', params: { id:10 } }"
                    class="btnEditar btn btn-default btn-xs"
                  >
                    <i class="fa fa-edit"></i>
                    Editar
                  </router-link>
                  <button class="btnBorrar btn btn-default btn-xs" @click="cancelarRequerimiento(10)">
                    <i class="fa fa-ban"></i>
                    Cancelar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarRequerimientos()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import CmpSecciones from '../CmpSecciones.vue'
import Paginador from '@/components/Paginador.vue'
import Layout from "@/views/layouts/main"

export default {
  components: {
    CmpSecciones, 
    Paginador,
    Layout
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: 10,
      tc: 0, // Total de clientes
      totpag: 0, // Total de páginas
    }
  },
  created() {
    
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      //self.refrescarRequerimientos({ page: n })
    },

    cancelarRequerimiento: function(idRequerimiento) {
      var self = this

      swal({
        title: "¿Cancelar requerimiento?",
        text: "¿Está seguro Ejecutivo de cancelar el requerimiento 1035?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        closeOnConfirm: false,
        closeOnCancel: false
      },
      function(isConfirm) {
        if (isConfirm) {
          self.motivoRequerimineto()
        } else {
          swal("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    motivoRequerimineto: function(){
      swal({
        title: "Motivo de cancelación",
        text: "Escriba el motivo de la cancelación:",
        type: "input",
        html: true,
        showCancelButton: true,
        closeOnConfirm: false,
        inputPlaceholder: "Escribe algo"
      }, function (inputValue) {
        if (inputValue === false) return false;
        if (inputValue === "") {
          swal.showInputError("Necesitas escribir algo!");
          return false
        }
        swal("Cancelado!", "Cotización 327 cancelada por motivo: \n\n" + inputValue, "success");
      });
    }
  }
}
</script>

<style></style>
