<template>
  <Layout :tituloPagina="`Network | Routers | ${router.nombre} | Registro de incidencias`">
    <div class="row">
      <div class="col-lg-3 col-md-4 mb-4">
        <div class="input-group">
          <div class="input-group-text">
            <span class="d-none d-sm-block">
              Total de incidencias
            </span>
            <span class="d-block d-sm-none">
              Caídas
            </span>
          </div>
          <span class="form-control text-center" style="width: 80px;">
            {{ tc }}
          </span>
        </div>
      </div>
      <div class="col-lg-3 col-md-1"></div>
      <div class="col-lg-6 col-md-7">
        <div class="row">
          <div class="col-md-5 col-sm-5 col-12">
            <div class="input-group mb-4">
              <span class="input-group-text">
                Inicio
              </span>
              <input
                type="date"
                class="form-control"
                v-model="fecha_inicial"
                @change="refrescarCaidas()"
              />
              <button
                class="btn btn-light" 
                @click="
                  fecha_inicial = '',
                  refrescarCaidas()
                "
              >
                <i class="mdi mdi-trash-can-outline"></i>
              </button>
            </div>
          </div>
          <div class="col-md-5 col-sm-5 col-12">
            <div class="input-group mb-4">
              <span class="input-group-text">
                Fin
              </span>
              <input
                type="date"
                class="form-control"
                v-model="fecha_final"
                @change="refrescarCaidas()"
              />
              <button
                class="btn btn-light"
                @click="
                  fecha_final = '',
                  refrescarCaidas()
                "
              >
                <i class="mdi mdi-trash-can-outline"></i>
              </button>
            </div>
          </div>
          <div class="col-md-2 col-sm-2 col-12 text-right mb-4">
            <button class="btn btn-success" @click="refrescarCaidas()">
              <i class="bx bx-filter-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="d-flex align-items-start">
          <div class="flex-grow-1">
            <h5 class="card-title mb-4">
              Registro de incidencias del "{{ router.nombre }}"
            </h5>
          </div>
          <div class="flex-shrink-0">
            <div>
              <button
                class="btn btn-outline-danger btn-sm d-inline-block me-3"
                @click="eliminarRegistros()"
              >
                Borrar historico
              </button>
              <div class="dropdown d-inline-block">
                <a
                  class="dropdown-toggle text-muted"
                  href="javascript: void(0);"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{tipo_caida=='todos' ? 'Todos' : ''}}
                  {{tipo_caida=='routers' ? 'Routers' : ''}}
                  {{tipo_caida=='isps' ? 'ISPs' : ''}}
                  {{tipo_caida=='cpes' ? 'CPEs' : ''}}
                  <i class="mdi mdi-chevron-down ms-1"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <a 
                    class="dropdown-item" 
                    :class="{'active': tipo_caida=='todos'}"
                    href="javascript: void(0);"
                    @click="
                      tipo_caida='todos',
                      refrescarCaidas()
                    "
                  >
                    Todos
                  </a>
                  <a 
                    class="dropdown-item"
                    :class="{'active': tipo_caida=='routers'}"
                    href="javascript: void(0);"
                    @click="
                      tipo_caida='routers',
                      refrescarCaidas()
                    "
                  >
                    Routers
                  </a>
                  <a 
                    class="dropdown-item"
                    :class="{'active': tipo_caida=='isps'}"
                    href="javascript: void(0);"
                    @click="
                      tipo_caida='isps',
                      refrescarCaidas()
                    "
                  >
                    ISPs
                  </a>
                  <a
                    class="dropdown-item"
                    :class="{'active': tipo_caida=='cpes'}"
                    href="javascript: void(0);"
                    @click="
                      tipo_caida='cpes',
                      refrescarCaidas()
                    "
                  >
                    CPEs
                  </a>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div class="table-responsive" style="min-height: 475px;">
          <table class="table table-hover table-nowrap align-middle" id="tblCaidas">
            <thead>
              <tr>
                <th style="width: 50px">Caida</th>
                <th style="width: 150px">Desde</th>
                <th>Hasta</th>
                <th style="width: 150px" class="text-left">Duración</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="caida in caidas">
                <tr v-if="true" :key="caida.id">
                  <td class="fw-semibold">
                    <button
                      @click="palancaInformacion(caida)"
                      class="btn btn-ling btn-xs"
                    >
                      <i
                        class="mdi"
                        :class="{
                          'mdi-chevron-down': !caida.info, 
                          'mdi-chevron-up': caida.info
                        }"
                      ></i>
                    </button>
                    <span
                      class="badge font-size-12 bg-gradient text-uppercase"
                      :class="{
                        'bg-soft-danger text-danger': caida.tipo_caida == 'Caida router',
                        'bg-soft-warning text-warning': caida.tipo_caida == 'Caida isp',
                        'bg-soft-info text-info': caida.tipo_caida == 'Caida cpe'
                      }"
                    >
                      {{ caida.tipo_caida }}
                    </span>
                  </td>
                  <td>
                    {{caida.inicio ? formatoFecha(caida.inicio, 'dddd, DD/MM/YYYY - hh:mm a') : ''}}
                  </td>
                  <td>
                    {{caida.fin ? formatoFecha(caida.fin, 'dddd, DD/MM/YYYY - hh:mm a') : 'Ahora'}}
                  </td>
                  <td class="text-left">
                    {{ caida.fin ? duracion(caida.inicio, caida.fin) : intervalos(caida.inicio, caida) }}
                    {{ !caida.fin ? caida.duracion : '' }}
                  </td>
                </tr>
                <tr v-if="caida.info" :key="`caida-info-${caida.id}`">
                  <td colspan="2">
                    <span class="ms-4" v-if="caida.tipo_caida == 'Caida router'">
                      <strong>Router:</strong>
                      <router-link
                        v-if="caida.router"
                        :to="{
                          name: 'edicionRouter',
                          params: { id: caida.id_router }
                        }"
                        class="ms-2"
                      >
                        {{ caida.router.nombre }}
                      </router-link>
                    </span>
                    <span class="ms-4" v-if="caida.tipo_caida == 'Caida cpe'">
                      <strong>CPE:</strong>
                      <router-link
                        v-if="caida.cpe"
                        :to="{
                          name: 'edicionCpe',
                          params: { id: caida.id_cpe }
                        }"
                        class="ms-2"
                      >
                        {{ caida.cpe.nombre }}
                      </router-link>
                    </span>
                    <span class="ms-4" v-if="caida.tipo_caida == 'Caida isp'">
                      <strong>ISP:</strong>
                      <router-link
                        v-if="caida.isp"
                        :to="{
                          name: 'edicionProveedorInternetRouter',
                          params: { id_isp: caida.id_isp }
                        }"
                        class="ms-2"
                      >
                        {{ caida.isp.nombre }}
                      </router-link>
                    </span>
                  </td>
                  <td colspan="3">
                    <span class="ms-4" v-if="caida.sitio">
                      <strong>Sitio:</strong>
                      <router-link
                        v-if="caida.sitio"
                        :to="{
                          name: 'edicionSitio',
                          params: { id: caida.id_sitio }
                        }"
                        class="ms-2"
                      >
                        {{ caida.sitio.nombre }}
                      </router-link>
                    </span>
                    <span class="ms-4" v-if="!caida.sitio && caida.router">
                      <strong>Sitio:</strong>
                      <router-link
                        v-if="caida.router"
                        :to="{
                          name: 'edicionSitio',
                          params: { id: caida.router.id_sitio }
                        }"
                        class="ms-2"
                      >
                        {{ caida.router.sitio.nombre }}
                      </router-link>
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarCaidas()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import IncidenciaCaidasSrv from '@/services/incidencia/IncidenciaCaidasSrv.js'
import Paginador from '@/components/Paginador.vue'
import RouterSrv from '@/services/RouterSrv.js'
import Layout from '@/views/layouts/main'
import Swal from 'sweetalert2'
import moment from 'moment'
export default {
  name: 'RegistroCaidasRouter',
  components: { Layout, Paginador },
  data() {
    return {
      router: {
        id: null,
        nombre: '',
        ip: '',
        descripcion: '',
        usuario: '',
        clave: '',
        puerto_api: 8728,
        puerto_web_proxy: 8080,
        usuario_bloqueado: true,
        clave_bloqueada: true,
        puerto_api_bloqueado: true,
        puerto_web_proxy_bloqueado:true,
        latitud: 0,
        longitud: 0,
        id_sitio: null,
        ifs_sinc: 0,
        bs_sinc: 0,
        pv_sinc: 0,
        intentos_conexion_fallidos_api: 0,
        proximo_intento_conexion_api: null,
        dns1_sugerido: null,
        dns2_sugerido: null,
        registro_en_log : false,
        cantidad_cpes: {
          total: 0,
          sincronizados: 0,
          por_sincronizar: 0
        }
      },
      caidas: [],
      fecha_inicial: '',
      fecha_final: '',

      tipo_caida: 'todos', // todos, routers, isps, cpes

      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.registroCaidasRouter.por_pagina') || 10),
      tc: 0, // Total de caidas
      totpag: 0, // Total de páginas
      intervals: [],
    }
  },
  computed: {
    idRouter: function(){
      var self = this
      return self.$route.params.id
    }
  },
  beforeUnmount: function() {
    var self = this
    self.intervals.forEach(i => { clearInterval(i) })
  },
  created() {
    var self = this

    if (this.$route.query.page) this.page = parseInt(this.$route.query.page)
    if (this.$route.query.epp) this.epp = parseInt(this.$route.query.epp)
    if (this.$route.query.fecha_inicial) this.fecha_inicial = this.$route.query.fecha_inicial
    if (this.$route.query.fecha_final) this.fecha_final = this.$route.query.fecha_final
    if (this.$route.query.tipo_caida) this.tipo_caida = this.$route.query.tipo_caida

    self.cargarRouter()
    self.refrescarCaidas()
  },
  mounted() {
    iu.spinner.mostrar('#tblCaidas')
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarCaidas({ page: n })
    },

    cargarRouter: function() {
      var self = this

      RouterSrv.routerJSON(self.idRouter).then(response => {
        let router = response.data
        Object.assign(self.router, router)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el router'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    eliminarRegistros: function() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      let titulo = ''
      switch (self.tipo_caida) {
        case 'routers':
          titulo = '¿Está seguro que desea eliminar el historial de caidas routers registradas del router <strong>'+self.router.nombre+'</strong>?'
        break;
        case 'isps':
          titulo = '¿Está seguro que desea eliminar el historial de caidas isps registradas del router <strong>'+self.router.nombre+'</strong>?'
        break;
        case 'cpes':
          titulo = '¿Está seguro que desea eliminar el historial de caidas cpes registradas del router <strong>'+self.router.nombre+'</strong>?'
        break;
        default:
          titulo = '¿Está seguro que desea eliminar el historial de todas las caidas registradas del router <strong>'+self.router.nombre+'</strong>?'
        break;
      }

      swal.fire({
        title: "Eliminar historial de caidas",
        html: titulo,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          IncidenciaCaidasSrv.eliminarRegistros(self.idRouter, {tipo_caida: self.tipo_caida}).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarCaidas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el sitio'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
      
    },

    intervalos(inicio = null, caida){
      var self = this
      self.intervals.push(
        setInterval(function(){
          let fin = moment().format("YYYY-MM-DD HH:mm:ss")
          caida.duracion = self.duracion(inicio, fin)
        }, 1000)
      )
    },

    palancaInformacion: function(caida) {
      if (caida.info) caida.info = false
      else caida.info = true
    },

    duracion(inicio = null, fin = null) {
      if(fin == null) return

      inicio = moment(inicio)
      fin = moment(fin)
      let segundos = fin.diff(inicio,"seconds")

      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      var seg = Math.floor((segundos % 60) % 60)

      let cadena = ''
      if(dias > 0) cadena+= dias+' dia '
      if(horas > 0) cadena+= horas+' hrs '
      if(minutos > 0) cadena+= minutos+' min '
      if(segundos > 0) cadena+= seg+' seg'

      return cadena
    },

    formatoFecha(mt, formato = 'DD/MM/YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    },

    refrescarCaidas: function() {
      var self = this

      iu.spinner.mostrar('#tblCaidas')

      this.$router.replace({
        query: {
          page: self.page,
          epp: self.epp,
          fecha_inicial: self.fecha_inicial,
          fecha_final: self.fecha_final,
          tipo_caida: self.tipo_caida
        }
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        tipo_caida: self.tipo_caida,
        id_router: self.idRouter
      }

      if(self.fecha_inicial != '' && self.fecha_final == '') {
        Object.assign(params, {
          fecha_inicial: moment(self.fecha_inicial).format('YYYY-MM-DD'),
          fecha_final: moment(self.fecha_inicial).format('YYYY-MM-DD')
        })
      }

      if(self.fecha_inicial != '' && self.fecha_final != '') {
        Object.assign(params, {
          fecha_inicial: moment(self.fecha_inicial).format('YYYY-MM-DD'),
          fecha_final: moment(self.fecha_final).format('YYYY-MM-DD')
        })
      }


      self.intervals.forEach(i => { clearInterval(i) })
      IncidenciaCaidasSrv.caidasJSON(params).then(response => {
        localStorage.setItem('argusblack.registroCaidasRouter.por_pagina', self.epp)

        let page = response.data
        self.caidas = page.data
        self.tc = page.total
        self.totpag = page.last_page

        self.caidas.forEach(c => {
          c.info = false
          c.duracion = null
          return c
        })

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarCaidas()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los caidas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblCaidas')
      })
    }
  }
}
</script>

<style scoped>
</style>