<template>
  <Layout :tituloPagina="`Sistema | Roles de usuarios | Rol ${ rol.nombre } | Edición de permisos de notificaciones`">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Elegir que notificaciones se mostraran al rol <span class="text-success">{{ rol.nombre }}</span>
            </h4>
            <div>
              <a 
                href="#"
                onclick="return false;"
                @click="$router.go(-1)"
                v-if="rol.id != null"
              >
                <i class="mdi mdi-close text-dark"></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover table-striped table-nowrap align-middle" id="tabla-notificaciones" style="min-height: 250px;">
                <thead>
                  <tr>
                    <th style="width: 70px">Estado</th>
                    <th style="width: 300px">Nombre de la notificacion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(notificacion, index) in notificaciones" :key="`fm_${index+1}`">
                    <td>
                      <div class="form-check form-switch form-switch-md">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'switch-' + index"
                          @change="actualizarNotificacion(notificacion, index, $event)"
                          :checked="notificacion.estado == true"
                        />
                        <label
                          class="form-check-label"
                          :for="'switch-' + index"
                        ></label>
                      </div>
                    </td>
                    <td class="fw-semibold">
                      {{ notificacion.nombre }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button class="btn btn-secondary" @click="$router.go(-1)">
            <i class="mdi mdi-chevron-left"></i>
            Atrás
          </button>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import NotificacionesRolSrv from '@/services/NotificacionesRolSrv.js'
import RolUsuariosSrv from '@/services/RolUsuariosSrv.js'
import Layout from "@/views/layouts/main"
export default {
  name: 'Notificaciones',
  props: ['id_rol'],
  components: { Layout },
  data() {
    return {
      notificaciones: [],
      rol: {
        id: null,
        nombre: ''
      }
    }
  },

  created() {
    var self = this

    // Carga del rol
    self.rol.id = self.$route.query.id_rol
    
    if (self.rol.id == null) self.$router.go(-1)

    // Carga de los permisos del rol
    self.cargarRol()
    self.refrescarNotificaciones()
  },

  methods: {
    actualizarNotificacion(notificacion, index, event) {
      var self = this

      // Si la palanca cambió a true
      if (event.target.checked == true) {
        NotificacionesRolSrv.activar(self.rol.id, notificacion.id).then(response => {
          self.refrescarNotificaciones()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo actualizar el permiso'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => { self.refrescarNotificaciones() })
      // Si la palanca cambió a false
      } else {
        NotificacionesRolSrv.desactivar(self.rol.id, notificacion.id).then(response => {
          self.refrescarNotificaciones()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo quitar el permiso'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => { self.refrescarNotificaciones() })
      }
    },

    cargarRol() {
      var self = this

      RolUsuariosSrv.rolJSON(self.rol.id).then(response => {
        self.rol = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el rol'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    refrescarNotificaciones() {
      var self = this

      iu.spinner.mostrar('#tabla-notificaciones')

      NotificacionesRolSrv.notificacionesJSON(self.rol.id).then(response => {
        var permisos = response.data
        self.notificaciones = permisos
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los permisos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-notificaciones')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-notificaciones')
  }
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>