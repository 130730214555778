<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover table-striped table-nowrap align-middle" id="tabla-permisos-hijas">
          <thead>
            <tr>
              <th style="width: 70px">Permiso</th>
              <th style="width: 70px">Tipo</th>
              <th style="width: 300px">Nombre de función</th>
              <th>Descripción de la función</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(permiso, index) in permisos" :key="`fm_${permiso.id_funcion}`">
              <td>
                <div class="form-check form-switch form-switch-md">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="'switch-hija-' + index"
                    :disabled="rol.generico"
                    @change="actualizarPermisoHija(permiso, index, $event)"
                    :checked="permiso.permiso == true"
                  />
                  <label
                    class="form-check-label"
                    :for="'switch-hija-' + index"
                  ></label>
                </div>
              </td>
              <td>
                <span
                  class="badge bg-gradient font-size-12 text-capitalize"
                  :class="{ 
                    'bg-success': permiso.tipo == 'accion',
                    'bg-info'   : permiso.tipo == 'vista',
                    'bg-warning': permiso.tipo == 'grupo',
                    'bg-primary': permiso.tipo == 'componente'
                  }"
                >
                  {{ permiso.tipo }}
                </span>
              </td>
              <td class="fw-semibold">
                {{ permiso.nombre }}
              </td>
              <td>
                {{ permiso.descripcion }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Vista para administración de permisos
 */
import PermisoSrv from '@/services/PermisoSrv.js'
import RolUsuariosSrv from '@/services/RolUsuariosSrv.js'
export default {
  name: 'PermisosHijas',
  props: [
    'idRol',
    'idBloque',
    'idFuncionMadre',
    'permisoMadre'
  ],
  data: function() {
    return {
      permisos: [],
      id_rol: null,
      id_bloque: null,
      id_funcion_madre: null,
      rol: { id: null, nombre: '' },
    }
  }, 

  watch: {
    permisoMadre(newVal, oldVal) {
      if(newVal == oldVal) return

      this.cargarRol()
      this.refrescarPermisosHijas()
    }
  },

  created() {
    var self = this

    // Carga del rol
    self.id_rol = self.idRol
    self.id_bloque = self.idBloque
    self.id_funcion_madre = self.idFuncionMadre

    // Carga de los permisos del rol
    self.cargarRol()
  },
  mounted: function() {
    var self = this
    iu.spinner.mostrar('#tabla-permisos-hijas')
    // Carga de los permisos del rol
    self.refrescarPermisosHijas()
  },

  methods: {
    actualizarPermisoHija(permiso, index, event) {
      var self = this

      // Si la palanca cambió a true
      if (event.target.checked == true) {
        let permisoAct = {
          id_rol: self.id_rol,
          id_funcion: permiso.id_funcion
        }

        PermisoSrv.agregar(permisoAct).then(response => {
          self.refrescarPermisosHijas()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo actualizar el permiso'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      // Si la palanca cambió a false
      } else {
        PermisoSrv.quitar(permiso.id_rol, permiso.id_funcion).then(response => {
          self.refrescarPermisosHijas()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo quitar el permiso'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      }
    },

    cargarRol() {
      var self = this

      RolUsuariosSrv.rolJSON(self.id_rol).then(response => {
        self.rol = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el rol'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    refrescarPermisosHijas() {
      var self = this

      iu.spinner.mostrar('#tabla-permisos-hijas')
      
      PermisoSrv.permisosJSON({
        id_rol: self.id_rol,
        id_bloque: self.id_bloque,
        id_funcion_madre: self.id_funcion_madre
      }).then(response => {
        self.permisos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los permisos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-permisos-hijas')
      })
    }
  }
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>