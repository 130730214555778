<template>
  <div class="modal fade" ref="mdlRoles" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h4 class="modal-title text-white">
            Roles de usuario
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Da click sobre el botón de "<strong>Asignar</strong>" para añadir 
            el nuevo rol al usuario asignado.
          </p>
          <table class="table table-hover align-middle">
            <tbody>
              <tr v-for="rol in roles" :key="rol.id">
                <td>{{rol.nombre}}</td>
                <td class="text-right">
                  <button class="btn btn-outline-success btn-sm"
                    @click="asignar(rol)">
                    <i class="mdi mdi-plus-thick"></i>
                    Asignar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RolUsuariosSrv from '@/services/RolUsuariosSrv.js'
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'MdlRoles',

  data() {
    return {
      roles: []
    }
  },

  created() {
    this.refrescarRoles()
  },
  methods: {
    asignar(rol) {
      let cpRol = Object.assign({}, rol)
      this.$emit('rol:seleccionado', cpRol)
    },

    mostrar() {
      var modal = new Modal(this.$refs.mdlRoles)
      modal.show()
    },

    refrescarRoles() {
      let self = this

      RolUsuariosSrv.rolesJSON().then(response => {
        self.roles = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los roles'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>