<template>
  <Layout tituloPagina="Calendario | Ajustes">
    <div class="row">
      <div class="col-lg-5 col-md-7 col-sm-8 col-12">
        <cmp-secciones seccion="ajustes"></cmp-secciones>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-7 col-sm-8 col-12">
        <div class="row">
          <div class="col-md-9 col-9 mb-3">
            <filtrador
              v-on:dato-actualizado="
                dato = $event,
                refrescarCalendarios()
              "
              :datoini="dato"
              placeholder="Dato del calendario"
            ></filtrador>
          </div>
          <div class="col-md-3 col-3 text-right mb-3">
            <router-link
              :to="{ name: 'nuevoAjusteCalendario' }"
              class="btn btn-success"
            >
              <span class="d-none d-sm-block">
                <i class="mdi mdi-plus-thick"></i>
                Nuevo
              </span>
              <span class="d-block d-sm-none">
                <i class="mdi mdi-plus-thick"></i>
              </span>
            </router-link>
          </div>
        </div>
        <br />
        <div class="card">
          <div class="card-body" style="min-height: 475px;">
            <div class="table-responsive" style="min-height: 475px;">
              <table class="table table-hover table-nowrap align-middle" id="tabla-eventos-calendario">
                <thead>
                  <tr>
                    <th style="width: 60px">Visible</th>
                    <th>Nombre</th>
                    <th style="width: 50px">Color</th>
                    <th style="width: 100px" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="calendario,index in calendarios" :key="calendario.id">
                    <td class="text-right fw-semibold">
                      <div class="form-check form-switch form-switch-md d-inline-block">
                        <input
                          :checked="calendario.visible"
                          @change="
                            calendario.visible = !calendario.visible,
                            actualizarAjustesCalendario(calendario.id, index, 'visible')
                          "
                          class="form-check-input"
                          type="checkbox"
                          :id="'switch-'+calendario.nombre"
                        />
                        <label
                          class="form-check-label"
                          :for="'switch-'+calendario.nombre"
                        ></label>
                      </div>
                    </td>
                    <td>
                      {{ calendario.nombre }}
                    </td>
                    <td>
                      <input
                        class="form-control form-control-color d-inline-block"
                        type="color"
                        v-model="calendario.color"
                        @change="actualizarAjustesCalendario(calendario.id, index, 'color')"
                      />
                    </td>
                    <td class="text-center">
                      <div class="dropdown">
                        <button
                          class="btn btn-light btn-sm dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="bx bx-dots-horizontal-rounded"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <button class="dropdown-item dark" @click="eliminarAjustes(calendario)">
                              <i class="mdi mdi-dip-switch text-dark"></i>
                              default
                            </button>
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: !calendario.generico ? 'edicionAjusteCalendario' : 'visorAjusteCalendario',
                                params: { id: calendario.id }
                              }"
                              class="dropdown-item"
                              :class="{
                                'warning': !calendario.generico,
                                'info': calendario.generico,
                              }"
                            >
                              <i 
                                class="mdi font-size-15"
                                :class="{
                                  'mdi-square-edit-outline text-warning': !calendario.generico,
                                  'mdi-eye text-info': calendario.generico
                                }"
                              ></i>
                              {{ !calendario.generico ? 'Editar' : 'Visor' }}
                            </router-link>
                          </li>
                          <li>
                            <button
                              v-on:click="preguntaEliminar(calendario)"
                              v-if="!calendario.generico"
                              class="dropdown-item danger"
                            >
                              <i class="mdi mdi-trash-can-outline font-size-15 text-danger"></i>
                              Eliminar
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="text-center">
          <paginador
            :pag="page"
            :totpag="totpag"
            :epp="epp"
            @cargar-pagina="cargarPagina"
            @epp-actualizado="
              epp = $event,
              refrescarCalendarios()
            "
          ></paginador>
        </div>
      </div>
      <div class="col-lg-6 col-md-7 col-sm-8 col-12">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Ajustes del calendario
            </h4>
            <div>
              <div class="dropdown d-inline-block">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="actualizarAjustes()"
                      v-if="!bandera_spinner && usuarioActEsAdmin"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      Actualizar
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      Actualizar
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="atras()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Hora de entrada</label>
                <input
                  type="time"
                  class="form-control"
                  ref="hora_entrada"
                  name="hora_entrada"
                  :disabled="!usuarioActEsAdmin"
                  :readonly="!usuarioActEsAdmin"
                  v-model="ajustes.hora_entrada"
                />
              </div>
              <div class="col-md-6">
                <label>Hora de salida</label>
                <input
                  type="time"
                  v-model="ajustes.hora_salida"
                  class="form-control"
                  ref="hora_salida"
                  name="hora_salida"
                  :disabled="!usuarioActEsAdmin"
                  :readonly="!usuarioActEsAdmin"
                />
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-12">
                <label>Dias laborales</label>
                <multiselect
                  v-model="ajustes.dias_laborales"
                  placeholder="Buscar dia laboral"
                  selectLabel="Presione enter para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione enter para eliminar"
                  label="dia"
                  track-by="id"
                  :options="dias_laborales"
                  :disabled="!usuarioActEsAdmin"
                  :multiple="true"
                  :taggable="false"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn-group">
            <button @click="atras()" class="btn btn-secondary">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              class="btn btn-success"
              @click="actualizarAjustes()"
              :disabled="bandera_spinner"
              v-if="usuarioActEsAdmin"
            >
              <i 
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import CmpSecciones from './CmpSecciones.vue'
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import CalendarioSrv from "@/services/CalendarioSrv.js"
import SistemaSrv from '@/services/SistemaSrv.js'
import Multiselect from 'vue-multiselect'
import Swal from 'sweetalert2'

import { todoGetters } from "@/state/helpers";

export default {
  name: 'AjustesCalendario',
  components: { 
    Layout,
    CmpSecciones,
    Multiselect,
    Filtrador,
    Paginador,
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.eventosCalendario.por_pagina') || 10),
      tc: 0, // Total de calendarios
      totpag: 0, // Total de páginas
      calendarios:[],

      ajustes: {
        hora_entrada: "07:00:00",
        hora_salida: "07:00:00",
        dias_laborales: [],
        
      },
      dias_laborales: [
        { id: 1, dia: 'Lunes' },
        { id: 2, dia: 'Martes' },
        { id: 3, dia: 'Miércoles' },
        { id: 4, dia: 'Jueves' },
        { id: 5, dia: 'Viernes' },
        { id: 6, dia: 'Sábado' },
        { id: 0, dia: 'Domingo' }
      ],
      bandera_spinner: false
    }
  },

  computed: {
    ...todoGetters,
    usuario() {
      return this.$store.state.todo.usuario
    },
    usuarioActEsAdmin() {
      return this.usuarioActualEsAdministrador()
    }
  },

  created() {
    var self = this

    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.page) this.page = parseInt(this.$route.query.page)
    if (this.$route.query.epp) this.epp = parseInt(this.$route.query.epp)

    self.cargarAjustes()
    self.refrescarCalendarios()
  },
  mounted() {
    iu.spinner.mostrar('#tabla-eventos-calendario')
  },

  methods: {
    actualizarAjustesCalendario: function(idCalendario, index, tipo = 'color') {
      var self = this,
          calendario = self.calendarios[index]
      
      let datos = {
        color: calendario.color,
        visible: calendario.visible,
        id_calendario: idCalendario,
      }

      CalendarioSrv.actualizarAjustes(idCalendario, datos).then(response => {
        iu.msg.success('Se actualizo correctamente el '+tipo+' del calendario')
        self.refrescarCalendarios()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el '+tipo+' del calendario'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    actualizarAjustes() {
      let self = this,
        ajustes = Object.assign({}, self.ajustes)

      self.bandera_spinner = true

      SistemaSrv.actualizar(ajustes).then(response => {
        iu.msg.success('Datos de facturación actualizados')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron actualizar los datos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarCalendarios({ page: n })
    },

    cargarAjustes() {
      let self = this
      let ajustesSolicitados = Object.keys(self.ajustes)

      SistemaSrv.ajustes(ajustesSolicitados).then(response => {
        let ajustes = response.data
        Object.assign(self.ajustes, ajustes)
        self.ajustes.dias_laborales = JSON.parse(ajustes.dias_laborales)
      })
    },

    eliminarAjustes: function(calendario) {
      var self = this 

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Valores predeterminados",
        html: `¿Estás seguro de regresar a los valores predeterminados del calendario <br><strong>${calendario.nombre}</strong>?`,
        icon: "info",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          CalendarioSrv.eliminarAjustes(calendario.id, self.usuario.id).then(response => {
            swal.fire("predeterminado!", "Se restauró los valores predeterminados correctamente", "success");
            self.refrescarCalendarios()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo restaurar los valores predeterminados del calendario'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    preguntaEliminar: function(calendario) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminación de calendario",
        html: `¿Estás seguro de eliminar el calendario <strong>${calendario.nombre}</strong>?`,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          CalendarioSrv.eliminar(calendario.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarCalendarios()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el calendario'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarCalendarios: function() {
      var self = this

      iu.spinner.mostrar('#tabla-eventos-calendario')

      this.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp
        }
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        dato: self.dato
      }

      CalendarioSrv.calendariosJSON(params).then(response => {
        localStorage.setItem('argusblack.eventosCalendario.por_pagina', self.epp)

        let page = response.data
        self.calendarios = page.data
        self.tc = page.total
        self.totpag = page.last_page

        self.calendarios.forEach(cal => {
          if(cal.usuario_ajustes_calendario){
            cal.color = cal.usuario_ajustes_calendario.color
            cal.visible = cal.usuario_ajustes_calendario.visible
          }
        })

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarCalendarios()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los calendarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-eventos-calendario')
      })
    }
  }
}
</script>

<style scoped>

</style>