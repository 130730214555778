<template>
  <Layout tituloPagina="Compras | Proveedores">
    <div class="row">
      <div class="col-lg-12">
        <cmp-secciones seccion="proveedores"></cmp-secciones>
      </div>
    </div>

    <br>
 
    <div class="row">
      <div class="col-lg-12 text-right">
        <router-link :to="{ name: 'nuevoProveedor' }" class="btn btn-success">
          <i class="fa fa-plus"></i>
          Agregar
        </router-link>
      </div>
    </div>

    <br />
    
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover align-middle">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>dirección</th>
                <th>RFC</th>
                <th style="width:250px">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  SYSCOM
                </td>
                <td>
                  Av. 20 de Noviembre #805 Col Centro de la ciudad de Chihuahua Estado de Chihuahua CP 31000
                </td>
                <td>
                  Sistemas y Servicios de Comunicación, S. A. de C. V.
                </td>
                <td>
                  <button class="btnInfo btn btn-default btn-xs" @click="detallesProveedor(10)">
                    <i class="fa fa-eye"></i> Detalles
                  </button>
                  <router-link
                    :to="{ name: 'edicionProveedor', params: { id:10 } }"
                    class="btnEditar btn btn-default btn-xs"
                  >
                    <i class="fa fa-edit"></i>
                    Editar
                  </router-link>
                  <button class="btnBorrar btn btn-default btn-xs" @click="eliminarProveedor(10)">
                    <i class="fa fa-ban"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarRequerimientos()
        "
      ></paginador>
    </div>
    <mdl-detalle-proveedor ref="mdlDetallesProveedor"/>
    <br>
  </Layout>
</template>

<script>
import CmpSecciones from '../CmpSecciones.vue'
import Paginador from '@/components/Paginador.vue'

import MdlDetalleProveedor from './MdlDetalleProveedor.vue'
import Layout from "@/views/layouts/main"


export default {
  components: {
    CmpSecciones, 
    Paginador,
    MdlDetalleProveedor,
    Layout
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: 10,
      tc: 0, // Total de clientes
      totpag: 0, // Total de páginas
    }
  },
  created() {
    
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      //self.refrescarRequerimientos({ page: n })
    },

    detallesProveedor: function(idProveedor) {
      var self = this

      self.$refs.mdlDetallesProveedor.mostrar(idProveedor)
    },

    eliminarProveedor: function(idProveedor) {
      swal({
        title: "¿Eliminar proveedor?",
        text: "¿Está seguro de eliminar el proveedor?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        closeOnConfirm: false,
        closeOnCancel: false
      },
      function(isConfirm) {
        if (isConfirm) {
          swal("Eliminado!", "Proveedor eliminado.", "success");
        } else {
          swal("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  }
}
</script>

<style></style>
