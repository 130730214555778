<template>
  <Layout tituloPagina="Sistema | Ajustes | Inventario">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Ajustes de inventario
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  Actualizar
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  Actualizar
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="$router.go(-1)"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-2">
            <label>Periodo de inventario</label>
            <select class="form-select" v-model="tipo_periodo">
              <option :value="1">Mensual</option>
              <option :value="2">Bimestral</option>
              <option :value="3">Trimestral</option>
              <option :value="4">Cuatrimestral</option>
              <option :value="5">Semestral</option>
              <option :value="6">Anual</option>
              <option :value="7">Personalizado</option>
            </select>
          </div>
          <div class="col-md-2" v-show="tipo_periodo == 7">
            <label>Tipo de periodo</label>
            <select class="form-select" v-model="meses_periodo">
              <option :value="5">5 meses</option>
              <option :value="7">7 meses</option>
              <option :value="8">8 meses</option>
              <option :value="9">9 meses</option>
              <option :value="10">10 meses</option>
              <option :value="11">11 meses</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Periodo</label>
            <div class="input-group">
              <span class="input-group-text">
                Inicio
              </span>
              <select class="form-select" v-model="periodo_inicial">
                <option v-for="n in 31" :key="`perido_inicial-${n}`" :value="n">{{n}}</option>
              </select>
              <span class="input-group-text">
                Fin
              </span>
              <select class="form-select" v-model="periodo_final">
                <option v-for="h in 31" :key="`perido_final-${h}`" :value="h">{{h}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 text-right mt-4">
            <button class="btn btn-outline-success" @click="agregarPeriodo()">
              <i class="mdi mdi-plus-thick"></i>
              Agregar
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-7">
            <div class="table-responsive">
              <table class="tblRouters table table-hover table-nowrap align-middle">
                <thead>
                  <tr>
                    <th>Tipo de periodo</th>
                    <th>Inicial del periodo</th>
                    <th>Fin del periodo</th>
                    <th class="text-center">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(periodo,index) in ajustes.periodos_inventario" :key="`periodo-${index+1}`">
                    <td>
                      {{periodo.meses_periodo == 1 ? 'Mensual':''}}
                      {{periodo.meses_periodo == 2 ? 'Bimestral':''}}
                      {{periodo.meses_periodo == 3 ? 'Trimestral':''}}
                      {{periodo.meses_periodo == 4 ? 'Cuatrimestral':''}}
                      {{periodo.meses_periodo == 6 ? 'Semestral':''}}
                      {{periodo.meses_periodo == 12 ? 'Anual':''}}
                      {{periodo.meses_periodo != 1 && periodo.meses_periodo != 2 && periodo.meses_periodo != 3 && periodo.meses_periodo != 4 && periodo.meses_periodo != 6 && periodo.meses_periodo != 12 ? periodo.meses_periodo+' meses':''}}
                    </td>
                    <td>{{periodo.periodo_inicial}}</td>
                    <td>{{periodo.periodo_final}}</td>
                    <td class="text-right">
                      <button
                        class="btn btn-danger btn-sm"
                        @click="eliminarPeriodo(index)"
                      >
                        <i class="fa fa-minus"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary"
          @click="$router.go(-1)">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button class="btn btn-success" @click="actualizar()" :disabled="bandera_spinner">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import SistemaSrv from '@/services/SistemaSrv.js'
export default {
  name: 'Inventario',
  components: { Layout },
  data() {
    return {
      ajustes: {
        periodos_inventario: []
      },
      tipo_periodo:1,
      meses_periodo: 5,
      periodo_inicial: 1,
      periodo_final:5,
      bandera_spinner: false
    }
  },

  created() {
    this.refrescarAjustes()
  },

  methods:{
    actualizar() {
      let self = this,
        ajustes = Object.assign({}, self.ajustes)

      self.bandera_spinner = true

      SistemaSrv.actualizar(ajustes).then(response => {
        iu.msg.success('Datos actualizados')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron actualizar los datos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    agregarPeriodo: function(){
      var self = this

      if(self.ajustes.periodos_inventario.length == 1) {
        iu.msg.warning('No se puede registar dos periodos a la vez si desea registrar otro periodo elimine el anterior')
        return
      }

      var nuevoPeriodo = {
        id: null,
        periodo_inicial: self.periodo_inicial,
        periodo_final: self.periodo_final
      }

      switch (self.tipo_periodo) {
        case 1:
          Object.assign(nuevoPeriodo,{
            meses_periodo:1
          })
        break;
        case 2:
          Object.assign(nuevoPeriodo,{
            meses_periodo:2
          })
        break;
        case 3:
          Object.assign(nuevoPeriodo,{
            meses_periodo:3
          })
        break;
        case 4:
          Object.assign(nuevoPeriodo,{
            meses_periodo:4
          })
        break;
        case 5:
          Object.assign(nuevoPeriodo,{
            meses_periodo:6
          })
        break;
        case 6:
          Object.assign(nuevoPeriodo,{
            meses_periodo:12
          })
        break;
        case 7:
          Object.assign(nuevoPeriodo,{
            meses_periodo:self.meses_periodo
          })
        break;
      }

      self.ajustes.periodos_inventario.push(nuevoPeriodo)
      
      self.tipo_periodo = 1
      self.meses_periodo = 5
      self.periodo_inicial = 1
      self.periodo_final = 5
      self.$emit('actualizar-ajustes')
    },
    eliminarPeriodo: function(index) {
      var self = this
      self.ajustes.eliminar_periodos_inventario = true

      self.ajustes.periodos_inventario.splice(index, 1)

      self.$emit('actualizar-ajustes')
    },

    refrescarAjustes() {
      let self = this

      SistemaSrv.ajustes(['periodos_inventario']).then(response => {
        let ajustes = response.data
        Object.assign(self.ajustes, ajustes)
      })
    }
  }
}
</script>

<style scoped>

</style>