<template>
  <div class="modal fade" ref="mdlOpcionesAlias" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header text-white bg-info">
          <div class="modal-title text-center">
            <strong style="font-size:30px">Opciones del campo alias "{{campo.campo}}"</strong>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 text-right">
              <div class="input-group">
                <span class="input-group-text">
                  Opción
                </span>
                <input
                  class="form-control"
                  name="opcion"
                  ref="opcion"
                  v-model="opcion"
                  placeholder="Nueva opción"
                  @keyup.enter="agregarOpcion()"
                />
                <button class="btn btn-outline-success" @click="agregarOpcion()">
                  <i class="mdi mdi-plus-thick"></i>
                  Nueva opción
                </button>
              </div>
            </div>
          </div>

          <br>

          <div class="table-responsive">
            <table class="table table-hover table-nowrap align-middle">
              <thead>
                <tr>
                  <th style="width: 30px">No.</th>
                  <th>Opción</th>
                  <th class="text-center" style="width: 100px">Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(opcion, index) in campo.opciones" :key="`opcion-${index}`">
                  <th>{{index+1}}</th>
                  <td>{{ opcion.nombre }}</td>
                  <td class="text-center">
                    <button class="btn btn-outline-danger btn-sm" @click="eliminarOpcion(index)">
                      <i class="mdi mdi-trash-can-outline"></i>
                      Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button type="button" class="btn btn-success" @click="actualizarCampoAlias()" :disabled="bandera_spinner">
            <i 
              class="mdi"
              :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
            ></i>
            Guardar opciones
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
import VistasCampos from '@/services/VistasCampos.js'
export default {
  name: 'MdlOpciones',

  data() {
    return {
      id_campo: null,
      campo:{
        id: null,
        id_vista: null,
        campo: '',
        alias: '',
        visible: true,
        opciones: []
      },
      opcion:'',
      bandera_spinner: false
    }
  },

  methods: {
    actualizarCampoAlias: function() {
      var self = this

      self.bandera_spinner = true
      
      var campo = {
        id: self.campo.id,
        id_vista: self.campo.id_vista,
        alias: self.campo.alias,
        campo: self.campo.campo,
        opciones: self.campo.opciones,
        visible: self.campo.visible
      }

      if(Array.isArray(campo.opciones)){
        if(campo.opciones.length == 0) campo.opciones = null
      }

      VistasCampos.actualizar(campo).then(response => {
        iu.msg.success('Se actualiza correctamente las opciones del alias')
        self.refrescarAlias()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar las opciones de los alias correctamente'
        }
        iu.msg.error(mensaje)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    agregarOpcion: function() {
      var self = this

      if(self.opcion == ''){
        iu.msg.warning('Ingrese una opción para registrar.')
        self.$refs.opcion.focus()
        return
      }

      if(!Array.isArray(self.campo.opciones)) self.campo.opciones = []

      let opcion_a_validar = self.campo.opciones.find(opc => {
        return opc.nombre == self.opcion
      })
      
      if(opcion_a_validar != null) {
        iu.msg.warning('No se puede registrar la misma opción dos veces')
        self.$refs.opcion.focus()
        return
      }

      self.campo.opciones.push({
        nombre: self.opcion
      })
      self.opcion = ''
    },

    cargarCampo: function() {
      var self = this

      VistasCampos.vistaCampoJSON(self.id_campo).then(response => {
        self.campo = response.data

        self.campo.opciones = JSON.parse(self.campo.opciones)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el alias'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    eliminarOpcion: function(index) {
      var self = this

      self.campo.opciones.splice(index, 1)
    },

    guardarCampo: function (campo) {
      var self = this
      var nuevo_campo = {
        id_vista: 36,
        id: null,
        campo: campo.campo,
        alias: campo.alias,
        visible: true,
        opciones: ''
      }

      VistasCampos.guardar(nuevo_campo).then(response => {
        self.id_campo = response.data
        self.refrescarAlias()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el alias'
        }
        iu.msg.error(mensaje)
      })
    },

    mostrar(campo) {
      var self = this
      self.id_campo = campo.id
      
      if(self.id_campo != null) self.cargarCampo()
      else self.guardarCampo(campo)

      var modal = new Modal(this.$refs.mdlOpcionesAlias)
      modal.show()
    },

    refrescarAlias:function(){
      var self = this

      self.$emit('refrescar-alias')
      self.cargarCampo()
    },
  },
}
</script>

<style scoped>

</style>