<template>
  <Layout :tituloPagina="`Hotspot | Fichas | ${modo=='tipos' ? 'Por tipo: '+tipo.descripcion : 'Por vendedor: '+vendedor.nombre_vendedor}`">
    <div class="row">
      <div class="col-lg-4">
        <cmp-secciones seccion="fichas"></cmp-secciones>
      </div>
    </div>
    <div class="row mb-4">
      <div class="offset-md-8 col-md-4 text-right">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{tf}} fichas en total
        </span>
      </div>
    </div>
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Listado fichas
          <small class="font-size-10">
            Aquí puedes ver las fichas registradas {{modo=='tipos' ? 'por tipo: ':'por Vendedor: '}}
            <strong>
              {{modo=='tipos' ?tipo.descripcion : vendedor.nombre_vendedor}}
            </strong>
          </small>
        </h4>
        <div>
          <a href="#" onclick="return false;" @click="atras()">
            <i class="mdi mdi-close text-dark"></i>
          </a>
        </div>
      </div>
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive" style="min-height: 475px;">
          <table class="tblRouters table table-hover table-nowrap align-middle">
            <thead>
              <tr>
                <th style="width: 30px">
                  <input
                    type="checkbox"
                    v-model="seleccionar_todo"
                    @change="seleccionarFichas()"
                    :title="!seleccionar_todo?'Seleccionar todas las fichas':'Deseleccionar todas las fichas'"
                  >
                </th>
                <th>ID</th>
                <th>Tipo de autenticación</th>
                <th v-if="modo == 'tipos'" style="width: 200px">Tipo de ficha</th>
                <th v-if="modo == 'vendedores'" style="width: 200px">Vendedor</th>
                <th>Precio</th>
                <th>Estado</th>
                <th>Fecha de creación</th>
                <th>Tiempo restante</th>
                <th>Fecha de expiración</th>
                <th style="width: 80px" class="text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ficha in fichas" :key="ficha.id">
                <td>
                  <input
                    type="checkbox"
                    v-model="ficha.selecionar"
                    ref="seleccionarFicha"
                    @change="fichaSelec = !fichaSelec"
                    :disabled="ficha.mt_venta != null"
                    :title="!ficha.selecionar?'Seleccionar ficha':'Deseleccionar ficha'"
                  >
                </td>
                <td class="fw-semibold">{{ ficha.id }}</td>
                <td>
                  <span :class="ficha.clave != null ? 'text-success' : 'text-warning'">
                    <strong>
                      {{ficha.clave != null ? 'Usuario y contraseña' : 'PIN'}}
                    </strong>
                  </span>
                </td>
                <td v-if="modo == 'tipos'">{{ tipo.descripcion }}</td>
                <td v-if="modo == 'vendedores'">{{ vendedor.nombre_vendedor }}</td>
                <td>
                  <span class="badge bg-soft-dark font-size-12">
                    {{ formatoMoneda(ficha.precio) }}
                  </span>
                </td>
                <td>
                  <span
                    class="badge bg-soft-dark font-size-12"
                    v-if="ficha.mt_venta == null"
                  >
                    Nuevo
                  </span>
                  <span
                    class=""
                    v-if="ficha.mt_venta != null"
                  >
                    Vendido el {{ ficha.mt_venta != null ? formatoFecha(ficha.mt_venta) : '' }}&nbsp;
                  </span>
                  <span
                    class="badge bg-success bg-gradient font-size-12"
                    v-if="
                      ficha.mt_venta != null &&
                      ficha.vencida == false &&
                      ficha.mt_login != null &&
                      ficha.mt_logout == null
                    "
                  >
                    En uso
                  </span>
                  <span
                    class="badge bg-warning bg-gradient font-size-12"
                    v-if="
                      ficha.mt_venta != null &&
                      ficha.vencida == false &&
                      ficha.mt_login != null &&
                      ficha.mt_logout != null
                    "
                  >
                    Pausada 
                  </span>
                  <span
                    class="badge bg-primary bg-gradient font-size-12"
                    v-if="ficha.vencida == true"
                  >
                    Finalizada 
                  </span>
                </td>
                <td>
                  {{ formatoFecha(ficha.created_at) }}
                </td>
                 <td>
                  {{ ficha.tiempo == 0 ? 'No aplica' : calcularTiempoFecha(ficha.mt_inicio, ficha.tiempo) }}
                </td>
                <td>
                  {{ ficha.mt_max_validez != null ? formatoFecha(ficha.mt_max_validez) : 'No aplica' }}
                </td>
                <td class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li>
                        <button
                          class="dropdown-item warning"
                          @click="detallesFicha(ficha.id)"
                        >
                          <i class="mdi mdi-eye text-warning"></i>
                          Detalle
                        </button>
                      </li>
                      <li>
                        <button
                          class="dropdown-item primary"
                          @click="finalizarFicha(ficha.id)"
                          :disabled="ficha.mt_inicio == null || ficha.vencida == 1"
                        >
                          <i class="fa fa-ban text-primary"></i>
                          Finalizar
                        </button>
                      </li>
                      <li>
                        <button
                          class="dropdown-item info"
                          @click="imprimirFicha(ficha)"
                          :disabled="ficha.mt_venta != null"
                        >
                          <i class="mdi mdi-printer text-info"></i>
                          Imprimir
                        </button>
                      </li>
                      <li>
                        <button
                          class="dropdown-item danger"
                          @click="eliminarFicha(ficha.id)"
                          :disabled="ficha.mt_venta != null"
                        >
                          <i class="mdi mdi-trash-can-outline text-danger"></i>
                          Eliminar
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-lg-12" v-show="mostrarEliminarFichasBLoque > 0">
            <button
              class="btn btn-outline-info btn-sm"
              @click="imprimirFichas()"
              :title="mostrarEliminarFichasBLoque == 1 ? 'Imprimir ficha selecionada' : 'Imprimir fichas seleccionadas'"
            >
              <i class="mdi mdi-printer"></i>
              Imprimir
            </button>
            <button
              @click="eliminarBLoqueFichas()"
              class="btn btn-outline-danger btn-sm"
              :title="mostrarEliminarFichasBLoque == 1 ? 'Eliminar ficha selecionada' : 'Eliminar fichas seleccionadas'"
            >
              <i class="mdi mdi-trash-can-outline"></i>
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="offset-md-4 col-md-4 text-center">
        <paginador
          :pag="page"
          :totpag="totpag"
          :epp="epp"
          @cargar-pagina="cargarPagina"
          @epp-actualizado="
            epp = $event,
            refrescarFichas()
          "
        ></paginador>
      </div>
      <div class="col-md-4 text-right">
        <button class="btn btn-secondary" @click="atras()">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
      </div>
    </div>
    <mdl-ficha-detalles
      ref="mdlDetalles"
    ></mdl-ficha-detalles>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import { APIHOTS as API } from '@/API.js'
import CmpSecciones from './CmpSecciones.vue'
import Paginador from '@/components/Paginador.vue'
import accounting from 'accounting/accounting.js'

import FichasSrv from '@/services/hotspot/FichasSrv.js'
import vendedorSrv from '@/services/hotspot/VendedorSrv.js'
import FichaTipoSrv from '@/services/hotspot/FichaTipoSrv.js'

import MdlFichaDetalles from './MdlFichaDetalles.vue'

import { todoGetters } from "@/state/helpers";

import Swal from 'sweetalert2'

import moment from 'moment'
export default {
  name: 'ListadoFichasPorTiposOVendedores',
  components: {
    CmpSecciones,
    Paginador,
    MdlFichaDetalles,
    Layout
  },
  data() {
    return {
      modo: 'tipos',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoFichasPorTiposOVendedores.por_pagina') || 10),
      tf: 0, // Total de fichas
      totpag: 0, // Total de páginas
      token: localStorage.getItem('argusblack.token'),
      fecha_inicial: '',
      fecha_final:'',
      seleccionar_todo: false,
      fichaSelec: false,
      por_fecha: false,
      tipo_autenticacion: null,

      fichas:[],
      tipo: {},
      vendedor:{}
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created : function () {
    var self = this

    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp) self.epp = parseInt(self.$route.query.epp)
    if (self.$route.query.fecha_inicial) self.fecha_inicial = self.$route.query.fecha_inicial
    if (self.$route.query.fecha_final) self.fecha_final = self.$route.query.fecha_final
    if (self.$route.query.por_fecha) self.por_fecha = self.$route.query.por_fecha == 1 ? true: false
    if (self.$route.params.por_fecha) self.por_fecha = self.$route.params.por_fecha == 1 ? true: false
    if (self.$route.query.tipo_autenticacion) self.tipo_autenticacion = self.$route.query.tipo_autenticacion
    if (self.$route.params.tipo_autenticacion) self.tipo_autenticacion = self.$route.params.tipo_autenticacion
    if (self.$route.params.fecha_inicial) self.fecha_inicial = self.$route.params.fecha_inicial
    if (self.$route.params.fecha_final) self.fecha_final = self.$route.params.fecha_final

    // Determinación del modo
    if (self.$route.path.indexOf('tipos') != -1) self.modo = 'tipos'
    else self.modo = 'vendedores'

    if(self.modo == 'tipos') self.cargarTipo()
    else self.cargarVendedor()

    self.refrescarFichas()
  },

  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    calcularTiempoFecha: function (mt_inicio, tiempo) {
      var self = this

      if(mt_inicio == null ){
        return self.convertidorSegundos_a_DHM(tiempo)
      }

      var fechaInicio = new Date(mt_inicio).getTime()
      var fechaActual = new Date().getTime()
      var fecha = ((fechaInicio / 1000) + tiempo) - (fechaActual / 1000)
      
      return self.convertidorSegundos_a_DHM(fecha)
    },

    convertidorSegundos_a_DHM(segundos) {
      if(segundos < 0 ) return 'finalizada'

      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return dias + ' dias, ' + horas + ' horas, ' + minutos + ' minutos' 
    },

    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarFichas({ page: n })
    },

    cargarTipo(){
      var self = this,
        idTipo = self.$route.params.id

      FichaTipoSrv.fichaTipoJSON(idTipo).then(response => {
        self.tipo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el tipo de ficha'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarVendedor: function() {
      var self = this,
        idVendedor = self.$route.params.id

      vendedorSrv.vendedorJSON(idVendedor).then(response => {
        self.vendedor = response.data

        self.vendedor.nombre_vendedor = self.vendedor.nombre+' '+(self.vendedor.apellido_paterno?self.vendedor.apellido_paterno:'')+' '+(self.vendedor.apellido_materno?self.vendedor.apellido_materno:'')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el vendedor'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    detallesFicha: function(idFicha) {
      this.$refs.mdlDetalles.mostrar(idFicha)
    },

    eliminarFicha: function(idFicha) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "¿Eliminar la ficha?",
        html: "¿Está seguro de eliminar el ficha con el ID <br><b>"+idFicha+"</b>?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FichasSrv.eliminarFICHA(idFicha).then(response => {
            swal.fire("Eliminado!", "ficha con el ID "+idFicha+" eliminado.", "success");
            self.refrescarFichas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo eliminar la ficha con el ID "+idFicha
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    eliminarBLoqueFichas: function() {
      var self = this, idsFichas=[]

      var fichasSelecionadas = self.fichas.filter(ficha => ficha.selecionar == true)

      if(fichasSelecionadas.length == 0) {
        iu.msg.warning('No se han seleccionado fichas para eliminar')
        return
      }

      fichasSelecionadas.forEach(fichaSec => {
        idsFichas.push(fichaSec.id)
      })

      var params = {
        ids_fichas: idsFichas
      }

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "¿Eliminar las fichas selecionadas?",
        html: "¿Está seguro de eliminar las fichas seleccionadas?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FichasSrv.eliminarFICHAS(params).then(response => {
            swal.fire("Eliminado!", "¡Las fichas seleccionadas se han eliminado correctamente!.", "success");
            self.refrescarFichas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se han podido eliminar las fichas seleccionadas'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    finalizarFicha: function(idFicha) {
      var self = this

      swal({
        title: "¿Finalizar ficha?",
        text: "¿Está seguro de finalizar la ficha con el ID <br><b>"+idFicha+"</b>?",
        type: "warning",
        html: true,
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        closeOnConfirm: false,
        closeOnCancel: false
      },
      function(isConfirm) {
        if (isConfirm) {
          FichasSrv.finalizarFICHA(idFicha).then(response => {
            swal("Finalizado!", "ficha con el ID "+idFicha+" finalizado.", "success");
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo finalizar la ficha con el ID "+idFicha
            }
            swal("Error", mensaje, "error");
            console.log(error)
          }).finally(firn => {
            self.refrescarFichas()
          })
        } else {
          swal("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },

    formatoFechaFicha(mt){
      return moment(mt, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },

    imprimirFichas: function(){
      var self = this, idsFichas = []

      if(!self.tienePermiso('imprimirFichasHotspot','vista')) {
        iu.msg.warning(
          `No tienes permiso para acceder a <strong style="color: #e65853">imprimirFichasHotspot</strong>,
          revisa los permisos de rol con tu administrador de sistema`)
        setTimeout(() => {
          self.$router.replace({
            name: 'inicio'
          })
        }, 50)
        return
      }

      var fichasSelecionadas = self.fichas.filter(ficha => ficha.selecionar == true)

      if(fichasSelecionadas.length == 0) {
        iu.msg.warning('No se han seleccionado fichas para eliminar')
        return
      }

      fichasSelecionadas.forEach(fichaSec => {
        idsFichas.push(fichaSec.id)
      })

      let rutaDestino = this.$router.resolve({name: 'imprimirFichasHotspot', query: { ids_fichas: idsFichas }});
      window.open(rutaDestino.href, '_blank');
    },

    imprimirFicha: function(ficha){
      var self = this, idsFichas = []

      if(!self.tienePermiso('imprimirFichasHotspot','vista')) {
        iu.msg.warning(
          `No tienes permiso para acceder a <strong style="color: #e65853">imprimirFichasHotspot</strong>,
          revisa los permisos de rol con tu administrador de sistema`)
        setTimeout(() => {
          self.$router.replace({
            name: 'inicio'
          })
        }, 50)
        return
      }

      idsFichas.push(ficha.id)

      let rutaDestino = this.$router.resolve({name: 'imprimirFichasHotspot', query: { ids_fichas: idsFichas }});
      window.open(rutaDestino.href, '_blank');
    },

    refrescarFichas: function(){
      var self = this

      self.fichas = []
      
      let query = {
        page: self.page,
        epp: self.epp,
        por_fecha: self.por_fecha,
        tipo_autenticacion: self.tipo_autenticacion
      }

      if(self.por_fecha){
        Object.assign(query,{
          fecha_inicial: self.fecha_inicial,
          fecha_final: self.fecha_final
        })
      }

      self.$router.replace({
        query: query
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        tipo_autenticacion: self.tipo_autenticacion
      }

      if(self.por_fecha){
        Object.assign(params,{
          fecha_inicial:self.fecha_inicial,
          fecha_final: self.fecha_final
        })
      }

      if(self.modo == 'tipos'){
        Object.assign(params, {
            id_tipo: self.$route.params.id
        })
      } else {
        Object.assign(params, {
            id_vendedor: self.$route.params.id
        })
      }

      FichasSrv.fichasJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoFichasPorTiposOVendedores.por_pagina', self.epp)
        let page = response.data
        self.fichas = page.data
        self.tf = page.total
        self.totpag = page.last_page

        self.fichas.forEach(ficha=> {
          ficha.selecionar = false
        })

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarFichas()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las fichas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    seleccionarFichas: function() {
      var self = this

      self.fichas.forEach(ficha => {
        if(self.seleccionar_todo && ficha.mt_venta == null) ficha.selecionar = true
        else ficha.selecionar = false
      })
    }
  },
  computed: {
    ...todoGetters,
    mostrarEliminarFichasBLoque () {
      var self = this, fichas = []

      if(self.seleccionar_todo) fichas = self.fichas.filter(ficha => ficha.selecionar == true)
      
      if(self.fichaSelec) fichas = self.fichas.filter(ficha => ficha.selecionar == true)
      else fichas = self.fichas.filter(ficha => ficha.selecionar == true)

      return fichas.length
    }
  }
}
</script>