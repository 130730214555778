<template>
  <Layout tituloPagina="Pagos | Selección de cliente">
    <div class="row">
      <div class="col-md-2 col-xs-4 col-3 mb-3">
        <router-link :to="{ name: 'resumenDia' }" class="btn btn-secondary">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-clipboard-list-outline"></i>
            Resumen
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-clipboard-list-outline"></i>
          </span>
        </router-link>
      </div>
      <div class="col-md-8 col-xs-8 col-9">
        <filtrador
          v-on:dato-actualizado="
            dato_cliente = $event,
            refrescarClientes()
          "
          :datoini="dato_cliente"
          placeholder="Datos del cliente"
        ></filtrador>
      </div>
    </div>

    <br />

    <div class="card">
      <div class="card-body">
        <div class="contenedorTblClientes table-responsive">
          <table class="tblClientes table table-hover table-striped table-nowrap align-middle" id="tabla-inicio-pago">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Dirección</th>
                <th style="width: 150px" class="text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cliente in clientes" :key="cliente.id">
                <td class="fw-semibold">{{ cliente.id }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'tableroCliente',
                      params: { id_cliente: cliente.id }
                    }"
                  >
                    {{ cliente.nombre }}
                    {{ cliente.apellido_paterno }}
                    {{ cliente.apellido_materno }}
                  </router-link>
                </td>
                <td>
                  <span class="fw-semibold">{{cargarAliasCampo('Calle')}}</span>: {{ cliente.calle }} <br />
                  <span class="fw-semibold">{{cargarAliasCampo('Colonia')}}</span>: {{ cliente.colonia }} <br />
                  <span class="fw-semibold">{{cargarAliasCampo('Delegación o Municipio')}}</span>: {{ cliente.municipio }} <br />
                  <span class="fw-semibold">{{cargarAliasCampo('Estado de residencia')}}</span>: {{ cliente.estado_residencia }} <br />
                </td>
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'facturasPendientesPago',
                      params: { id_cliente: cliente.id }
                    }"
                    class="btn btn-success btn-sm"
                  >
                    <i class="mdi mdi-check-bold"></i>
                    Seleccionar
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="totPag"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarClientes()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import ClienteSrv from '@/services/ClienteSrv.js'
import { todoGetters } from "@/state/helpers"

export default {
  name: 'InicioPagos',
  components: { Layout, Paginador, Filtrador },
  data() {
    return {
      baseUrl: window.location.origin, 
      dato_cliente: '',
      por_pagina: parseInt(localStorage.getItem('argusblack.inicioPagos.por_pagina') || 10),
      pagina: 1,
      clientes: [],
      total_clientes: 0,
      todos: false,
      ultima_pagina: 1,
      tc: 0,
      totPag: 0
    }
  },

  created: function() {
    var self = this

    // Carga de los querys
    if (this.$route.query.dato_cliente) this.dato_cliente = this.$route.query.dato_cliente
    if (this.$route.query.pagina) this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina) this.por_pagina = parseInt(this.$route.query.por_pagina)

    if (this.dato_cliente != null && this.dato_cliente != '') {
      this.refrescarClientes()
    }
  },
  computed: {
    ...todoGetters
  },

  methods: {
    refrescarClientes: function(config) {
      var self = this

      iu.spinner.mostrar('#tabla-inicio-pago')

      self.$router.replace({
        query: {
          dato_cliente: self.dato_cliente,
          pagina: self.pagina,
          por_pagina: self.por_pagina
        }
      }).catch(error => {})

      if (self.dato_cliente == '') {
        self.clientes = []
        self.totPag = 0
        iu.spinner.ocultar('#tabla-inicio-pago')
        return
      }

      config = config || {}
      if (config.pagina == undefined) config.pagina = self.pagina
      if (config.por_pagina == undefined) config.por_pagina = self.por_pagina
      if (config.dato_cliente == undefined && self.dato_cliente != '') config.dato_cliente = self.dato_cliente
      if (config.activos == undefined && self.listado == 'activos') config.activos = true

      config.cfg_cliente = {
        id: true,
        id_cliente: true,
        nombre: true,
        apellido_paterno: true,
        apellido_materno: true,
        calle: true,
        colonia: true,
        municipio: true,
        estado_residencia: true
      }

      ClienteSrv.clientesJSON(config).then(response => {
        localStorage.setItem('argusblack.inicioPagos.por_pagina',self.por_pagina)
        let res = response.data
        self.clientes = res.clientes
        self.tc = res.total
        self.totPag = res.ultima_pagina

        if (self.totPag < self.pagina) {
          self.pagina = self.totPag
          self.refrescarClientes()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los clientes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        iu.spinner.ocultar('#tabla-inicio-pago')
      })
    },

    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.refrescarClientes({ pagina: n })
    }
  },
}
</script>

<style scoped>
.contenedorTblClientes {
  min-height: 475px;
}

table thead tr th:first-child,
th:last-child {
  width: 60px;
}
</style>