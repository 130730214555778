<template>
  <Layout tituloPagina="Hotspot | Layouts | Layout fichas">
    <div class="card">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
        <li class="nav-item">
          <router-link :to="{ name:'portalCautivoHotspot'}" class="nav-link">
            <i class="bx bx-layout font-size-20"></i>
            <span class="d-none d-sm-block">
              Layout de portal cautivo
            </span>
          </router-link>
        </li>
        <li class="nav-item">
          <a
            class="nav-link active"
            data-bs-toggle="tab"
            href="#fichas" role="tab"
          >
            <i class="bx bx-receipt font-size-20"></i>
            <span class="d-none d-sm-block">
              Layout de fichas
            </span>
          </a>
        </li>
        <li class="nav-item"></li>
        <li class="nav-item"></li>
        <li class="nav-item"></li>
      </ul>
      <!-- Tab content -->
      <div class="tab-content p-4">
        <div class="tab-pane active" id="fichas" role="tabpanel">
          <div class="row">
            <div class="offset-md-2 col-md-8 col-9 mb-3">
              <filtrador
                v-on:dato-actualizado="
                  dato = $event,
                  refrescarFichasLayouts()
                "
                :datoini="dato"
                placeholder="Dato del layout ficha"
              ></filtrador>
            </div>
            <div class="col-md-2 col-3 text-right">
              <router-link :to="{ name: 'nuevoFichasLayoutHotspot' }" class="btn btn-success">
                <span class="d-none d-sm-block">
                  <i class="mdi mdi-plus-thick"></i>
                  Nuevo layout ficha
                </span>
                <span class="d-block d-sm-none">
                  <i class="mdi mdi-plus-thick"></i>
                </span>
              </router-link>
            </div>
          </div>
          <br>
          <div class="row" style="min-height: 475px;">
            <div class="col-lg-12">
              <div class="table-responsive">
                <table class="table table-hover table-nowrap align-middle" id="tabla-layout-fichas">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nombre</th>
                      <th>Fecha de creación</th>
                      <th>Ultima edición</th>
                      <th>Tipo de ficha</th>
                      <th style="width:200px" class="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ficha,index) in fichas_layouts" :key="`ficha-layout-${index+1}`">
                      <td class="fw-semibold">
                        {{ficha.id}}
                      </td>
                      <td>
                        {{ficha.nombre}}
                      </td>
                      <td>
                        {{formatoFecha(ficha.created_at)}}
                      </td>
                      <td>
                        {{formatoFecha(ficha.updated_at)}}
                      </td>
                      <td>
                        <span
                          :class="{
                            'text-success' : ficha.tipo_autenticacion == 2,
                            'text-info' : ficha.tipo_autenticacion == 1
                          }"
                        >
                          <strong>
                            {{ficha.tipo_autenticacion == 1 ? 'Solo PIN' : 'Usuario y contraseña'}}
                          </strong>
                        </span>
                      </td>
                      <td class="text-center">
                        <router-link
                          :to="{ name: 'edicionFichasLayoutHotspot', params: { id: ficha.id } }"
                          class="btn btn-warning btn-sm"
                        >
                          <i class="mdi mdi-content-save-edit-outline"></i>
                          Editar
                        </router-link>
                        <button class="btn btn-danger btn-sm" @click="preguntaBorrarFicha(ficha)">
                          <i class="mdi mdi-trash-can-outline"></i>
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="text-center">
            <paginador
              :pag="page"
              :totpag="totpag"
              :epp="epp"
              @cargar-pagina="cargarPagina"
              @epp-actualizado="
                epp = $event,
                refrescarFichasLayouts()
              "
            ></paginador>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import FichasLayoutSrv from '@/services/hotspot/FichasLayoutSrv.js'
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import Layout from "@/views/layouts/main"
import Swal from 'sweetalert2'
import moment from 'moment'
export default {
  name: 'ListadoLayoutFichasHotspot',
  components: {
    Layout,
    Filtrador,
    Paginador
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoLayoutFichasHotspot.por_pagina') || 10),
      tc: 0, // Total de portales cautivos
      totpag: 0, // Total de páginas
      fichas_layouts: []
    }
  },

  created() {
    var self = this

    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp)  self.epp  = parseInt(self.$route.query.epp)

    self.refrescarFichasLayouts()
  },

  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarFichasLayouts({ page: n })
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },

    refrescarFichasLayouts: function() {
      var self = this

      iu.spinner.mostrar('#tabla-layout-fichas')

      this.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp
        }
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        dato: self.dato
      }
      
      FichasLayoutSrv.fichasLayoutsJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoLayoutFichasHotspot.por_pagina', self.epp)
        let page = response.data
        self.fichas_layouts = page.data
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarFichasLayouts()
        }
      }).catch(error => {
        let mensaje = error.response.data.message || 'No se pudieron cargar los layouts de ficha'
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-layout-fichas')
      })
    },
    
    preguntaBorrarFicha: function(layout){
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Eliminar layout ficha?",
        html: "¿Está seguro de eliminar el layout ficha <br><b>"+layout.nombre+"</b>?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FichasLayoutSrv.eliminar(layout.id).then(response => {
            swal.fire("Eliminado!", "Layout ficha "+layout.nombre+" eliminado.", "success");
            self.refrescarFichasLayouts()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo eliminar el layout ficha "+layout.nombre
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-layout-fichas')
  }
}
</script>

<style scoped>

</style>