<template>
  <div class="modal fade" ref="mdlUnidadesMedida" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Unidades de medida para el artículo
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-show="frmNuevaUnidadMedidaVisible">
            <h3>Unidad de medida</h3>
            <div class="input-group">
              <input
                ref="nombre_unidad"
                type="text"
                class="form-control"
                v-model="unidad_medida.nombre"
                placeholder="Nombre"
              />
              <input
                ref="abreviatura_unidad"
                type="text"
                class="form-control"
                v-model="unidad_medida.abreviatura"
                placeholder="Abreviatura"
              />
            </div>
            <br><br>
            <div v-if="modo == 'nuevo'">
              <h3>Otras unidades base</h3>
              <div class="table-responsive">
                <table class="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Abreviatura</th>
                      <th class="text-center" style="width:120px">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(unidad, index) in unidades_medida_base" :key="'umb'+index">
                      <td>{{unidad.nombre}}</td>
                      <td>{{unidad.abreviatura}}</td>
                      <td class="text-center">
                        <button class="btn btn-success btn-sm"
                          @click="guardarUnidad(unidad)">
                          <i class="mdi mdi-content-save"></i>
                          Guardar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="text-right">
              <button class="btn btn-secondary btn-sm" @click="frmNuevaUnidadMedidaVisible=false">
                <i class="mdi mdi-chevron-left"></i>
                Atras
              </button>
              <button
                class="btn btn-success btn-sm"
                @click="modo == 'nuevo' ? guardarUnidad(): actualizarUnidad()"
                :disabled="bandera_spinner"
              >
                <i 
                  class="mdi"
                  :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
                ></i>
                {{modo == 'nuevo' ? 'Guardar' : 'Actualizar'}}
              </button>
            </div>
          </div>
          <div v-show="!frmNuevaUnidadMedidaVisible">
            <div class="text-right">
              <button class="btn btn-success btn-sm"
                @click="nuevo()">
                Nueva unidad de medida
              </button>
            </div>
            <br>
            <div class="table-responsive">
              <table class="table table-hover table-nowrap align-middle">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Abreviatura</th>
                    <th class="text-center" style="width:280px">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(unidad, index) in unidades_medida" :key="'um'+index">
                    <td>{{unidad.nombre}}</td>
                    <td>{{unidad.abreviatura}}</td>
                    <td class="text-center" >
                      <button class="btn btn-success btn-sm"
                        @click="agregar(unidad)">
                        <i class="mdi mdi-plus-thick"></i>
                        Agregar
                      </button>
                      <button class="btn btn-warning btn-sm"
                        @click="editar(unidad)">
                        <i class="mdi mdi-square-edit-outline"></i>
                        Editar
                      </button>
                      <button class="btn btn-danger btn-sm"
                        @click="eliminar(unidad)">
                        <i class="mdi mdi-trash-can-outline"></i>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'MdlUnidadesMedida',
  data() {
    return {
      modo: 'nuevo',
      frmNuevaUnidadMedidaVisible: false,
      unidad_medida: {
        id:null,
        nombre: '',
        abreviatura: ''
      },
      unidades_medida: [],
      unidades_medida_base: [],
      bandera_spinner: false
    }
  },
  watch:{
    frmNuevaUnidadMedidaVisible: function (newfrm, oldfrm) {
      if(newfrm != true) return
      this.refrescarUnidadesMedida()
    }
  },
  methods: {
    actualizarUnidad() {
      var self = this 

      self.bandera_spinner = true

      if(self.unidad_medida.nombre == '') {
        iu.msg.warning('Es necesario ingresar el nombre de la unidad de medida')
        self.$refs.nombre_unidad.select()
        self.bandera_spinner = false
        return
      }

      let unidad = Object.assign(self.unidad_medida)

      ArticuloSrv.actualizarUnidadMedida(unidad).then(response => {
        iu.msg.success("Se actualizo correctamente")
        self.frmNuevaUnidadMedidaVisible = false
        self.refrescarUnidadesMedida()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar la unidad de medida'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    agregar(unidad) {
      let unidadTmp = Object.assign({}, unidad)
      this.$emit('unidad-medida:seleccionada', unidadTmp)

      var modal = Modal.getInstance(this.$refs.mdlUnidadesMedida)
      modal.hide()
    },
    agregarNueva() {
      let nvaum = this.unidad_medida
      if(nvaum.nombre == '') {
        iu.msg.warning('Es necesario ingresar el nuevo nombre de la unidad de medida')
        self.bandera_spinner = false
        return
      }

      let unidadTmp = Object.assign({}, nvaum)
      this.$emit('unidad-medida:seleccionada', unidadTmp)
      
      Object.assign(nvaum, {nombre: '', abreviatura: ''})
    },
    editar: function (unidad) {
      var self = this
      self.modo = 'editar'
      self.frmNuevaUnidadMedidaVisible = true

      self.unidad_medida = unidad
    },
    eliminar(unidad) {
      var self = this

      let params = {}

      ArticuloSrv.eliminarUnidadMedida(unidad.id, params).then(response => {
        iu.msg.success("Se elimino correctamente")
        self.refrescarUnidadesMedida()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo eliminar la unidad de medida'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    guardarUnidad (unidad){
      var self = this

      self.bandera_spinner = true
      
      let uni = Object.assign(self.unidad_medida)

      delete uni.id
      
      if(unidad) {
        uni = unidad
      }

      uni.prioridad = 1

      if(uni.nombre == '') {
        iu.msg.warning('Es necesario ingresar el nuevo nombre de la unidad de medida')
        self.$refs.nombre_unidad.select()
        self.bandera_spinner = false
        return
      }

      ArticuloSrv.guardarUnidadMedida(uni).then(response => {
        iu.msg.success("Se guardo correctamente")
        self.frmNuevaUnidadMedidaVisible = false
        self.refrescarUnidadesMedida()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar la unidad de medida'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    mostrar() {
      this.refrescarUnidadesMedida()
      var modal = new Modal(this.$refs.mdlUnidadesMedida)
      modal.show()
    },
    nuevo(){
      var self = this
      self.modo = 'nuevo'
      self.frmNuevaUnidadMedidaVisible = true

      self.unidad_medida = {
        id: null,
        nombre: '',
        abreviatura: ''
      }

      ArticuloSrv.unidadesMedidaBaseJSON().then(response => {
        self.unidades_medida_base = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las unidades de medida base'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    refrescarUnidadesMedida () {
      let self = this
      ArticuloSrv.unidadesMedidaJSON().then(response => {
        self.unidades_medida = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las unidades de medida'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>