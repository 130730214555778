<template>
  <Layout tituloPagina="Calendario | Eventos">
    <div class="row">
      <div class="col-lg-5 col-md-7 col-sm-8 col-12">
        <cmp-secciones seccion="eventos"></cmp-secciones>
      </div>
    </div>
    <div class="row align-items-center">
      <label class="col-md-1 col-3">Calendarios</label>
      <div class="col-md-4 col-9 mb-3">
        <multiselect
          v-model="ids_calendarios"
          placeholder="Buscar calendario"
          selectLabel="Presione enter para seleccionar"
          selectedLabel="Seleccionado"
          deselectLabel="Presione enter para eliminar"
          label="nombre"
          track-by="id"
          :options="calendarios"
          :multiple="true"
          :taggable="false"
          @select="refrescarEventos()"
          @remove="refrescarEventos()"
        ></multiselect>
      </div>
      <div class="col-md-5 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarEventos()
          "
          :datoini="dato"
          placeholder="Dato del evento"
        ></filtrador>
      </div>
      <div class="col-md-2 col-3 text-right mb-3">
        <button
          @click="nuevoEvento"
          class="btn btn-success"
        >
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </button>
      </div>
    </div>
    
    <div class="row align-items-center">
      <label class="col-md-1 col-3">Estado</label>
      <div class="col-md-4 col-9 mb-3">
        <select
          name="prioridad"
          class="form-select"
          v-model="estado"
          @change="refrescarEventos()"
        >
          <option :value="null">Seleccionar</option>
          <option value="1">Pendiente</option>
          <option value="2">En proceso</option>
          <option value="3">Completado</option>
          <option value="4">Cancelado</option>
        </select>
      </div>

      <div class="col-md-7 col-12 mb-3">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-12 mb-3">
            <div class="input-group">
              <span class="input-group-text">
                Inicio
              </span>
              <input
                type="date"
                class="form-control"
                v-model="fecha_inicial"
                @change="refrescarEventos()"
              />
              <button
                class="btn btn-light" 
                @click="
                  fecha_inicial = '',
                  refrescarEventos()
                "
              >
                <i class="mdi mdi-trash-can-outline"></i>
              </button>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-12 mb-3">
            <div class="input-group">
              <span class="input-group-text">
                Fin
              </span>
              <input
                type="date"
                class="form-control"
                v-model="fecha_final"
                @change="refrescarEventos()"
              />
              <button
                class="btn btn-light"
                @click="
                  fecha_final = '',
                  refrescarEventos()
                "
              >
                <i class="mdi mdi-trash-can-outline"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive" style="min-height: 475px;">
          <table class="table table-hover table-nowrap align-middle" style="min-width: 950px" id="tabla-eventos-calendario">
            <thead>
              <tr>
                <th class="text-right" style="width: 60px">#ID</th>
                <th>Evento</th>
                <th style="width: 150px">Calendario</th>
                <th style="width: 80px">Fecha programada</th>
                <th style="width: 100px">Tiempo estimado</th>
                <th style="width: 100px">Soporte o intalación</th>
                <th style="width: 100px">Estado</th>
                <th style="width: 100px" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="evento in eventos" :key="evento.id">
                <td class="text-right">
                  <button
                    @click="editar(evento)"
                    class="btn btn-link fw-semibold"
                  >
                    {{ evento.id }}
                  </button>
                </td>
                <td>
                  {{ evento.titulo }}
                </td>
                <td>
                  <span class="badge font-size-12" :style="'background:'+evento.color_calendario+'; color: '+evento.texto_color_calendario+';'">
                    {{ evento.nombre_calendario }}
                  </span>
                  
                </td>
                <td>
                  {{ formatoDate(evento.fecha_programada, 'DD/MM/YYYY - hh:mm A') }}
                </td>
                <td>
                  {{ convertidorSegundos_a_DHM(evento.tiempo_estimado) }}
                </td>
                <td class="fw-semibold">
                  <span v-if="evento.id_soporte">
                    Soporte: 
                    <router-link
                      :to="{
                        name: 'edicionTicketSoporte',
                        params: { id: evento.id_soporte }
                      }"
                    >
                      {{ evento.id_soporte }}
                    </router-link>
                  </span>
                  <span v-if="evento.id_instalacion">
                    Instalación:
                    <router-link
                      :to="{
                        name: 'edicionSolicitudInstalacion',
                        params: { id: evento.id_instalacion }
                      }"
                    >
                      {{ evento.id_instalacion }}
                    </router-link>
                  </span>
                </td>
                <td>
                  <span
                    class="badge font-size-12"
                    :class="{
                      'bg-secondary': evento.estado == 1,
                      'bg-success': evento.estado == 2,
                      'bg-primary': evento.estado == 3,
                      'bg-danger': evento.estado == 4,
                    }"
                  >
                    {{ evento.estado == 1 ? 'Pendiente' : '' }}
                    {{ evento.estado == 2 ? 'En proceso' : '' }}
                    {{ evento.estado == 3 ? 'Completado' : '' }}
                    {{ evento.estado == 4 ? 'Cancelado' : '' }}
                  </span>
                </td>
                <td class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li>
                        <button
                          @click="editar(evento)"
                          class="dropdown-item"
                          :class="{
                            'warning': evento.estado == 1 || evento.estado == 2,
                            'info': evento.estado == 3 || evento.estado == 4
                          }"
                        >
                          <i 
                            class="mdi font-size-15"
                            :class="{
                              'mdi-square-edit-outline text-warning': evento.estado == 1 || evento.estado == 2,
                              'mdi-eye text-info': evento.estado == 3 || evento.estado == 4
                            }"
                          ></i>
                          {{ evento.estado != 3 ? 'Editar' : 'Visor' }}
                        </button>
                      </li>
                      <li v-show="evento.estado != 4 && evento.estado != 3 && (evento.id_soporte == null && evento.id_instalacion == null )">
                        <button
                          @click="cancelarEvento(evento)"
                          class="dropdown-item danger"
                        >
                          <i class="mdi mdi-cancel font-size-15 text-danger"></i>
                          Cancelar
                        </button>
                      </li>
                      <li v-show="evento.estado != 3 && (evento.id_soporte == null && evento.id_instalacion == null )">
                        <button
                          v-on:click="preguntaEliminarEvento(evento.id, evento.titulo, evento.nombre_calendario)"
                          class="dropdown-item danger"
                        >
                          <i class="mdi mdi-trash-can-outline font-size-15 text-danger"></i>
                          Eliminar
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarEventos()
        "
      ></paginador>
    </div>
    <mdl-seleccionar-calendario
      ref="mdlSeleccionarCalendario"
      @evento:guardado="refrescarEventos()"
    ></mdl-seleccionar-calendario>
    <mdl-ticket
      ref="mdlTicket"
      @ticket:actualizado="refrescarEventos()"
    ></mdl-ticket>
    <mdl-evento
      ref="mdlEvento"
      @evento:actualizado="refrescarEventos()"
    ></mdl-evento>
    <mdl-instalacion
      ref="mdlInstalacion"
      @instalacion:actualizado="refrescarEventos()"
    ></mdl-instalacion>
    <br>
  </Layout>
</template>

<script>
import EventoCalendarioSrv from "@/services/EventoCalendarioSrv"

import MdlSeleccionarCalendario from '../MdlSeleccionarCalendario.vue'
import MdlEvento from '../MdlEvento.vue'
import MdlTicket from '../MdlTicket.vue'
import MdlInstalacion from '../MdlInstalacion.vue'

import CalendarioSrv from "@/services/CalendarioSrv.js"
import Layout from '@/views/layouts/main'
import CmpSecciones from '../CmpSecciones.vue'
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import Multiselect from 'vue-multiselect'
import Swal from 'sweetalert2'
import moment from 'moment'
export default {
  name: 'EventosCalendario',
  components: { 
    Layout,
    CmpSecciones,
    Filtrador,
    Paginador,
    Multiselect,
    MdlSeleccionarCalendario,
    MdlEvento,
    MdlTicket,
    MdlInstalacion
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.eventosCalendario.por_pagina') || 10),
      tc: 0, // Total de clientes
      totpag: 0, // Total de páginas
      eventos:[],
      calendarios: [],
      ids_calendarios: [],
      estado: null,
      fecha_inicial: '',
      fecha_final: '',
      ids: []
    }
  },
  created() {
    var self = this

    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.page) this.page = parseInt(this.$route.query.page)
    if (this.$route.query.epp) this.epp = parseInt(this.$route.query.epp)
    if (this.$route.query.estado) this.estado = parseInt(this.$route.query.estado)
    if (this.$route.query.fecha_inicial) this.fecha_inicial = this.$route.query.fecha_inicial
    if (this.$route.query.fecha_final) this.fecha_final = this.$route.query.fecha_final
    if (this.$route.query.ids_calendarios) {
      let ids = this.$route.query.ids_calendarios
      if(typeof ids == 'string') self.ids.push(ids)
      else self.ids = ids
    }

    self.cargarCalendarios()
    self.refrescarEventos()
  },
  mounted() {
    iu.spinner.mostrar('#tabla-eventos-calendario')
  },

  methods: {
    cargarCalendarios:function() {
      var self = this, params = { sin_paginacion: true }

      CalendarioSrv.calendariosJSON(params).then(response => {
        let calendarios = response.data

        self.calendarios = []
        calendarios.forEach(cal => {
          self.calendarios.push({
            id: cal.id,
            nombre: cal.nombre
          })
        })

        if (self.ids.length > 0) {
          self.ids_calendarios = []
          self.ids.forEach(id => {
            let cal = self.calendarios.find(cal => cal.id == id)
            self.ids_calendarios.push(cal)
          })

          self.refrescarEventos()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los calendarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarEventos({ page: n })
    },
    cancelarEvento: function(evento) {
      var self = this,
          idEvento = evento.id

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Deseas cancelar el evento?",
        html: `¿Está seguro de cancelar el evento <strong>${idEvento}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          EventoCalendarioSrv.cancelar(idEvento).then(response => {
            swal.fire("Cancelado!", "El evento se ha cancelado correctamente", "success");
            self.refrescarEventos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se puede cancelar el evento '+idEvento
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
            self.refrescarEventos()
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    colorIdealTexto: function(hexColor) {
      let limite = 105
      let componentes = {
        r: parseInt( hexColor.substring(1,3), 16),
        g: parseInt( hexColor.substring(3,5), 16),
        b: parseInt( hexColor.substring(5,7), 16)
      }

      let bgDelta = (componentes.r * 0.299) + (componentes.g * 0.587) + (componentes.b * 0.114);
      
      return ((255 - bgDelta) < limite) ? "#000000" : "#ffffff";
    },
    convertidorSegundos_a_DHM(segundos = 0) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return dias+' dia '+horas+' hrs '+minutos+' min'
    },
    editar: function(evento) {
      var self = this

      if(evento.id_instalacion !== null) return self.$refs.mdlInstalacion.mostrar(evento.id, evento.id_instalacion)
      if(evento.id_soporte !== null) return self.$refs.mdlTicket.mostrar(evento.id, evento.id_soporte)

      self.$refs.mdlEvento.mostrar(evento.id)
    },
    formatoDate(mt, formato = 'DD/MM/YYYY') {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format(formato)
    },
    nuevoEvento: function(){
      var self = this, fecha, hora

      fecha = moment().format('YYYY-MM-DD')
      hora = moment().format('HH:mm:ss')

      self.$refs.mdlSeleccionarCalendario.mostrar(fecha, hora)
    },

    preguntaEliminarEvento: function(idEvento, nombre, calendario) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: 'Eliminar evento del "'+calendario+'"',
        html: '¿Desea eliminar el evento <br> <strong>' + nombre + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          EventoCalendarioSrv.eliminar(idEvento).then(response=>{
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarEventos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarEventos() {
      var self = this
      iu.spinner.mostrar('#tabla-eventos-calendario')

      this.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp,
          estado: self.estado,
          fecha_inicial: self.fecha_inicial,
          fecha_final: self.fecha_final,
          ids_calendarios : self.ids_calendarios.map(id => id.id)
        }
      }).catch(error => {})

      let params = {
        dato: self.dato,
        page: self.page,
        epp: self.epp,
        estado: self.estado
      }
      let t_cal = self.ids_calendarios.length

      if(t_cal > 0) {
        let idsCal = self.ids_calendarios.map(id => id.id)
        Object.assign(params, {
          con_ids_calendarios: true,
          ids_calendarios: idsCal,
        })
      }

      if(this.fecha_inicial != '' && self.fecha_final == '') {
        Object.assign(params, {
          fecha_inicial: moment(self.fecha_inicial).format('YYYY-MM-DD'),
          fecha_final: moment(self.fecha_inicial).format('YYYY-MM-DD')
        })
      }

      if(this.fecha_inicial != '' && self.fecha_final != '') {
        Object.assign(params, {
          fecha_inicial: moment(self.fecha_inicial).format('YYYY-MM-DD'),
          fecha_final: moment(self.fecha_final).format('YYYY-MM-DD')
        })
      }

      self.eventos = []
      EventoCalendarioSrv.eventosJSON(params).then(response => {
        localStorage.setItem('argusblack.eventosCalendario.por_pagina', self.epp)

        let page = response.data
        let eventos = page.data
        
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarEventos()
        }

        eventos.forEach(e => {
          let calendario = e.calendario
          let usuario_ajuste = calendario.usuario_ajuste

          e.nombre_calendario = calendario ? calendario.nombre : ''
          e.color_calendario = usuario_ajuste ? usuario_ajuste.color : calendario.color
          e.texto_color_calendario = self.colorIdealTexto(usuario_ajuste ? usuario_ajuste.color : calendario.color)
        });
        self.eventos = eventos
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los eventos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-eventos-calendario')
      })
    }
  }
}
</script>

<style scoped>
</style>