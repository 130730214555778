<template>
  <div class="card">
    <h5 class="card-header">
      ultimas Solicitudes de requerimientos
    </h5>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover align-middle">
          <thead>
            <tr>
              <th>No.</th>
              <th>Fecha de solicitud</th>
              <th>Solicitante</th>
            </tr>
          </thead>
          <tbody>
            <tr style="font-size: 15px;" >
              <td class="sin-borde-top">
                1035
              </td>
              <td class="sin-borde-top">
                09/03/2020
              </td>
              <td class="sin-borde-top">
                Felicia Oliva A. Sanchez
              </td>
            </tr>
            <tr style="font-size: 15px;" >
              <td class="sin-borde-top">
                1036
              </td>
              <td class="sin-borde-top">
                09/03/2020
              </td>
              <td class="sin-borde-top">
                Axel Tonatiúh Santillán Gonzaléz
              </td>
            </tr>
            <tr style="font-size: 15px;" >
              <td class="sin-borde-top">
                1037
              </td>
              <td class="sin-borde-top">
                09/03/2020
              </td>
              <td class="sin-borde-top">
                Felicia Oliva A. Sanchez
              </td>
            </tr>
            <tr style="font-size: 15px;" >
              <td class="sin-borde-top">
                1038
              </td>
              <td class="sin-borde-top">
                09/03/2020
              </td>
              <td class="sin-borde-top">
                Omar Jesus Aguilar Mendoza
              </td>
            </tr>
            <tr style="font-size: 15px;" >
              <td class="sin-borde-top">
                1039
              </td>
              <td class="sin-borde-top">
                09/03/2020
              </td>
              <td class="sin-borde-top">
                Felicia Oliva A. Sanchez
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.tamano {
  width:50px
}

.color-yellow {
  color: #F8AC59;
  font-size: 20px;
}

.color-red {
  color: #F78469;
}

.sin-borde-top {
  border-top: none;
}
</style>
