<template>
  <Layout :tituloPagina="`Network | DHs ${ marca.nombre ? '| Marca '+marca.nombre : '' } | Modelos`">
    <div class="row">
      <div class="col-lg-5">
        <cmp-secciones seccion="modelos"></cmp-secciones>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-10 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarModelos()
          "
          :datoini="dato"
          placeholder="Dato del modelo"
        ></filtrador>
      </div>
      <div class="col-sm-2 col-3 text-right">
        <router-link
          :to="{ name: 'nuevoModeloDhs', query: { id_marca: marca.id } }"
          class="btn btn-success"
        >
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo modelo
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 offset-md-8 text-right" style="padding-top: 10px;">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nModelosEnPag}} modelos en página
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nModelosTotales}} modelos en total
        </span>
      </div>
    </div>
    <br />

    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Modelos {{ marca.nombre ? 'de la marca '+marca.nombre : '' }}
        </h4>
        <div>
          <a href="#" onclick="return false;" @click="cerrar()">
            <i class="mdi mdi-close text-dark"></i>
          </a>
        </div>
      </div>
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="table table-hover table-nowrap align-middle" id="tabla-modelo-dhs">
            <thead>
              <tr>
                <th style="width: 50px">Foto</th>
                <th>Identificador</th>
                <th style=" width: 180px">Marca</th>
                <th style=" width: 180px">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="modelo in modelos" :key="modelo.id">
                <td>
                  <img
                    v-show="modelo.id_foto != null"
                    :src="
                      API + '/dhs/modelos/' + modelo.id +
                      '/imagen?tipo_foto=64x64&_tk=' + tk +
                      '&_tiempo=' + new Date().getTime()
                    "
                    class="iconmodelo"
                  />
                </td>
                <td>{{ modelo.identificador }}</td>
                <td>{{ modelo.nombre_marca}}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'edicionModeloDhs',
                      params: { id: modelo.id }
                    }"
                    class="btn btn-warning btn-sm"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                    Editar
                  </router-link>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="preguntaEliminarModelo(modelo)"
                  >
                    <i class="mdi mdi-trash-can-outline"></i>
                    Borrar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarModelos()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import API from '@/API.js'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import CmpSecciones from '../CmpSecciones.vue'
import ModeloDhsSrv from '@/services/ModeloDhsSrv.js'
import MarcaDhsSrv from '@/services/MarcaDhsSrv.js'

import Swal from 'sweetalert2'
export default {
  name: 'ListadoModelosDhs',
  components: { Layout, CmpSecciones, Paginador, Filtrador },
  data() {
    return {
      tk: localStorage.getItem('argusblack.token'),
      API: API,
      baseUrl: window.location.origin,
      marcas: [],
      marca: {
        nombre: '',
        id: null
      },
      modelos: [],
      dato: '',
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.listadoModelosDhs.por_pagina') || 10),
      ultima_pagina: 0,
      id_marca: null,
      nModelosEnPag:0,
      nModelosTotales:0
    }
  },

  created: function() {
    var self = this

    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.pagina) this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina) this.por_pagina = parseInt(this.$route.query.por_pagina)
    if (this.$route.query.id_marca) this.id_marca = this.$route.query.id_marca

    self.cargarMarca()
    self.refrescarModelos()
  },

  methods: {
    cargarMarca: function(callback) {
      var self = this
      
      if(self.id_marca == null) return
      
      MarcaDhsSrv.marcaJSON(self.id_marca).then(response => {
        self.marca = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las marcas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.refrescarModelos()
    },

    cerrar: function() {
      this.$router.go(-1)
    },

    preguntaEliminarModelo: function(modelo) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Eliminación de modelo de DHs",
        html: '¿Está seguro que desea eliminar el modelo <strong>'+modelo.identificador+'</strong> ?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ModeloDhsSrv.eliminar(modelo.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarModelos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el CPE'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarModelos: function(config) {
      var self = this
      var idMarca = self.idMarca

      iu.spinner.mostrar('#tabla-modelo-dhs')

      this.$router.replace({
        query: {
          dato: self.dato,
          pagina: self.pagina,
          por_pagina: self.por_pagina,
          id_marca: self.id_marca
        }
      }).catch(error => {})

      var config = {
        dato: self.dato,
        pagina: self.pagina,
        por_pagina: self.por_pagina,
        id_marca: self.id_marca
      }
      
      ModeloDhsSrv.modelosJSON(config).then(response => {
        localStorage.setItem('argusblack.listadoModelosDhs.por_pagina', self.por_pagina)
        let res = response.data
        self.modelos = res.modelos
        self.pagina = res.pagina
        self.ultima_pagina = res.ultima_pagina
        self.nModelosEnPag = res.nModelosEnPag
        self.nModelosTotales = res.total

        if (self.ultima_pagina < self.pagina) {
          self.pagina = self.ultima_pagina
          self.refrescarModelos()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los modelos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-modelo-dhs')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-modelo-dhs')
  }
}
</script>

<style scoped>
.iconmodelo {
  width: 30px;
  height: 30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>