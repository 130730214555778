<template>
  <div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10">
        <h2>Compras</h2>
        <ol class="breadcrumb">
          <li>
            Requerimientos
          </li>
          <li>
            <strong>
              {{modo == 'nuevo'?'Nuevo':''}}
              {{modo == 'edicion'?'Edición':''}}
              {{modo == 'visor'?'Visor':''}}
            </strong>
          </li>
        </ol>
      </div>
    </div>

    <div class="wrapper wrapper-content">
      <div class="row">
        <div class="col-md-9">
          <div class="ibox">
            <div class="ibox-title">
              <h5>
                {{modo == 'nuevo'?'Nuevo':''}}
                {{modo == 'edicion'?'Editar':''}}
                {{modo == 'visor'?'Ver':''}}
                requerimiento
              </h5>
              <div class="ibox-tools">
                <a @click="cerrar()">
                  <i class="fa fa-close"></i>
                </a>
              </div>
            </div>
            
            <div class="ibox-content">
              <div class="row">
                <div class="col-md-4">
                  <label>Nombre solicitante</label>
                  <input
                    class="form-control"
                    v-model="requerimiento.nombre_solicitante"
                    disabled/>
                </div>
                <div class="col-md-4">
                  <label>Fecha de solicitud</label>
                  <input
                    class="form-control"
                    v-model="requerimiento.fecha_solicitud"
                    disabled/>
                </div>
                <div class="col-md-4">
                  <label>Area</label>
                  <input
                    class="form-control"
                    v-model="requerimiento.area"
                    disabled/>
                </div>
              </div>

              <br>

              <div class="row">
                <div class="col-md-12">
                  <label>Justificacion del requerimiento</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="requerimiento.justificacion"/>
                </div>
              </div>

              <br>

              <div class="row">
                <div class="col-md-4">
                  <label>división de familia de articulos</label>
                  <select class="form-control" v-model="id_familia" @change="cargarFamiliaSeleccionada()" name="division">
                    <option :value="null">Selecciona familia</option>
                    <option
                      v-for="familia in familias"
                      :key="familia.id"
                      :value="familia.id"
                    >
                      {{familia.division}}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <label>nombre del articulo a seleccionar</label>
                  <select
                    class="form-control"
                    :disabled="id_familia == null"
                    v-model="id_articulo"
                    @change="cargarArticuloSeleccionado()"
                    name="articulo"
                  >
                    <option :value="null">Seleccionar articulo</option>
                    <option 
                      v-for="articulo in articulos"
                      :key="articulo.id"
                      :value="articulo.id"
                    >
                      {{articulo.nombre}}
                    </option>
                  </select>
                </div>
              </div>

              <br>

              <div class="row">
                <div class="col-md-12">
                  <label>descirición del articulo o servicio</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="articulo_seleccionado.descripcion"
                    disabled/>
                </div>
              </div>

              <br>

              <div class="row">
                <div class="col-md-2">
                  <label>Cantidad</label>
                  <input type="number" class="form-control" v-model="cantidad" :disabled="id_articulo == null">
                </div>
                <div class="col-md-2">
                  <label>Precio unitario</label>
                  <input type="number" class="form-control" v-model="precio_unitario" :disabled="cantidad < 1">
                </div>
                <div class="col-md-2">
                  <br>
                  <button class="btn btn-primary" @click="agregarPartida()" :disabled="id_familia == null || id_articulo == null || cantidad < 1 || precio_unitario < 1">
                    <i class="fa fa-plus"></i> Agragar
                  </button>
                </div>
              </div>

              <br>
              <br>

              <div class="row">
                <div class="col-md-12">
                  <label>Articulos solicitados</label>
                  <div class="table-responsive">
                    <table class="table table-hover align-middle">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>División de familia de articulos</th>
                          <th>Nombre del articulo o servico</th>
                          <th>descripción del articulo o servicio</th>
                          <th>Cantidad</th>
                          <th>Precio unitario</th>
                          <th>Total</th>
                          <th>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-show="modo == 'nuevo'"
                          v-for="(partida,index) in partidas"
                          :key="`part-${index+1}`">
                          <td>
                            {{index+1}}
                          </td>
                          <td>
                            {{partida.division}}
                          </td>
                          <td>
                            {{partida.nombre_articulo}}
                          </td>
                          <td>
                            {{partida.descripcion}}
                          </td>
                          <td>
                            {{partida.cantidad}}
                          </td>
                          <td>
                            {{formatoMoneda(partida.precio_unitario)}}
                          </td>
                          <td>
                            <b style="color:#3379B7">
                              {{formatoMoneda(partida.total)}}
                            </b>
                          </td>
                          <td>
                            <button class="btnBorrar btn btn-default btn-xs"
                              @click="eliminarPartida(index)">
                              <i class="fa fa-trash"></i>
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              
              <br>

              <div class="row">
                <div class="col-md-2 col-md-offset-10">
                  <div class="p-xxs text-center bg-success b-r-md">
                    <span style="font-size:16px">
                      <b>Total: {{formatoMoneda(total)}}</b>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="text-right">
            <button @click="cerrar()" class="btn btn-default">
              <i class="fa fa-angle-left"></i> Atras
            </button>

            {{modo == 'nuevo'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-primary"
              @click="guardar()"
              v-if="modo == 'nuevo'"
            >
              <i class="fa fa-check"></i>
              Enviar requerimiento
            </button>

            {{modo == 'edicion'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-primary"
              @click="Actualizar()"
              v-if="modo == 'edicion'"
            >
              <i class="fa fa-check"></i>
              Actualizar requerimiento
            </button>

            {{modo == 'visor'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-danger"
              v-if="modo == 'visor'"
            >
              <i class="fa fa-times-circle"></i>
              Rechazar
            </button>

            {{modo == 'visor'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-warning"
              v-if="modo == 'visor'"
            >
              <i class="fa fa-clock-o"></i>
              Posponer
            </button>

            {{modo == 'visor'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-primary"
              v-if="modo == 'visor'"
            >
              <i class="fa fa-check"></i>
              Aprobar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import accounting from 'accounting/accounting.js'

export default {
  data: function() {
    return {
      modo: 'nuevo',
      usuario: {},
      requerimiento: {
        id_solicitante: null,
        nombre_solicitante: '',
        fecha_solicitud: '',
        partidas: []
      },
      partidas: [],

      id_familia: null,
      id_articulo: null,
      articulos: [],
      familias: [],
      cantidad: 0,
      precio_unitario: 0,
      familia_seleccionada:{},
      articulo_seleccionado:{},

      total:0
    }
  },

  created: function() {
    var self = this

    // Determinar el modo de la vista
    if (self.$route.path.indexOf('requerimientos/nuevo') != -1) self.modo = 'nuevo'
    if (self.$route.path.indexOf('edicion') != -1) self.modo = 'edicion'
    if (self.$route.path.indexOf('visor') != -1) self.modo = 'visor'
    
    //if (this.modo == 'visor') this.cargarSolicitudArticulo()
    //Carga de la fecha inicial del sistema
    if (self.requerimiento.fecha_solicitud == '' && self.modo == 'nuevo') {
      SistemaSrv.fecha({formato: 'd/m/Y'}).then(response => {
        self.requerimiento.fecha_solicitud = response.data
      })
    }

    self.cargarFamilias()

    switch(self.modo){
      case 'nuevo': {
        setTimeout(() => {
          self.agregarUsuario()
        }, 1100)
      }
      break;
      case 'edicion': {

      }
      break;
      case 'visor': {

      }
    }
  },

  methods: {
    agregarPartida: function() {
      var self = this
      
      var nuevaPartida = {
        id_articulo: self.id_articulo,
        id_familia: self.familia_seleccionada.id,
        familia: self.familia_seleccionada.familia,
        division: self.familia_seleccionada.division,
        nombre_articulo: self.articulo_seleccionado.nombre,
        descripcion: self.articulo_seleccionado.descripcion,
        cantidad: self.cantidad,
        precio_unitario: self.precio_unitario,
        total: self.cantidad * self.precio_unitario
      }

      self.partidas.push(nuevaPartida)

      self.id_familia= null
      self.id_articulo= null
      self.cantidad= 0
      self.precio_unitario= 0
      self.articulos=[]
      self.familias= []
      self.familia_seleccionada= {}
      self.articulo_seleccionado= {}

      self.calcularTotal()
      self.cargarFamilias()

    },

    agregarUsuario: function() {
      var self = this
      
      let solicitante = self.requerimiento

      self.usuario= self.$store.state.todo.usuario
      
      solicitante.nombre_solicitante = self.usuario.nombre + ' ' +
                                       self.usuario.apellido_paterno + ' ' +
                                       self.usuario.apellido_materno
      
      solicitante.id_solicitante = self.usuario.id
    },

    atras: function() {
      this.$router.go(-1)
    },

    calcularTotal:function(){
      var self = this

      self.total = 0

      self.partidas.forEach(part => {
        self.total += part.total
      })
    },

    cargarArticuloSeleccionado: function() {
      let self = this

      let idArticulo = self.id_articulo

      ArticuloSrv.articuloJSON(idArticulo).then(response => {
        self.articulo_seleccionado = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudo cargar el articulo' + idArticulo
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarArticulos: function() {
      var self = this

      let params = {
        id_familia: self.id_familia,
        con_cantidad: true,
        sin_paginacion:true,
      }

      ArticuloSrv.articulosJSON(params).then(response => {
        self.articulos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudieron cargar los articulos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarFamilias: function() {
      var self = this

      let params = {
        sin_paginacion: true,
        solo_idfamdiv:true
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        let page = response.data
        self.familias = page
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudieron cargar las familias de articulos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarFamiliaSeleccionada: function() {
      let self = this

      let idFamilia = self.id_familia

      FamiliaArticulosSrv.familiaJSON(idFamilia).then(response => {
        self.familia_seleccionada = response.data
        self.cargarArticulos()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudo cargar las familia de articulos' +idFamilia
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    formatoMoneda: function(moneda) {
      return accounting.formatMoney(moneda)
    },
  }
}
</script>

<style>

</style>