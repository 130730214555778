<template>
  <Layout tituloPagina="Sistema | Cuenta | Cambiar de plan ISP | Actualización del plan ISP">
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Comparacion de planes</h4>
          </div>
          <div class="card-body" style="min-height: 475px;">
            <div class="table-responsive">
              <table class="tblRouters table table-hover align-middle">
                <thead>
                  <tr>
                    <th><span class="h3">Caracteristicas</span></th>
                    <th v-show="estado_modulo">
                      <span class="h3">Plan {{plan_isp_contratado.nombre}}</span>
                      <br>
                      <big class="text-primary">
                        <strong>Plan actual</strong>
                      </big>
                    </th>
                    <th>
                      <span class="h3">Plan {{plan_isp_seleccionado.nombre}}</span><br>
                      <big class="text-info">
                        <strong>Nuevo plan seleccionado</strong>
                      </big>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Descripción</td>
                    <td style="min-width: 300px;" v-show="estado_modulo">{{plan_isp_contratado.descripcion}}</td>
                    <td style="min-width: 300px;">{{plan_isp_seleccionado.descripcion}}</td>
                  </tr>
                  <tr>
                    <td>
                      Gestión de clientes
                    </td>
                    <td v-show="estado_modulo">
                      {{plan_isp_contratado.max_clientes != -1 ? 'hasta '+plan_isp_contratado.max_clientes : 'Ilimitado'}}
                    </td>
                    <td>
                      {{plan_isp_seleccionado.max_clientes != -1 ? 'hasta '+plan_isp_seleccionado.max_clientes : 'Ilimitado'}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Gestión de contratos
                    </td>
                    <td v-show="estado_modulo">
                      {{plan_isp_contratado.max_contratos != -1 ? 'hasta '+plan_isp_contratado.max_contratos : 'Ilimitado'}}
                    </td>
                    <td>
                      {{plan_isp_seleccionado.max_contratos != -1 ? 'hasta '+plan_isp_seleccionado.max_contratos : 'Ilimitado'}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Gestión de CPEs
                    </td>
                    <td v-show="estado_modulo">
                      {{plan_isp_contratado.max_cpes != -1 ? 'hasta '+plan_isp_contratado.max_cpes : 'Ilimitado'}}
                    </td>
                    <td>
                      {{plan_isp_seleccionado.max_cpes != -1 ? 'hasta '+plan_isp_seleccionado.max_cpes : 'Ilimitado'}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Gestión de routers de administración
                    </td>
                    <td v-show="estado_modulo">
                      {{plan_isp_contratado.max_routers != -1 ? 'hasta '+plan_isp_contratado.max_routers : 'Ilimitado'}}
                    </td>
                    <td>
                      {{plan_isp_seleccionado.max_routers != -1 ? 'hasta '+plan_isp_seleccionado.max_routers : 'Ilimitado'}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Notificación de routers de administración caídos
                    </td>
                    <td v-show="estado_modulo">
                      {{plan_isp_contratado.notificacion_routers_caidos ? "SI": "NO"}}
                    </td>
                    <td>
                      {{plan_isp_seleccionado.notificacion_routers_caidos ? "SI": "NO"}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Generación de facturas
                    </td>
                    <td v-show="estado_modulo">
                      {{plan_isp_contratado.generacion_facturas ? "SI": "NO"}}
                    </td>
                    <td>
                      {{plan_isp_seleccionado.generacion_facturas ? "SI": "NO"}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Cortes de servicios
                    </td>
                    <td v-show="estado_modulo">
                      {{plan_isp_contratado.cortes_servicios ? "SI": "NO"}}
                    </td>
                    <td>
                      {{plan_isp_seleccionado.cortes_servicios ? "SI": "NO"}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Mensaje de servicio suspendido
                    </td>
                    <td v-show="estado_modulo">
                      {{plan_isp_contratado.mensaje_servicio_suspendido ? "SI": "NO"}}
                    </td>
                    <td>
                      {{plan_isp_seleccionado.mensaje_servicio_suspendido ? "SI": "NO"}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Reportes diarios
                    </td>
                    <td v-show="estado_modulo">
                      {{plan_isp_contratado.reportes_diarios ? "SI": "NO"}}
                    </td>
                    <td>
                      {{plan_isp_seleccionado.reportes_diarios ? "SI": "NO"}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Notificación por correo electrónico
                    </td>
                    <td v-show="estado_modulo">
                      {{plan_isp_contratado.notificacion_por_email ? "SI": "NO"}}
                    </td>
                    <td>
                      {{plan_isp_seleccionado.notificacion_por_email ? "SI": "NO"}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Precio mensual
                    </td>
                    <td v-show="estado_modulo">
                      ${{plan_isp_contratado.precio_mensual}} USD
                    </td>
                    <td>
                      ${{plan_isp_seleccionado.precio_mensual}} USD
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Precio anual
                    </td>
                    <td v-show="estado_modulo">
                      ${{plan_isp_contratado.precio_anual}} USD <br>
                      <strong>{{costoAnualPocentaje(plan_isp_contratado.precio_mensual, plan_isp_contratado.precio_anual)}}</strong>
                    </td>
                    <td>
                      ${{plan_isp_seleccionado.precio_anual}} USD <br>
                      <strong>{{costoAnualPocentaje(plan_isp_seleccionado.precio_mensual, plan_isp_seleccionado.precio_anual)}}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-right pb-4">
          <div class="btn-group">
            <button class="btn btn-secondary"
              @click="$router.go(-1)">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>

            <button class="btn btn-success" @click="actualizarModuloPlan()">
              <i class="mdi mdi-refresh"></i>
              {{estado_modulo ? 'Cambiar' : 'Activar'}} plan ISP
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <w-saldo></w-saldo>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import WSaldo from '@/views/sistema/cuenta/WSaldo.vue'
import CuentaSrv from '@/services/CuentaSrv.js'
export default {
  name: 'ActualizarPlanIsp',
  components: { Layout, WSaldo },
  data() {
    return {
      id_plan_seleccionado: null,

      estado_modulo: false,

      plan_isp_contratado: {
        id:null,
        nombre: "",
        descripcion: "",
        max_clientes: 0,
        max_contratos: 0,
        max_cpes: 0,
        max_routers: 0,
        notificacion_routers_caidos: false,
        generacion_facturas: false,
        cortes_servicios: false,
        mensaje_servicio_suspendido: false,
        reportes_diarios: false,
        notificacion_por_email: false,
        precio_mensual: 0,
        precio_anual: 0,
      },

      plan_isp_seleccionado: {
        id:null,
        nombre: "",
        descripcion: "",
        max_clientes: 0,
        max_contratos: 0,
        max_cpes: 0,
        max_routers: 0,
        notificacion_routers_caidos: false,
        generacion_facturas: false,
        cortes_servicios: false,
        mensaje_servicio_suspendido: false,
        reportes_diarios: false,
        notificacion_por_email: false,
        precio_mensual: 0,
        precio_anual: 0,
      }
    }
  },

  created: function () {
    var self = this

    self.id_plan_seleccionado = self.$route.params.id_plan

    self.cargarEstado(function() {
      self.cargarPlanSeleccionado()
    })
  },

  methods: {
    actualizarModuloPlan:function() {
      var self = this 
      let datos = {
        id_plan: self.plan_isp_seleccionado.id
      }
      
      if(self.plan_isp_contratado.id != null) Object.assign(datos, {id_plan_actual: self.plan_isp_contratado.id})

      CuentaSrv.actualizarModuloPlanContratado('isp', datos).then(response => {
        let mensaje = self.estado_modulo ? 'Se cambio correctamente el plan seleccionado' : 'Se activo correctamente el plan seleccionado'
        iu.msg.success(mensaje)

        // self.$router.push({ name: 'sistemaCuenta'})
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo activar o cambiar plan'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarEstado: function(callback) {
      var self = this

      CuentaSrv.estadoModulo('isp').then(response => {
        self.estado_modulo = response.data

        if(self.estado_modulo == true) self.cargarPlanContratado()

        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlanContratado: function (){
      var self = this

      CuentaSrv.moduloPlanContratadoJSON('isp').then(response => {
        self.plan_isp_contratado = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlanSeleccionado:function () {
      var self = this

      CuentaSrv.moduloPlanJSON('isp', self.id_plan_seleccionado).then(response => {
        self.plan_isp_seleccionado = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    costoAnualPocentaje: function(precio_mensual, precio_anual){
      if(precio_anual < 0) return ''

      let total = precio_mensual * 12
      let porcentaje = ((total - precio_anual) * 100) / total

      if(porcentaje < 0) return ''

      return `Costo anual (incluye ${parseInt(porcentaje)}% de descuento)`
    }
  },
}
</script>

<style scoped>

</style>