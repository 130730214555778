<template>
  <Layout :tituloPagina="'Facturas'">
    <div class="row">
      <div class="col-lg-4">
        <cmp-secciones seccion="facturas"></cmp-secciones>
      </div>
      <!-- <div class="col-lg-6">
        <div class="row">
          <div class="offset-md-4 col-md-4">
            <div class="input-group">
              <span class="form-control border border-warning text-warning text-center">
                <strong>Timbres usados</strong>
              </span>
              <span class="input-group-text bg-warning border border-warning text-white">
                50
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <span class="form-control border border-success text-success text-center">
                <strong>Timbres disponibles</strong>
              </span>
              <span class="input-group-text bg-success border border-success text-white">
                50
              </span>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row">
      <div class="col-md-2 col-2 mb-3">
        <button
          class="btn btn-secondary"
          @click="modalFiltrosListadoFacturas()"
        >
          <span class="d-none d-sm-block">
            <i class="fas fa-list-ul"></i>
            Filtros
          </span>
          <span class="d-block d-sm-none">
            <i class="fas fa-list-ul"></i>
          </span>
        </button>
      </div>
      <div class="col-md-8 col-8 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarFacturas()
          "
          :datoini="dato"
          placeholder="Dato de la factura"
        ></filtrador>
      </div>
      <div class="col-md-2 col-2 text-right">
        <router-link :to="{ name: 'nuevaFactura' }" class="btn btn-success">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nueva
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn-light btn-sm" @click="mostrarFacturasAnticipadas()">
        <i class="mdi mdi-cogs"></i>
        Facturas anticipadas
      </button>
    </div>
    <div class="row" style="padding-top: 10px">
      <div class="col-md-9">
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="creacion"
            v-model="columnas.fechaCreacion"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="creacion">Fecha de creación</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="pago"
            v-model="columnas.fechaPago"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="pago">Fecha para pago</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="vencimiento"
            v-model="columnas.fechaVencimiento"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="vencimiento">Fecha de vencimiento</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="corte"
            v-model="columnas.fechaCorte"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="corte">Fecha de corte</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="inicioFinPeriodo"
            v-model="columnas.inicioYFinPeriodo"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="inicioFinPeriodo">
            Inicio y fin de periodo
          </label>
        </div>
      </div>

      <div class="col-md-3 text-right">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nFacturasEnPag}} facturas en página
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nFacturasTotales}} facturas en total
        </span>
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive" style="min-height: 475px;">
          <table id="tabla-facturas" class="tblFacturas table table-hover table-nowrap align-middle">
            <thead>
              <tr>
                <th style="width: 120px">Folio</th>
                <th style="min-width: 200px">Cliente</th>
                <th style="width: 100px" v-show="columnas.fechaCreacion">Fecha de creación</th>
                <th style="width: 100px" v-show="columnas.fechaPago">Fecha para pago</th>
                <th style="width: 100px" v-show="columnas.fechaVencimiento">Fecha de vencimiento</th>
                <th style="width: 100px" v-show="columnas.fechaCorte">Fecha de corte</th>
                <th style="width: 100px" v-show="columnas.inicioYFinPeriodo">Inicio de periodo</th>
                <th style="width: 100px" v-show="columnas.inicioYFinPeriodo">Fin de periodo</th>
                <th style="width: 100px">Total</th>
                <th style="width: 100px">Saldo</th>
                <th style="width: 100px">Estado</th>
                <!-- <th>CFDI</th> -->
                <th style="width: 135px" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="factura in facturas">
                <tr v-if="true" :key="'factura-'+factura.id">
                  <td class="fw-semibold">
                    <router-link :to="{ name: 'factura', params: { id: factura.id } }">
                      {{ factura.folio }}
                    </router-link>
                    <sup
                      title="Generada por sistema"
                      class="badge badge-pill badge-soft-info"
                      v-show="factura.generada_por_sistema == true"
                    >GS</sup>
                    <sup
                      title="Factura anticipada"
                      class="badge badge-pill badge-soft-dark"
                      v-show="factura.anticipada == true"
                    >FA</sup>
                    <sup
                      v-show="factura.prorroga > 0"
                      class="badge badge-pill badge-soft-success font-size-11"
                      title="Tiene promesa de pago"
                    >P</sup>
                  </td>
                  <td>
                    <h5 class="font-size-14" :class="columnas.inicioYFinPeriodo ? 'text-truncate' : ''">
                      <router-link
                        :to="{
                          name: 'tableroCliente',
                          params: { id_cliente: factura.id_cliente }
                        }"
                        :title="factura.nombre_cliente"
                      >
                        {{ factura.nombre_cliente }}
                      </router-link>
                    </h5>
                  </td>
                  <td v-show="columnas.fechaCreacion">
                    {{ formatoFecha(factura.fecha+' '+factura.hora_creacion, 'DD/MM/YYYY - hh:mm A' ) }}
                  </td>
                  <td v-show="columnas.fechaPago">
                    {{ factura.fecha_para_pago ? formatoFecha(factura.fecha_para_pago) : '' }}
                  </td>
                  <td v-show="columnas.fechaVencimiento">
                    <span
                      v-if="factura.id_tipo_renta_contrato == 1"
                      :class="{
                        'text-danger':
                          new Date(factura.fecha_vencimiento).getTime() -
                            new Date(fechaHoy).getTime() <=
                          0
                      }"
                    >
                      {{ factura.fecha_vencimiento ? formatoFecha(factura.fecha_vencimiento) : '' }}
                    </span>

                    <span
                      v-if="factura.id_tipo_renta_contrato == 2"
                      title="Renta Mensual Variable"
                      class="badge bg-warning"
                    >
                      RMV
                    </span>
                  </td>
                  <td v-show="columnas.fechaCorte">
                    <span v-show="factura.id_tipo_renta_contrato == 1">
                      {{ fecha_corte_servicio(factura.fecha_para_pago ? factura.fecha_para_pago : '' , factura.n_dias_corte ? factura.n_dias_corte : 0 ) }}
                    </span>
                    <span v-show="factura.id_tipo_renta_contrato == 2">
                      {{factura.fin_periodo ? formatoFecha(factura.fin_periodo): ''}}
                    </span>
                  </td>
                  <td v-show="columnas.inicioYFinPeriodo">{{factura.inicio_periodo ? formatoFecha(factura.inicio_periodo): ''}}</td>
                  <td v-show="columnas.inicioYFinPeriodo">{{factura.fin_periodo ? formatoFecha(factura.fin_periodo): ''}}</td>
                  <td class="text-right">
                    {{ formatoMoneda(factura.total) }}
                  </td>
                  <td class="text-right">
                    <router-link
                      :to="{
                        name: 'abonosFactura',
                        params: { id_factura: factura.id }
                      }"
                    >
                      <strong>{{ formatoMoneda(factura.saldo) }}</strong>
                    </router-link>
                  </td>
                  <td>
                    <span
                      class="badge bg-success bg-gradient"
                      v-show="factura.pagada && !factura.cancelada"
                    >
                      Pagada
                    </span>
                    <span
                      class="badge bg-warning bg-gradient"
                      v-show="
                        !factura.pagada &&
                        !factura.cancelada &&
                        factura.total.toFixed(2) == factura.saldo.toFixed(2)
                      "
                    >
                      Sin cobrar
                    </span>
                    <span
                      class="badge bg-warning bg-gradient"
                      v-show="
                        !factura.pagada &&
                        !factura.cancelada &&
                        factura.total.toFixed(2) > factura.saldo.toFixed(2)
                      "
                    >
                      Cobro parcial
                    </span>
                    <span
                      class="badge bg-info bg-gradient"
                      v-show="
                        !factura.pagada &&
                        !factura.cancelada &&
                        factura.prorroga > 0
                      "
                    >
                      Promesa de pago
                    </span>
                    <button
                      v-show="
                        !factura.pagada &&
                        !factura.cancelada &&
                        factura.prorroga > 0
                      "
                      class="btn btn-light btn-sm"
                      @click="palancaMostrarProrroga(factura)"
                    >
                      <i
                        class="mdi"
                        :class="{
                          'mdi-chevron-down': !factura.mostrar_prorroga, 
                          'mdi-chevron-up': factura.mostrar_prorroga
                        }"
                      ></i>
                    </button>
                    <span class="badge bg-danger bg-gradient font-size-12" v-show="factura.cancelada">
                      Cancelada
                    </span>
                  </td>
                  <!-- <td class="text-center">
                    <div class="dropdown">
                      <button
                        class="btn btn-light btn-sm dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="bx bx-dots-horizontal-rounded"></i>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <button class="dropdown-item success"
                            @click="preguntaDescargarCfdiPdf(factura)"
                          >
                            <i class="mdi mdi-file-pdf text-success"></i>
                            PDF
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item warning"
                            @click="preguntaDescargarCfdiXml(factura)"
                          >
                            <i class="mdi mdi-xml text-warning"></i>
                            XML
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item primary"
                            @click="preguntaReenviarCfdi(factura)"
                          >
                            <i class="mdi mdi-email-send-outline text-primary"></i>
                            Reenviar
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td> -->
                  <td class="text-center">
                    <div class="d-flex flex-wrap gap-2">
                      <router-link
                        :to="{
                          name: 'facturasPendientesPago',
                          params: { id_cliente: factura.id_cliente }
                        }"
                        class="btn btn-success btn-sm"
                        v-if="
                          factura.pagada == false && factura.cancelada == false
                        "
                        title="Cobrar"
                      >
                        <i class="mdi mdi-cash"></i>
                      </router-link>
                      <router-link
                        :to="{ name: 'factura', params: { id: factura.id } }"
                        class="btn btn-info btn-sm"
                        title="Ver"
                      >
                        <i class="mdi mdi-eye"></i>
                      </router-link>
                      <div class="dropdown">
                        <button
                          class="btn btn-light btn-sm dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="bx bx-dots-horizontal-rounded"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <button class="dropdown-item dark" @click="imprimirFactura(factura)">
                              <i class="mdi mdi-printer text-dark"></i>
                              Imprimir
                            </button>
                          </li>
                          <li>
                            <button
                              class="dropdown-item"
                              :class="factura.prorroga == 0 ? 'primary': 'danger'"
                              v-show="
                                  !factura.cancelada && !factura.pagada && 
                                  factura.fecha_vencimiento != null &&
                                  factura.id_tipo_renta_contrato != 2
                                "
                              @click="factura.prorroga == 0 ? mostrarMdlProrrogaFactura(factura) : cancelarProrroga(factura)"
                            >
                              <i class="mdi mdi-clock-outline" :class="factura.prorroga == 0 ? 'text-primary': 'text-danger'"></i>
                              {{factura.prorroga == 0 ? 'Promesa de pago' : 'Cancelar promesa de pago'}}
                            </button>
                          </li>
                          <li>
                            <button
                            class="dropdown-item danger"
                              v-show="!factura.cancelada && !factura.pagada"
                              @click="preguntaCancelarFactura(factura)"
                            >
                              <i class="mdi mdi-cancel text-danger"></i>
                              Cancelar
                            </button>
                          </li>
                          <li>
                            <button
                              class="dropdown-item danger"
                              v-show="factura.cancelada"
                              @click="preguntaEliminarFactura(factura)"
                            >
                              <i class="mdi mdi-trash-can-outline text-danger"></i>
                              Eliminar
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="factura.mostrar_prorroga" :key="'factura-prorroga-'+factura.id">
                  <td colspan="2">
                    <strong>Días después de la fecha de vencimiento:</strong> {{ factura.prorroga }} dia{{factura.prorroga==0?'':'s'}}
                  </td>
                  <td colspan="4">
                    <strong>Motivo de promesa de pago:</strong> {{ factura.motivo_prorroga }}
                  </td>
                  <td colspan="4">
                    <strong>Promesa de pago aplicada por:</strong> {{ factura.nombre_usuario_prorroga }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarFacturas()
        "
      ></paginador>
    </div>

    <mdl-filtros
      ref="filtros"
      v-on:filtros-actualizados="actualizarFiltros"
    ></mdl-filtros>
    <mdl-prorroga-factura
      ref="mdlProrrogaFactura"
      v-on:prorroga-factura:gardada="refrescarFacturas()"
    />
    <mdl-facturas-anticipadas
      ref="modal_facturas_anticipadas"
      @facturas:anticipadas:actualizar="refrescarFacturas()"
    ></mdl-facturas-anticipadas>

    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import CmpSecciones from './CmpSecciones.vue'
import MdlFiltros from './CmpModalFiltrosListadoFacturas.vue'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import SistemaSrv from '@/services/SistemaSrv.js'
import FacturaSrv from '@/services/FacturaSrv.js'
import MdlProrrogaFactura from './MdlProrrogaFactura.vue'
import MdlFacturasAnticipadas from "./MdlFacturasAnticipadas.vue"
import accounting from 'accounting/accounting.js'
import moment from 'moment'
import md5 from 'md5'
import API from '@/API.js'

import Swal from 'sweetalert2'
export default {
  name: 'ListadoFacturas',
  components: {
    Layout,
    CmpSecciones,
    MdlFiltros,
    Paginador,
    Filtrador,
    MdlProrrogaFactura,
    MdlFacturasAnticipadas
  },
  data() {
    return {
      API: API,
      token: localStorage.getItem('argusblack.token'),
      fechaHoy: null,
      baseUrl: window.location.origin,
      dato: '',
      facturas: [],
      filtros: ['no pagadas'],
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.listadoFacturas.por_pagina') || 10),
      total: 0,
      ultima_pagina: 1,
      nFacturasEnPag:0,
      nFacturasTotales:0,
      fecha_pago: '',
      id_sitio: null,
      columnas: {
        fechaCreacion: true,
        fechaPago: true,
        fechaVencimiento: true,
        fechaCorte: false,
        inicioYFinPeriodo: false
      }
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this

    // Carga de los querys
    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.filtros) self.filtros = self.$route.query.filtros
    if (self.$route.query.pagina) self.pagina = parseInt(self.$route.query.pagina)
    if (self.$route.query.por_pagina) self.por_pagina = parseInt(self.$route.query.por_pagina)
    if (self.$route.query.fecha_pago) self.fecha_pago = self.$route.query.fecha_pago
    if (self.$route.query.id_sitio) self.id_sitio = parseInt(self.$route.query.id_sitio)

    // Carga inicial de las facturas
    self.refrescarFacturas()

    // Carga de la fecha de hoy
    SistemaSrv.fecha().then(response => {
      self.fechaHoy = response.data
    }).catch(error => {
      let mensaje
      try {
        mensaje = error.response.data.message
      } catch (e) {
        mensaje = ''
      }
      mensaje != '' ? iu.msg.error(mensaje) : null
      console.log(error)
    })

    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()

    // Control de eventos
    iu.bus.on('filtros-actualizados', self.actualizarFiltros)
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-facturas')
  },
  methods: {
    actualizarFiltros: function(datos) {
      var self = this
      self.filtros = datos.filtros
      self.fecha_pago = datos.fecha_pago != '' ? moment(datos.fecha_pago, 'YYYY-MM-DD').format('YYYY-MM-DD') : ''
      self.id_sitio = datos.id_sitio
      
      self.refrescarFacturas()
    },

    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.listadoFacturas.columnas', JSON.stringify(self.columnas))
    },

    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.listadoFacturas.columnas')
      
      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },

    cancelarFactura(idFactura, params) {
      let self = this
      params = params || {}

      FacturaSrv.cancelar(idFactura, params).then(response => {
        iu.msg.success('Factura cancelada correctamente.')
        self.refrescarFacturas()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cancelar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPagina: function(n) {
      var self = this
      self.pagina = n

      self.refrescarFacturas()
    },

    cancelarProrroga: function(factura) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "¿Cancelar promesa de pago?",
        html: "¿Está seguro de cancelar la promesa de pago para la factura "+factura.folio+"?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FacturaSrv.eliminarProrroga(factura.id).then(resopnse => {
            swal.fire("¡Promesa de pago cancelada!", "Promesa de pago de la factura "+factura.folio+" cancelada.", "success");
            self.refrescarFacturas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo cancelar la promesa de pago de la factura "+factura.folio
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    fecha_corte_servicio: function(fecha_para_pago = '', n_dias_corte = 0) {
      if(fecha_para_pago == '') return ''
      return moment(fecha_para_pago, 'YYYY-MM-DD').add(n_dias_corte, 'days').format('DD/MM/YYYY')
    },

    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },

    formatoFecha(mt, formato = 'DD/MM/YYYY'){
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format(formato)
    },

    imprimirFactura(factura) {
      let url = API+"/facturas/"+factura.id+"/impresion/"+md5(factura.id.toString())
      window.open(url,'_blank')
    },

    modalFiltrosListadoFacturas() {
      var self = this,
      params = {
        filtros: self.filtros,
        fecha_pago: self.fecha_pago,
        id_sitio: self.id_sitio
      }
      this.$refs.filtros.mostrar(params)
    },
    
    mostrarMdlProrrogaFactura(factura) {
      this.$refs.mdlProrrogaFactura.mostrar(factura)
    },

    mostrarFacturasAnticipadas: function () {
      var self = this

      self.$refs.modal_facturas_anticipadas.mostrar()
    },

    palancaMostrarProrroga(factura) {
      if (factura.mostrar_prorroga) factura.mostrar_prorroga = false
      else factura.mostrar_prorroga = true
    },

    preguntaEliminarFactura: function(factura) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminación de factura",
        html: '¿Desea elimnar la factura <strong>' + factura.folio + '</strong> ' +
          'del cliente <strong>' + factura.nombre_cliente + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FacturaSrv.eliminar(factura.id).then(resopnse => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarFacturas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'Mp se pudo eliminar la factura'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    preguntaCancelarFactura(factura) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Cancelación de factura",
        html: '¿Desea cancelar la factura <strong>'+factura.folio+'</strong> '+'del cliente <strong>'+factura.nombre_cliente+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          if(factura.id_contrato != null && factura.fecha_para_pago != null) {
            swal.fire("¡Cancelación de factura!", "Cancelación de factura "+factura.folio, "success")
            setTimeout(function(){
              swal.fire({
                title: 'Regeneración de factura',
                html: '¿Deseas que el sistema vuelva a regenerar la factura?',
                icon: "info",
                confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
                cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
                showCancelButton: true
              }).then(result => {
                if (result.value) {
                  self.cancelarFactura(factura.id, {regenerar: true})
                } else if ( result.dismiss === Swal.DismissReason.cancel ) {
                  self.cancelarFactura(factura.id)
                }
              })
            }, 300)
          }
          else {
            self.cancelarFactura(factura.id)
          }
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
        }
      })
    },

    preguntaDescargarCfdiPdf: function (factura) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Descargar CFDI en PDF",
        html: "¿Desea descargar CFDI de la factura <br><b>"+factura.folio+"</b><br> en formato PDF?",
        icon: "question",
        confirmButtonText: "Si descargar!",
        cancelButtonText: "No descargar!",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          swal.fire("¡Descargando!", "Descargando CFDI en pdf de la factura "+factura.folio, "success")
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
        }
      })
    },

    preguntaDescargarCfdiXml: function (factura) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Descargar CFDI en XML",
        html: "¿Desea descargar CFDI de la factura <br><b>"+factura.folio+"</b><br> en formato XML?",
        icon: "question",
        confirmButtonText: "Si descargar!",
        cancelButtonText: "No descargar!",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          swal.fire("¡Descargando!", "Descargando CFDI en XML de la factura "+factura.folio, "success")
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
        }
      })
    },

    preguntaReenviarCfdi: function (factura) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Reenviar CFDI por correo?",
        html: `¿Está seguro de reenviar el CFDI de la factura <strong>${factura.folio}</strong> por correo?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.preguntarCorreo(factura)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    preguntarCorreo: function(factura){
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Motivo de cancelación",
        html: "Escriba el motivo de la cancelación:",
        input: "email",
        inputPlaceholder: "Escribe algo",
        inputValue: 'ejemplo@ejemplo.com',
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a",
        allowOutsideClick: false
      }).then(text => {
        if (text.value) {
          swal.fire("Renviado!", `Se reenvio correctamente el CFDI <br><br> <div style="text-align: justify;">factura: <strong>${factura.folio}</strong> <br> correo: <strong>${text.value}</strong></div>`, "success");
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarFacturas: function() {
      var self = this,filtros =[]

      iu.spinner.mostrar('#tabla-facturas')

      if(typeof self.filtros == 'string'){
        if (self.filtros.indexOf('\"no pagadas\"')  != -1) filtros.push('no pagadas')
        if (self.filtros.indexOf('\"pagadas\"')     != -1) filtros.push('pagadas')
        if (self.filtros.indexOf('\"canceladas\"')  != -1) filtros.push('canceladas')
        if (self.filtros.indexOf('\"prorrogas\"')   != -1) filtros.push('prorrogas')
        if (self.filtros.indexOf('\"vencidas\"')    != -1) filtros.push('vencidas')
        
        self.filtros = filtros
      } else filtros = self.filtros

      let query = {
        dato: self.dato,
        filtros: JSON.stringify(filtros),
        pagina: self.pagina,
        por_pagina: self.por_pagina,
        id_sitio: self.id_sitio
      }

      let params = {
        filtros: filtros,
        dato: self.dato,
        pagina: self.pagina,
        por_pagina: self.por_pagina,
        id_sitio: self.id_sitio,
        orden: 'desc'
      }

      if(self.fecha_pago != '' ){
        Object.assign(query, {
          fecha_pago: self.fecha_pago
        })

        Object.assign(params, {
          fecha_pago: self.fecha_pago
        })
      }

      self.$router.replace({query: query}).catch(error => {})

      FacturaSrv.vfacturas1JSON(params).then(response => {
        localStorage.setItem('argusblack.listadoFacturas.por_pagina', self.por_pagina)
        let res = response.data
        self.pagina = res.pagina
        self.total = res.total
        self.ultima_pagina = res.ultima_pagina

        let facturas = res.facturas

        facturas.forEach(function(factura) {
          factura.mostrar_prorroga = false
        })

        self.facturas = facturas

        self.nFacturasEnPag = res.nFacturasEnPag
        self.nFacturasTotales = res.total
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las facturas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        iu.spinner.ocultar('#tabla-facturas')
      })
    }
  }
}
</script>

<style scoped>
.text-truncate {
  width: 180px;
}
</style>
