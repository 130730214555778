<template>
  <div class="modal fade" ref="mdlSeleccionarCalendario" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" :class="modo=='listado' ? 'modal-md' : 'modal-lg'" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h4 class="modal-title text-white">
            {{modo == 'listado' ? 'Seleccionar calendario' : 'Nuevo evento'}}
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-show="modo=='listado'">
          <table class="table table-hover align-middle">
            <tbody>
              <tr v-for="calendario in calendarios" :key="calendario.id">
                <td class="fw-semibold">{{calendario.nombre}} {{ calendario.generico ? '*': '' }}</td>
                <td class="text-right">
                  <button class="btn btn-outline-success btn-sm"
                    @click="seleccionar(calendario)">
                    <i class="mdi mdi-check-bold"></i>
                    Seleccionar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          <div class="text-right">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">
              <i class="mdi mdi-close"></i>
              Cerrar
            </button>
          </div>
        </div>
        <div class="modal-body" v-show="modo=='evento'">
          <div class="row">
            <div class="col-md-12">
              <label>Título del evento</label>
              <input 
                ref="titulo"
                type="text"
                class="form-control"
                v-model="evento.titulo"
                placeholder="Título"
              >
              
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Descripción del evento</label>
              <textarea
                class="form-control"
                v-model="evento.descripcion"
                placeholder="Descripción del evento"
              ></textarea>
              
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-6">
              <label>Fecha programada</label>
              <input
                type="datetime-local"
                class="form-control"
                placeholder="YYYY-MM-DD HH:MM:SS"
                v-model="evento.fecha_programada"
              />
            </div>
            <div class="col-md-6">
              <label>Tiempo estimado</label>
              <div class="row text-center">
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoDias" type="number"
                    class="form-control text-center" ref="tiempoDias"
                    v-model="evento.tiempo_estimado_dias"
                    min="0" max="365"
                    placeholder="0"
                  />
                  <label>Dias</label>
                </div>
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoHoras" type="number"
                    class="form-control text-center" ref="tiempoHoras"
                    v-model="evento.tiempo_estimado_horas"
                    min="0" max="23"
                    placeholder="0"
                  />
                  <label>Horas</label>
                </div>
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoMinutos" type="number"
                    class="form-control text-center" ref="tiempoMinutos"
                    v-model="evento.tiempo_estimado_minutos" 
                    min="0" max="59"
                    placeholder="0"
                  />
                  <label>Minutos</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="">Calendario</label>
              <span class="form-control text-center text-success fw-semibold">
                {{calendario_seleccionado ? calendario_seleccionado.nombre : ''}}
              </span>
            </div>
          </div>

          <br>

          <div class="row">
            <div class="col-md-3">
              <div class="form-check form-check-success">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="mode"
                  id="para-todos"
                  :checked="evento.todos"
                  @change="evento.todos = !evento.todos"
                />
                <label
                  class="form-check-label"
                  for="para-todos"
                >
                  Para todos
                </label>
              </div>
            </div>
            <div class="col-md-9">
              <p v-show="!evento.todos">
                <i class="mdi mdi-arrow-left-thick"></i>
                Si activas esta opción, el evento será
                visible para todos los usuarios y roles.
              </p>
            </div>
          </div>

          <div class="row" v-if="!evento.todos">
            <div class="col-md-6">
              <label for="">Usuarios</label>
              <multiselect
                v-model="evento.ids_usuarios"
                placeholder="Buscar Usuario"
                selectLabel="Presione enter para seleccionar"
                selectedLabel="Seleccionado"
                deselectLabel="Presione enter para eliminar"
                label="nombre"
                track-by="id"
                :options="usuarios"
                :multiple="true"
                :taggable="false"
              ></multiselect>
            </div>
          </div>

          <div class="text-right">
            <button type="button" class="btn btn-light" @click="atras()">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button type="button" class="btn btn-success" @click="guardar()">
              <i class="mdi mdi-content-save"></i>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarioSrv from "@/services/CalendarioSrv.js"
import Multiselect from 'vue-multiselect'
import Modal from 'bootstrap/js/dist/modal'
import UsuarioSrv from '@/services/UsuarioSrv.js'
import EventoCalendarioSrv from "@/services/EventoCalendarioSrv"
import moment from 'moment'
export default {
  name: 'MdlSeleccionarCalendario',
  components: { Multiselect },
  data() {
    return {
      modo: 'listado',
      calendarios: [],
      calendario_seleccionado: null,
      fecha: null,
      hora: null,

      evento: {
        titulo: '', 
        descripcion: '',
        fecha_programada: '',
        tiempo_estimado: 0,
        tiempo_estimado_dias: 0,
        tiempo_estimado_horas: 1,
        tiempo_estimado_minutos: 0,
        id_calendario: null,
        id_instalacion: null,
        id_soporte: null,
        todos: true, 
        estado: 1,

        ids_usuarios: []
      },

      usuarios: []
    }
  },

  computed: {
    usuario() {
      return this.$store.state.todo.usuario
    }
  },
  methods: {
    atras: function() {
      var self = this 

      self.modo = 'listado'
      self.calendario_seleccionado = null

      self.evento.titulo = ''
      self.evento.descripcion = ''
      self.evento.fecha_programada = ''
      self.evento.tiempo_estimado = 0
      self.evento.tiempo_estimado_dias = 0
      self.evento.tiempo_estimado_horas = 1
      self.evento.tiempo_estimado_minutos = 0
      self.evento.id_calendario = null
      self.evento.id_instalacion = null
      self.evento.id_soporte = null
      self.evento.todos = true
      self.evento.estado = 1

      self.evento.ids_usuarios = []

      self.usuarios = []
    },

    mostrar(fecha, hora) {
      var self = this

      self.modo = 'listado'

      self.fecha = fecha
      self.hora = hora

      self.cargarCalendarios()
      var modal = new Modal(self.$refs.mdlSeleccionarCalendario)
      modal.show()
    },

    seleccionar: function(calendario){
      var self = this

      switch(calendario.nombre) {
        case 'Solicitudes de instalación':
          var modal = Modal.getInstance(self.$refs.mdlSeleccionarCalendario)
          modal.hide()
          self.$router.push({
            name: 'nuevaSolicitudInstalacion',
            query: {
              fecha_programada: moment(self.fecha+' '+self.hora).format('YYYY-MM-DD HH:mm:ss'),
              tiempo_estimado: 3600
            } 
          })
        break;
        case 'Tickets de soporte':
          var modal = Modal.getInstance(self.$refs.mdlSeleccionarCalendario)
          modal.hide()
          self.$router.push({
            name: 'nuevoTicketSoporte',
            query: {
              fecha_programada: moment(self.fecha+' '+self.hora).format('YYYY-MM-DD HH:mm:ss'),
              tiempo_estimado: 3600
            } 
          })
        break;
        default: 
          self.modo = 'evento'
          self.calendario_seleccionado = calendario
          self.evento.fecha_programada = moment(self.fecha+' '+self.hora).format('YYYY-MM-DD HH:mm:ss')
          self.evento.id_calendario = calendario.id
          self.cargarUsuarios()
        break;
      }
    },

    calcularDiasASegundos(dias){
      return dias * 86400
    },
    
    calcularHorasASegundos(horas){
      return horas * 3600
    },

    calcularMinutosASegundos(minutos){
      return minutos * 60
    },

    cargarUsuarios: function() {
      var self = this

      let params = { todos: true }

      UsuarioSrv.usuariosJSON(params).then(response => {
        let usuarios = response.data

        self.usuarios = []
        self.evento.ids_usuarios = []
        usuarios.forEach(usu => {
          let datos = {
            id: usu.id,
            nombre: usu.nombre+' '+(usu.apellido_paterno || '')+' '+(usu.apellido_materno || '')
          }
          self.usuarios.push(datos)
        });
        
        self.evento.ids_usuarios.push({
          id: self.usuario.id,
          nombre: self.usuario.nombre+' '+(self.usuario.apellido_paterno || '')+' '+(self.usuario.apellido_materno || '')
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarCalendarios:function() {
      var self = this, params = { sin_paginacion: true }

      CalendarioSrv.calendariosJSON(params).then(response => {
        var calendarios = response.data

        calendarios.forEach(cal => {
          if(cal.usuario_ajustes_calendario){
            cal.color = cal.usuario_ajustes_calendario.color
            cal.visible = cal.usuario_ajustes_calendario.visible
          }
        })

        self.calendarios = calendarios
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los calendarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    guardar: function() {
      var self = this,
        evento = self.evento

      let tiempo = self.calcularDiasASegundos(evento.tiempo_estimado_dias) +
                  self.calcularHorasASegundos(evento.tiempo_estimado_horas) +
                  self.calcularMinutosASegundos(evento.tiempo_estimado_minutos)

      
      evento.fecha_programada = moment(evento.fecha_programada, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
      evento.tiempo_estimado = tiempo

      if(evento.titulo == '') {
        iu.msg.warning('se requiere un titulo para el nuevo evento')
        self.$refs.titulo.focus()
        return
      }

      let total_ids = evento.ids_usuarios.length

      if( !evento.todos && total_ids == 0 ) {
        iu.msg.warning('Es necesario registrar al menos un "Usuario"')
        return
      }

      EventoCalendarioSrv.guardar(evento).then(response => {
        iu.msg.success('se guardo correctamente el nuevo evento')
        
        var modal = Modal.getInstance(self.$refs.mdlSeleccionarCalendario)
        modal.hide()

        self.atras()
        self.$emit('evento:guardado')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron guardar el evento'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
</style>
